// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import {
	IConsultarRelatorioMensalDare,
	IConsultarRelatorioMensalDareRequest,
	Types,
} from './types';

export function consultarRelatorioMensalDareRequest(
	token: string,
	payload: IConsultarRelatorioMensalDareRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_RELATORIO_MENSAL_DARE_REQUEST,
		token,
		payload,
	};
}

export function consultarRelatorioMensalDareSuccess(
	status: number,
	data: IConsultarRelatorioMensalDare[] | null,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_RELATORIO_MENSAL_DARE_SUCCESS,
		payload: {
			status,
			data,
		},
	};
}

export function consultarRelatorioMensalDareClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_RELATORIO_MENSAL_DARE_CLEAR,
		payload: null,
	};
}

export function consultarRelatorioMensalDareFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_RELATORIO_MENSAL_DARE_FAILURE,
		payload: null,
	};
}
