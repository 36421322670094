import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, putApi } from 'services/rotasp/api';
import { ReducerActionRotaSP } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { PATH_SGU_DOMINIOS_API } from '../../../../../../../services/rotasp/path';
import { AtualizarNaturalidadeRequestNew, Types } from './types';
import {
	atualizarNaturalidadeSuccess,
	atualizarNaturalidadeFailure,
} from './actions';

function* atualizarNaturalidadeRequest(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: AtualizarNaturalidadeRequestNew } = request;

	let body = { ...payload };

	delete body.id;

	body = cleanEmpty(body) as AtualizarNaturalidadeRequestNew;

	const response: ApiResponse = yield call(
		putApi,
		token || '',
		PATH_SGU_DOMINIOS_API,
		`naturalidades/${payload.id}`,
		body,
	);

	if (response.status === 200) {
		yield put(atualizarNaturalidadeSuccess(response));
	} else {
		yield put(atualizarNaturalidadeFailure());
	}
}

export default all([
	takeLatest(Types.ATUALIZAR_NATURALIDADE, atualizarNaturalidadeRequest),
]);
