import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_AAC_RESULTADO_NOMINAL } from 'pages/iirgd/Aac/routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
import { pesquisaNominalResquest } from 'store/modules/api/aac/pesquisaNominal/actions';
import { limpaConsultaNominal } from 'store/modules/api/aac/consultaAtestadoNominal/actions';
import data from 'store/modules/api/iirgd/enum';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ValidDataInput from 'components/Common/Form/Input/ValidData';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask';
import ButtonImage from 'components/Common/ButtonImage';
import Select from 'components/Common/Form/Select';
import Divider from 'components/Common/Divider';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import { limparMascara, onlyLetters } from 'utils/genericFunctions';

// FORM
import { PesquisaNominal } from 'store/modules/api/aac/pesquisaNominal/types';
import { comparaData } from 'utils/dateFunctions';
import NavMenu from '../../components/NavMenu';
import { InitialValues, schema } from './form';

// STYLED
import { ContentButton } from './styled';
import Procurador from '../../components/Procurador';

const Nominal: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);
	const { pesquisaNominal } = useSelector(
		(state: ApplicationState) => state.api.aac,
	);

	const { errors: screenErros } = useSelector(
		(state: ApplicationState) => state.app.notifications,
	);

	const [dataExpedicao, setDataExpedicao] = useState<string>('');
	const [dataNascimento, setDataNascimento] = useState<string>('');
	const [dataInvalida, setDataInvalida] = useState<boolean>(false);
	const [formData, setFormData] = useState(pesquisaNominal);

	useEffect(() => {
		if (dataInvalida) {
			addNotifications({
				errors: [
					'A data de expedição não pode ser anterior a data de nascimento.',
				],
			});
		}
	}, [dataInvalida]);

	const validaDataExpedicao = useCallback((): void => {
		if (
			limparMascara(dataNascimento).length < 8 ||
			limparMascara(dataExpedicao).length < 8
		) {
			return setDataInvalida(false);
		}

		if (dataExpedicao && limparMascara(dataNascimento).length === 8) {
			if (!comparaData(dataExpedicao, dataNascimento, 'maiorIgual')) {
				return setDataInvalida(true);
			}
			dispatch(clearNotifications());
		}
		return setDataInvalida(false);
	}, [dataExpedicao, dataNascimento, dispatch]);

	useEffect(() => {
		validaDataExpedicao();
	}, [dataExpedicao, dataNascimento, validaDataExpedicao]);

	useEffect(() => {
		if (pesquisaNominal.numeroDocumento !== '') {
			setFormData(pesquisaNominal);
		} else {
			setFormData(InitialValues);
		}
	}, [pesquisaNominal]);

	useEffect(() => {
		dispatch(clearNotifications());
		dispatch(limpaConsultaNominal());
	}, [dispatch]);

	const verifyMaskType = (docType: string) => {
		switch (docType) {
			case 'RG':
				return '999999999999999999';
			case 'CNH':
				return '999999999999999999';
			case 'CIN':
				return '99999999999';
			default:
				return '******************';
		}
	};

	const handleSubmit = async (values: PesquisaNominal) => {
		if (!dataInvalida) {
			const newPayload: PesquisaNominal = {
				...values,
				nome: values.nome.toUpperCase(),
				nomeMae: values.nomeMae.toUpperCase(),
				nomePai: values.nomePai.toUpperCase(),
				numeroDocumento: limparMascara(values.numeroDocumento.toUpperCase()),
				posto: login.user.posto,
				ipEstacao: login.user.ip,
				usuario: login.mainframe.idUsuario,
				dc: values.dc.substring(0, values.dc.length - 1),
				dataExpedicao: values.dataExpedicao,
				dataNascimento: values.dataNascimento,
				tipoDocApresentado: values.tipoDocApresentado.toUpperCase(),
				nomeProcurador: values.requerente
					? values.nomeProcurador.toUpperCase()
					: '',
				docProcurador: values.requerente
					? values.docProcurador.toUpperCase()
					: '',
				ufRg:
					values.tipoDocApresentado === 'RG' ||
					values.tipoDocApresentado === 'CNH'
						? values.ufRg.toUpperCase()
						: '',
			};
			dispatch(pesquisaNominalResquest(newPayload));
			history.push(ROUTE_AAC_RESULTADO_NOMINAL);
		} else {
			addNotifications({
				errors: [
					'A data de expedição não pode ser anterior a data de nascimento.',
				],
			});
		}
	};

	return (
		<div>
			<Row>
				<Col span={24}>
					<NavMenu />

					<Divider mtSize={15} mbSize={15} />
				</Col>
			</Row>
			<Section title="Pesquisar Nominal">
				<Formik
					enableReinitialize
					validateOnChange={false}
					validateOnBlur={screenErros.length > 0}
					initialValues={formData}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={handleSubmit}
				>
					{(formik: FormikProps<any>) => {
						const { errors, setFieldValue, values, handleReset } = formik;
						return (
							<Form autoComplete="off">
								<Row gutter={[0, 10]}>
									<Col span={12}>
										<Field
											as={Select}
											name="tipoDocApresentado"
											type="text"
											title="Tipo Doc."
											titleSize="lg"
											onChange={(v: number) =>
												setFieldValue('tipoDocApresentado', v)
											}
											size={70}
											options={data.tipoDocumentoAac}
											error={hasError(errors, 'tipoDocApresentado')}
										/>
									</Col>
									<Col span={12}>
										{values.tipoDocApresentado === 'RG' ||
										values.tipoDocApresentado === 'CNH' ||
										values.tipoDocApresentado === 'FUNC' ? (
											<Field
												title="UF do Documento"
												name="ufRg"
												size={10}
												as={Input}
												value={onlyLetters(values.ufRg)}
												maxLength={2}
												titleSize="md"
												error={hasError(errors, 'ufRgSp')}
											/>
										) : null}
									</Col>
									<Col span={12}>
										<Field
											name="numeroDocumento"
											type="text"
											title="Nº Documento"
											maskChar=""
											as={InputMask}
											size={60}
											titleSize="lg"
											mask={verifyMaskType(formik.values.tipoDocApresentado)}
											error={hasError(errors, 'numeroDocumento')}
										/>
									</Col>
									<Col span={12}>
										<Field
											id="dataExpedicaoRg"
											title="Data de Expedição"
											name="dataExpedicao"
											type="text"
											mask="99/99/9999"
											subtitle="(DD/MM/AAAA)"
											size={25}
											as={ValidDataInput}
											required
											error={hasError(errors, 'dataExpedicao')}
											formik={formik}
											customChanges={(
												v: React.ChangeEvent<HTMLInputElement>,
											) => {
												setDataExpedicao(v.currentTarget.value);
												setFieldValue('dataExpedicao', v.currentTarget.value);
											}}
										/>
									</Col>
									<Col span={24}>
										<Field
											title="Nome"
											name="nome"
											maxLength={60}
											titleSize="lg"
											value={onlyLetters(values.nome)}
											size={80}
											as={Input}
											error={hasError(errors, 'nome')}
										/>
									</Col>
									<Col span={24}>
										<Field
											titleSize="lg"
											title="Filiação 1"
											name="nomePai"
											value={onlyLetters(values.nomePai)}
											maxLength={60}
											size={80}
											as={Input}
										/>
									</Col>
									<Col span={24}>
										<Field
											titleSize="lg"
											title="Filiação 2"
											name="nomeMae"
											maxLength={60}
											value={onlyLetters(values.nomeMae)}
											size={80}
											as={Input}
										/>
									</Col>
									<Col span={8}>
										<Field
											as={Select}
											name="sexo"
											title="Sexo"
											size={85}
											titleSize="lg"
											defaultValue="M"
											onChange={(v: string) => setFieldValue('sexo', v)}
											options={data.sexo}
											error={hasError(errors, 'sexo')}
										/>
									</Col>
									<Col span={16}>
										<Field
											title="Data de Nascimento"
											name="dataNascimento"
											type="text"
											mask="99/99/9999"
											subtitle="(DD/MM/AAAA)"
											size={25}
											titleSize="lg"
											formik={formik}
											as={ValidDataInput}
											error={hasError(errors, 'dataNascimento')}
											customChanges={(
												v: React.ChangeEvent<HTMLInputElement>,
											) => {
												setFieldValue('dataNascimento', v.currentTarget.value);
												setDataNascimento(v.currentTarget.value);
											}}
										/>
									</Col>
									<Procurador formik={formik} />
								</Row>
								<ContentButton>
									<ButtonImage
										onClick={handleReset}
										type="button"
										src="limpar"
									/>
									<ButtonImage type="submit" src="pesquisar" />
								</ContentButton>
							</Form>
						);
					}}
				</Formik>
			</Section>
			<ButtonVoltar route="/iirgd" />
		</div>
	);
};

export default Nominal;
