import { ConsultarDetalheSolicitacaoData } from 'store/modules/api/ministerioPublico/consultarDetalheSolicitacao/types';
import { SolicitarReconhecimentoPaternidadeRequest } from 'store/modules/api/ministerioPublico/solicitarReconhecimentoPaternidade/types';
import {
	formatDateISOWithOutUTC,
	unformatDate,
	validaCpf,
} from 'utils/genericFunctions';
import * as Yup from 'yup';
import { ICadastroSolicitante } from '../NovoCadastro/Forms/form';

interface BuscaSolicitaoPaternidade {
	cpf: string;
}

export const initialValues = {
	cpf: '',
};

export const treatPreCadastroRequest = (
	dados: ConsultarDetalheSolicitacaoData,
	// solicitante?: number,
): SolicitarReconhecimentoPaternidadeRequest => {
	const dataRegister: ICadastroSolicitante = {
		filho: {
			...dados.filho,
			nome: dados.filho?.nome || '',
			dataDeNascimento:
				(dados.filho?.dataDeNascimento &&
					formatDateISOWithOutUTC(
						unformatDate(dados.filho?.dataDeNascimento),
					)) ||
				'',
			sexo: dados.filho?.sexo || '',
			cpf: {
				numero:
					(dados.filho?.cpf.numero && dados.filho?.cpf.numero.toString()) || '',
			},
			rg: {
				numero: dados.filho?.rg?.numero || '',
				siglaUF: dados.filho?.rg?.siglaUF || '',
			},
			enderecoDeResidencia: {
				logradouro: dados.filho?.enderecoDeResidencia?.logradouro || '',
				numero: dados.filho?.enderecoDeResidencia?.numero || '',
				complemento: dados.filho?.enderecoDeResidencia?.complemento || '',
				bairro: dados.filho?.enderecoDeResidencia?.bairro || '',
				cep: dados.filho?.enderecoDeResidencia?.cep || '',
				cidade: dados.filho?.enderecoDeResidencia?.cidade || '',
				siglaUF: dados.filho?.enderecoDeResidencia?.siglaUF || '',
			},
			email: dados.filho?.email || '',
			telefones: (dados.filho.telefones && dados.filho.telefones) || [],
			documentosPessoais: [],
		},
		supostoGenitor: {
			...dados.supostoGenitor,
			nome: dados.supostoGenitor.nome || '',
			dataDeNascimento:
				(dados.supostoGenitor?.dataDeNascimento &&
					formatDateISOWithOutUTC(
						unformatDate(dados.supostoGenitor?.dataDeNascimento),
					)) ||
				'',
			cidadeDeNascimento: dados.supostoGenitor?.cidadeDeNascimento || '',
			siglaUfDeNascimento: dados.supostoGenitor?.siglaUfDeNascimento || '',
			sexo: dados.supostoGenitor.sexo || '',
			cpf: {
				numero:
					(dados.supostoGenitor?.cpf.numero &&
						dados.supostoGenitor?.cpf.numero.toString()) ||
					'',
			},
			telefones:
				(dados.supostoGenitor.telefones && dados.supostoGenitor.telefones) ||
				[],
			rg: {
				numero: dados.supostoGenitor?.rg?.numero || '',
				siglaUF: dados.supostoGenitor?.rg?.siglaUF || '',
			},
			enderecoDeResidencia: {
				logradouro:
					dados.supostoGenitor?.enderecoDeResidencia?.logradouro || '',
				numero: dados.supostoGenitor?.enderecoDeResidencia?.numero || '',
				complemento:
					dados.supostoGenitor?.enderecoDeResidencia?.complemento || '',
				bairro: dados.supostoGenitor?.enderecoDeResidencia?.bairro || '',
				cep: dados.supostoGenitor?.enderecoDeResidencia?.cep || '',
				cidade: dados.supostoGenitor?.enderecoDeResidencia?.cidade || '',
				siglaUF: dados.supostoGenitor?.enderecoDeResidencia?.siglaUF || '',
			},
			email: dados.supostoGenitor?.email || '',
			documentosPessoais: [],
		},
		responsavelLegal: {
			...dados.responsavelLegal,
			nome: dados.responsavelLegal.nome || '',
			dataDeNascimento:
				(dados.responsavelLegal?.dataDeNascimento &&
					formatDateISOWithOutUTC(
						unformatDate(dados.responsavelLegal?.dataDeNascimento),
					)) ||
				'',
			sexo: dados.responsavelLegal.sexo || '',
			cpf: {
				numero:
					(dados.responsavelLegal?.cpf.numero &&
						dados.responsavelLegal?.cpf.numero.toString()) ||
					'',
			},
			telefones:
				(dados.responsavelLegal.telefones &&
					dados.responsavelLegal.telefones) ||
				[],
			rg: {
				numero: dados.responsavelLegal?.rg?.numero || '',
				siglaUF: dados.responsavelLegal?.rg?.siglaUF || '',
			},
			enderecoDeResidencia: {
				logradouro:
					dados.responsavelLegal?.enderecoDeResidencia?.logradouro || '',
				numero: dados.responsavelLegal?.enderecoDeResidencia?.numero || '',
				complemento:
					dados.responsavelLegal?.enderecoDeResidencia?.complemento || '',
				bairro: dados.responsavelLegal?.enderecoDeResidencia?.bairro || '',
				cep: dados.responsavelLegal?.enderecoDeResidencia?.cep || '',
				cidade: dados.responsavelLegal?.enderecoDeResidencia?.cidade || '',
				siglaUF: dados.responsavelLegal?.enderecoDeResidencia?.siglaUF || '',
			},
			email: dados.responsavelLegal?.email || '',
			documentosPessoais: [],
		},

		documentosAnexos: [],
		documentoAnexo: '',
		cpfUsuario: '',
		podeArcarComCustosDoProcesso: '',
		comoFezOPedido: '',
		quemFezOPedido: '',
		dataDaSolicitacao: dados.dataDaSolicitacao,
		outrasInformacoesParaLocalizacaoDoSupostoGenitor: '',
		tipoSolicitante: 0,
		numeroMP: dados.numeroMP,
		andamentoDaSolicitacao: dados.andamentoDaSolicitacao,
	};

	dados.filho?.telefones?.length > 0 &&
		dados.filho.telefones.forEach(tel => {
			if (tel.tipo === 'Celular') {
				dataRegister.filho.telefoneCelular = `${tel.ddd.toString()} ${tel.numero.toString()}`;
			} else if (tel.tipo === 'Residencial') {
				dataRegister.filho.telefoneResidencial = `${tel.ddd.toString()} ${tel.numero.toString()}`;
			}
		});

	dados.supostoGenitor?.telefones?.length > 0 &&
		dados.supostoGenitor.telefones.forEach(tel => {
			if (tel.tipo === 'Celular') {
				dataRegister.supostoGenitor.telefoneCelular = `${tel.ddd.toString()} ${tel.numero.toString()}`;
			} else if (tel.tipo === 'Residencial') {
				dataRegister.supostoGenitor.telefoneResidencial = `${tel.ddd.toString()} ${tel.numero.toString()}`;
			}
		});

	dados.responsavelLegal?.telefones?.length > 0 &&
		dados.responsavelLegal.telefones.forEach(tel => {
			if (tel.tipo === 'Celular') {
				dataRegister.responsavelLegal.telefoneCelular = `${tel.ddd.toString()} ${tel.numero.toString()}`;
			} else if (tel.tipo === 'Residencial') {
				dataRegister.responsavelLegal.telefoneResidencial = `${tel.ddd.toString()} ${tel.numero.toString()}`;
			}
		});

	return dataRegister;
};

export const schema = Yup.object<BuscaSolicitaoPaternidade>().shape({
	cpf: Yup.string()
		.required('Campo Obrigatório: CPF.')
		.test({
			name: 'cpfValidation',
			exclusive: true,
			message: 'CPF digitado inválido.',
			test: value => validaCpf(value),
		}),
});
