import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_BU_PPT_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, IConsultarRelacaoUsuarioRequest } from './types';
import {
	consultarRelacaoUsuarioClear,
	consultarRelacaoUsuarioFailure,
	consultaRelacaoUsuarioSuccess,
} from './actions';

function* consultarQueimaDare(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: IConsultarRelacaoUsuarioRequest } = request;

	yield put(consultarRelacaoUsuarioClear());

	const payloadQR: IConsultarRelacaoUsuarioRequest = {
		idOrgao: payload.idOrgao,
		idLocalidade: payload.idLocalidade,
	};

	if (payload?.codigoMainFrame) {
		payloadQR.codigoMainFrame = payload.codigoMainFrame;
	}

	if (payload?.page) {
		payloadQR.page = payload.page;
	}

	if (payload?.size) {
		payloadQR.size = payload.size;
	}

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_BU_PPT_SGU_API,
		`usuarios/localidades`,
		payloadQR,
	);

	if (response.status === 200) {
		yield put(consultaRelacaoUsuarioSuccess(response.status, response.data));
	} else {
		yield put(consultarRelacaoUsuarioFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_RELACAO_USUARIO_REQUEST, consultarQueimaDare),
]);
