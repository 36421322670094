import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, deleteApi } from 'services/rotasp/api';
import { ReducerActionRotaSP } from 'store/modules/types';
import { PATH_SGU_DOMINIOS_API } from '../../../../../../../services/rotasp/path';
import { ExcluirNaturalidadeRequestNew, Types } from './types';
import {
	excluirNaturalidadeSuccess,
	excluirNaturalidadeFailure,
} from './actions';

function* excluirNaturalidadeRequest(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: ExcluirNaturalidadeRequestNew } = request;

	const response: ApiResponse = yield call(
		deleteApi,
		token || '',
		PATH_SGU_DOMINIOS_API,
		`naturalidades/${payload.id}`,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(excluirNaturalidadeSuccess(response));
	} else {
		yield put(excluirNaturalidadeFailure());
	}
}

export default all([
	takeLatest(Types.EXCLUIR_NATURALIDADE, excluirNaturalidadeRequest),
]);
