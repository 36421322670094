import { ReducerAction, ReducerActionRotaSP } from 'store/modules/types';

import { Types } from './types';

export interface IConsultaPrefeiturasRequest {
	nomePrefeitura?: string;
	page?: number;
	size?: number;
	descricaoCanal?: string;
}

export interface ICadastraPrefeiturasRequest {
	codigo?: string | number;
	descricao: string;
	nome: string;
}

export interface IAlteraPrefeiturasRequest {
	codigo?: string | number;
	body: {
		descricao: string;
		nome: string;
	};
}

export interface ICadastraPrefeiturasServicosRequest {
	codigoPrefeitura?: number;
	idServico: number;
	body: any[];
	valuesToUpdate?: any[];
}

export interface IConsultaPrefeiturasServicosRequest {
	idPrefeituraServico?: number;
	codigoPrefeitura?: number;
	idServico?: number;
}

export interface IAlteraPrefeiturasServicosRequest {
	idPrefeituraServico?: number | null;
	body: {
		endpoint: string;
		idProximoServico: number | null;
		primeiroServico: boolean;
	};
	delete?: number | null;
}

export interface IConsultaPrefeiturasServicosParametrosRequest {
	idPrefeituraServico: number;
}

export interface ICadastraPrefeiturasServicosParametrosRequest {
	body: any;
	idPrefeituraServico: number;
	idParametro: any;
}

export interface IAlteraPrefeiturasServicosParametrosRequest {
	grupoObrigatorio: any;
	idPrefeituraServico: number;
	idParametro: number;
}

export interface IRemovePrefeiturasServicosParametrosRequest {
	idPrefeituraServico: number;
	idParametro: any;
}

export function consultaPrefeituras(
	token: string,
	data: IConsultaPrefeiturasRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTA_PREFEITURAS,
		token,
		payload: data,
	};
}
export function consultaPrefeiturasSuccess(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_PREFEITURAS_SUCCESS,
		payload,
	};
}
export function consultaPrefeiturasFailure(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_PREFEITURAS_FAILURE,
		payload,
	};
}

export function cadastraPrefeituras(
	token: string,
	data: ICadastraPrefeiturasRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRA_PREFEITURAS,
		token,
		payload: data,
	};
}
export function cadastraPrefeiturasSuccess(
	payload: ICadastraPrefeiturasRequest,
): ReducerAction {
	return {
		type: Types.CADASTRA_PREFEITURAS_SUCCESS,
		payload,
	};
}
export function cadastraPrefeiturasFailure(payload: []): ReducerAction {
	return {
		type: Types.CADASTRA_PREFEITURAS_FAILURE,
		payload,
	};
}

export function alteraPrefeituras(
	token: string,
	data: IAlteraPrefeiturasRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ALTERA_PREFEITURAS,
		token,
		payload: data,
	};
}
export function alteraPrefeiturasSuccess(
	payload: IAlteraPrefeiturasRequest,
): ReducerAction {
	return {
		type: Types.ALTERA_PREFEITURAS_SUCCESS,
		payload,
	};
}
export function alteraPrefeiturasFailure(payload: []): ReducerAction {
	return {
		type: Types.ALTERA_PREFEITURAS_FAILURE,
		payload,
	};
}

export function removePrefeituras(
	token: string,
	data: number,
): ReducerActionRotaSP {
	return {
		type: Types.REMOVE_PREFEITURAS,
		token,
		payload: data,
	};
}
export function removePrefeiturasSuccess(payload: number): ReducerAction {
	return {
		type: Types.REMOVE_PREFEITURAS_SUCCESS,
		payload,
	};
}
export function removePrefeiturasFailure(payload: []): ReducerAction {
	return {
		type: Types.REMOVE_PREFEITURAS_FAILURE,
		payload,
	};
}

export function consultaPrefeiturasServicos(
	token: string,
	data: IConsultaPrefeiturasServicosRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTA_PREFEITURAS_SERVICOS,
		token,
		payload: data,
	};
}
export function consultaPrefeiturasServicosSuccess(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_PREFEITURAS_SERVICOS_SUCCESS,
		payload,
	};
}
export function consultaPrefeiturasServicosFailure(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_PREFEITURAS_SERVICOS_FAILURE,
		payload,
	};
}

export function cadastraPrefeiturasServicos(
	token: string,
	data: ICadastraPrefeiturasServicosRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRA_PREFEITURAS_SERVICOS,
		token,
		payload: data,
	};
}
export function cadastraPrefeiturasServicosSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CADASTRA_PREFEITURAS_SERVICOS_SUCCESS,
		payload,
	};
}
export function cadastraPrefeiturasServicosFailure(payload: []): ReducerAction {
	return {
		type: Types.CADASTRA_PREFEITURAS_SERVICOS_FAILURE,
		payload,
	};
}

export function alteraPrefeiturasServicos(
	token: string,
	data: IAlteraPrefeiturasServicosRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ALTERA_PREFEITURAS_SERVICOS,
		token,
		payload: data,
	};
}
export function alteraPrefeiturasServicosSuccess(payload: []): ReducerAction {
	return {
		type: Types.ALTERA_PREFEITURAS_SERVICOS_SUCCESS,
		payload,
	};
}
export function alteraPrefeiturasServicosFailure(payload: []): ReducerAction {
	return {
		type: Types.ALTERA_PREFEITURAS_SERVICOS_FAILURE,
		payload,
	};
}

export function removePrefeiturasServicos(
	token: string,
	data: any,
): ReducerActionRotaSP {
	return {
		type: Types.REMOVE_PREFEITURAS_SERVICOS,
		token,
		payload: data,
	};
}
export function removePrefeiturasServicosSuccess(payload: []): ReducerAction {
	return {
		type: Types.REMOVE_PREFEITURAS_SERVICOS_SUCCESS,
		payload,
	};
}
export function removePrefeiturasServicosFailure(payload: []): ReducerAction {
	return {
		type: Types.REMOVE_PREFEITURAS_SERVICOS_FAILURE,
		payload,
	};
}

export function consultaPrefeiturasServicosParametros(
	token: string,
	data: IConsultaPrefeiturasServicosParametrosRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTA_PREFEITURAS_SERVICOS_PARAMETROS,
		token,
		payload: data,
	};
}
export function consultaPrefeiturasServicosParametrosSuccess(
	payload: [],
): ReducerAction {
	return {
		type: Types.CONSULTA_PREFEITURAS_SERVICOS_PARAMETROS_SUCCESS,
		payload,
	};
}
export function consultaPrefeiturasServicosParametrosFailure(
	payload: [],
): ReducerAction {
	return {
		type: Types.CONSULTA_PREFEITURAS_SERVICOS_PARAMETROS_FAILURE,
		payload,
	};
}

export function cadastraPrefeiturasServicosParametros(
	token: string,
	data: ICadastraPrefeiturasServicosParametrosRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRA_PREFEITURAS_SERVICOS_PARAMETROS,
		token,
		payload: data,
	};
}
export function cadastraPrefeiturasServicosParametrosSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CADASTRA_PREFEITURAS_SERVICOS_PARAMETROS_SUCCESS,
		payload,
	};
}
export function cadastraPrefeiturasServicosParametrosFailure(
	payload: [],
): ReducerAction {
	return {
		type: Types.CADASTRA_PREFEITURAS_SERVICOS_PARAMETROS_FAILURE,
		payload,
	};
}

export function alteraPrefeiturasServicosParametros(
	token: string,
	data: IAlteraPrefeiturasServicosParametrosRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ALTERA_PREFEITURAS_SERVICOS_PARAMETROS,
		token,
		payload: data,
	};
}
export function alteraPrefeiturasServicosParametrosSuccess(
	payload: IAlteraPrefeiturasServicosParametrosRequest,
): ReducerAction {
	return {
		type: Types.ALTERA_PREFEITURAS_SERVICOS_PARAMETROS_SUCCESS,
		payload,
	};
}
export function alteraPrefeiturasServicosParametrosFailure(
	payload: [],
): ReducerAction {
	return {
		type: Types.ALTERA_PREFEITURAS_SERVICOS_PARAMETROS_FAILURE,
		payload,
	};
}

export function removePrefeiturasServicosParametros(
	token: string,
	data: IRemovePrefeiturasServicosParametrosRequest,
): ReducerActionRotaSP {
	return {
		type: Types.REMOVE_PREFEITURAS_SERVICOS_PARAMETROS,
		token,
		payload: data,
	};
}
export function removePrefeiturasServicosParametrosSuccess(
	payload: [],
): ReducerAction {
	return {
		type: Types.REMOVE_PREFEITURAS_SERVICOS_PARAMETROS_SUCCESS,
		payload,
	};
}
export function removePrefeiturasServicosParametrosFailure(
	payload: [],
): ReducerAction {
	return {
		type: Types.REMOVE_PREFEITURAS_SERVICOS_PARAMETROS_FAILURE,
		payload,
	};
}

export function clearRedirect(): ReducerAction {
	return {
		type: Types.REDIRECT_PREFEITURAS_CLEAR,
		payload: null,
	};
}

export function nextStepClear(): ReducerAction {
	return {
		type: Types.NEXT_STEP_PREFEITURAS_CLEAR,
		payload: null,
	};
}
