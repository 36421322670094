import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

// ANTD
import { Row, Col } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';
import {
	ROUTE_IIRGD_PESQUISAR_RG_MAE,
	ROUTE_IIRGD_RESULTADO_PESQUISA,
} from 'pages/iirgd/RgFicDigital/routes/paths';
import { ROUTE_IIRGD } from 'routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	cadastroRgClear,
	cadastroRgPortaria606,
	cadastroRgPortaria606Clear,
} from 'store/modules/api/iirgd/cadastroRg/actions';
import {
	consultaPesquisaCirgMaeRequest,
	consultaPesquisaCirgCidadaoClear,
	consultaPesquisaCirgSuccess,
} from 'store/modules/api/iirgd/consultaPesquisaCirg/actions';
import {
	consultaPesquisaCidadaoClear,
	consultaPesquisaCidadaoFormPesquisaMae,
	consultaPesquisaCidadaoRequest,
} from 'store/modules/api/iirgd/consultaPesquisaCidadao/actions';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import {
	consultaPesquisaCivilClear,
	consultaPesquisaCivilRequest,
} from 'store/modules/api/iirgd/consultaPesquisaCivil/actions';
import { ConsultaPesquisaCivilRequest } from 'store/modules/api/iirgd/consultaPesquisaCivil/types';
import { scriptPreAtendimentoClear } from 'store/modules/api/iirgd/scriptPreAtendimento/actions';
import { ConsultaPesquisaCirg } from 'store/modules/api/iirgd/consultaPesquisaCirg/types';
import { dareKitClear } from 'store/modules/api/gerarDare/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import RG from 'components/Common/Form/Fields/RG';
import Input from 'components/Common/Form/Input/Input';
import CheckBox from 'components/Common/Form/Checkbox';
import CodigoMunicipio from 'components/Common/Form/Fields/Municipio/Codigo';
import BuscarMunicipioNaturalidade from 'components/Common/SearchButtons/MunicipioNaturalidade';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ValidDataInput from 'components/Common/Form/Input/ValidData';
import TypographyCommon from 'components/Common/Typography';

// UTILS
import { getFormValidationsErrors } from 'utils/getValidationsErrors';
import {
	completaZeroEsquerda,
	formatDate,
	limparMascara,
	unformatDate,
} from 'utils/genericFunctions';

// FORM
import { Formik, Form, Field } from 'formik';
import { nomeIirgdA, nomePaisIirgdA } from 'utils/stringFunctions';
import { consultarProcessoClear } from 'store/modules/api/rfb/consultarProcesso/actions';
import { paisClear } from 'store/modules/api/utils/endereco/pais/actions';
import { capturarBiometriaClearLeitura } from 'store/modules/api/biometria/capturarBiometria/actions';
import { IFormPesquisaRg, initialValues, schema, treatValues } from './form';

const PesquisaRg: React.FC = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { salvarAtendimento, recuperarAtendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento,
	);

	const { dareKit } = useSelector(
		(state: ApplicationState) => state.api.gerarDare,
	);

	const { notifications } = useSelector((state: ApplicationState) => state.app);

	const {
		consultaPesquisaCirg,
		consultaPesquisaCidadao,
		consultaPesquisaCivil,
	} = useSelector((state: ApplicationState) => state.api.iirgd);

	const [formData, setFormData] = useState<IFormPesquisaRg>(initialValues);

	const [focusBtnPesquisar, setFocusBtnPesquisar] = useState<boolean>(false);

	const [resetValues, setResetValues] = useState<boolean>(false);

	const [btnDisabled, setBtnDisabled] = useState<boolean>(false);

	const handleRgFocus = useCallback(() => {
		const rgInput = document.getElementsByTagName('input')[0];

		if (rgInput) {
			rgInput.setSelectionRange(0, 0);
			rgInput.focus();
		}
	}, []);

	const handleReset = useCallback(formik => {
		formik.setFieldValue('numeroRg', '');
		formik.setFieldValue('digitoRg', '');
		formik.setFieldValue('nome', '');
		formik.setFieldValue('nomePai', '');
		formik.setFieldValue('nomeMae', '');
		formik.setFieldValue('rgMae', '');
		formik.setFieldValue('dataNascimento', '');
		formik.setFieldValue('naturalidadeEstrangeira', false);
		formik.setFieldValue('codigoNaturalidade', '');
		formik.setFieldValue('municipioNaturalidade', '');
		formik.setFieldValue('apresentouCertidao', null);
	}, []);

	const handleFocusBtnPesquisar = useCallback(() => {
		return focusBtnPesquisar;
	}, [focusBtnPesquisar]);

	const handleValidate = useCallback(
		(formValues: IFormPesquisaRg) => {
			const hasFormErrors =
				notifications.errors.length > 0 &&
				notifications.notificationOrigin === 'FORM_VALIDATE';

			return getFormValidationsErrors(
				formValues,
				schema,
				undefined,
				hasFormErrors,
			);
		},
		[notifications.errors.length, notifications.notificationOrigin],
	);

	const handleSubmit = useCallback(
		(formValues: IFormPesquisaRg) => {
			dispatch(cadastroRgClear());

			if (formValues.portaria606 && !formValues.apresentouCertidao) {
				dispatch(cadastroRgPortaria606());
			}

			if (formValues.rgMae && consultaPesquisaCirg.mae.status !== 204) {
				dispatch(
					consultaPesquisaCirgMaeRequest(
						formValues.rgMae,
						login.mainframe.idUsuario,
						login.mainframe.senhaMainFrame,
					),
				);

				dispatch(consultaPesquisaCidadaoFormPesquisaMae(formValues));

				return;
			}

			const values = treatValues(formValues, login);

			if (formValues.RGempty) {
				dispatch(consultaPesquisaCidadaoRequest(values, formValues));
			} else {
				const newForm = {
					...formValues,
					dataNascimento: '',
					nome: '',
					nomeMae: '',
					nomePai: '',
				};

				dispatch(consultaPesquisaCidadaoRequest(values, newForm));
			}
		},
		[dispatch, consultaPesquisaCirg.mae.status, login],
	);

	useEffect(() => {
		dispatch(cadastroRgPortaria606Clear());

		dispatch(consultarProcessoClear());

		dispatch(consultaPesquisaCidadaoClear());

		dispatch(consultaPesquisaCivilClear());

		dispatch(scriptPreAtendimentoClear());

		dispatch(paisClear());

		dispatch(capturarBiometriaClearLeitura());
	}, [dispatch]);

	useEffect(() => {
		if (resetValues) {
			setFocusBtnPesquisar(false);

			handleRgFocus();

			setResetValues(false);
		}
	}, [handleRgFocus, resetValues]);

	useEffect(() => {
		if (dareKit.quantidade === dareKit.finalizados) {
			dispatch(dareKitClear());
		}
	}, [dareKit, dispatch]);

	// Realiza o redirecionamento após o sucesso na consulta.
	useEffect(() => {
		if (
			consultaPesquisaCirg.mae.status === 200 &&
			consultaPesquisaCirg.mae.data &&
			consultaPesquisaCirg.mae.data.filter(
				(item: ConsultaPesquisaCirg) =>
					item.nome.trim() !== consultaPesquisaCidadao.form?.nomeMae.trim(),
			).length
		) {
			history.push(ROUTE_IIRGD_PESQUISAR_RG_MAE);

			return;
		}
		if (
			consultaPesquisaCirg.mae.status === 200 &&
			consultaPesquisaCirg.mae.data &&
			consultaPesquisaCirg.mae.data.filter(
				(item: ConsultaPesquisaCirg) =>
					item.nome.trim() === consultaPesquisaCidadao.form?.nomeMae.trim(),
			).length &&
			consultaPesquisaCidadao.form &&
			consultaPesquisaCidadao.status === 0
		) {
			setBtnDisabled(true);

			const values = treatValues(consultaPesquisaCidadao.form, login);

			dispatch(
				consultaPesquisaCidadaoRequest(values, consultaPesquisaCidadao.form),
			);

			return;
		}

		if (
			consultaPesquisaCidadao.status === 200 &&
			consultaPesquisaCirg.cidadao?.data &&
			consultaPesquisaCirg.cidadao?.data?.length === 1
		) {
			setBtnDisabled(false);

			dispatch(
				consultaPesquisaCirgSuccess(consultaPesquisaCirg.cidadao?.data[0]),
			);

			history.push(ROUTE_IIRGD_RESULTADO_PESQUISA);
		}

		if (
			consultaPesquisaCidadao.status === 200 &&
			consultaPesquisaCidadao?.data &&
			consultaPesquisaCidadao?.data?.length === 1
		) {
			setBtnDisabled(false);

			history.push(ROUTE_IIRGD_RESULTADO_PESQUISA);
		}

		if (
			consultaPesquisaCidadao.status === 200 &&
			consultaPesquisaCirg.cidadao?.data &&
			consultaPesquisaCivil.status === 200
		) {
			setBtnDisabled(false);

			history.push(ROUTE_IIRGD_RESULTADO_PESQUISA);
		}

		if (
			(consultaPesquisaCidadao.status === 200 &&
				consultaPesquisaCirg.cidadao?.data === null) ||
			consultaPesquisaCirg.cidadao?.data?.length === 0
		) {
			setBtnDisabled(false);

			history.push(ROUTE_IIRGD_RESULTADO_PESQUISA);
		}

		if (
			consultaPesquisaCidadao.status === 200 &&
			consultaPesquisaCidadao?.data?.length === 0
		) {
			setBtnDisabled(false);

			history.push(ROUTE_IIRGD_RESULTADO_PESQUISA);
		}
	}, [
		consultaPesquisaCirg,
		consultaPesquisaCidadao,
		history,
		login,
		dispatch,
		consultaPesquisaCivil,
	]);

	useEffect(() => {
		if (consultaPesquisaCidadao.form && consultaPesquisaCidadao.form?.RGempty) {
			setFormData(consultaPesquisaCidadao.form);
		}
	}, [consultaPesquisaCidadao.form]);

	useEffect(() => {
		if (
			salvarAtendimento.status === 200 &&
			salvarAtendimento.tipo === 'OK' &&
			recuperarAtendimento.status === 200 &&
			recuperarAtendimento.data &&
			!consultaPesquisaCidadao.form
		) {
			const body: IFormPesquisaRg = {
				numeroRg: '',
				digitoRg: '',
				resetValues: false,
				RGempty: false,
				nome: '',
				nomePai: '',
				nomeMae: '',
				rgMae: '',
				rgMaeUf: 'SP',
				dataNascimento: '',
				naturalidadeEstrangeira: false,
				codigoNaturalidade: '',
				municipioNaturalidade: '',
				apresentouCertidao: null,
				portaria606: null,
			};

			setFormData(body);
		} else if (
			recuperarAtendimento.status === 0 &&
			consultaPesquisaCidadao.form
		) {
			const body: IFormPesquisaRg = {
				...initialValues,
			};

			setFormData(body);
		}
	}, [consultaPesquisaCidadao.form, salvarAtendimento, recuperarAtendimento]);

	const handleRgDuplicado = (item: ConsultaPesquisaCirg) => {
		dispatch(consultaPesquisaCirgSuccess(item));

		const payload: ConsultaPesquisaCivilRequest = {
			ip: login.user.ip,
			numeroRg: item.numeroRg,
			usuario: login.mainframe.idUsuario,
			senha: login.mainframe.senhaMainFrame,
			pesquisaCIRG: true,
			pesquisaCivil: true,
			nomeCompleto: item.nome,
			dataNascimento: unformatDate(item.dataNascimento),
			naturalidade:
				Number(limparMascara(item.codigoNaturalidade.split('-')[0])) ||
				Number(limparMascara(item.codigoNaturalidade)),
			nomePai: item.nomePai,
			nomeMae: item.nomeMae,
		};

		dispatch(consultaPesquisaCivilRequest(payload));
	};

	return (
		<Row>
			<Col span={24}>
				<Section title="Pesquisar">
					<Formik
						validateOnChange={false}
						validateOnBlur={notifications.errors.length > 0}
						initialValues={formData}
						validate={handleValidate}
						onSubmit={handleSubmit}
						enableReinitialize
					>
						{formik => (
							<Form autoComplete="off">
								<Row gutter={[0, 10]}>
									<Col span={14}>
										<RG
											title="RGE/CIN"
											subtitle={
												formik.values.RGempty
													? ''
													: '(Apenas números e sem dígito. Ex: 99999999)'
											}
											isSubtitleInLine
											titleSize="sm"
											numero="numeroRg"
											digito="digitoRg"
											defaultNumero={formik.values.numeroRg}
											defaultDigito={formik.values.digitoRg}
											required={!formik.values.RGempty}
											disabled={formik.values.RGempty}
											readOnly={formik.values.RGempty}
											reset={formik.values.RGempty || resetValues}
											formik={formik}
											onFocus={handleRgFocus}
											valueNumero={formik.values.numeroRg}
											valueDigito={formik.values.digitoRg}
											result={rg => {
												formik.setFieldValue('numeroRg', rg.numeroRg);

												formik.setFieldValue('digitoRg', rg.digitoRg);

												if (
													rg.numeroRg === '' &&
													rg.digitoRg === '' &&
													consultaPesquisaCidadao.status === 400
												) {
													dispatch(clearNotifications());
												}

												if (rg.digitoRg) {
													setFocusBtnPesquisar(true);
												}
											}}
											autoFocus
										/>
									</Col>

									<Col span={10}>
										<Field
											as={CheckBox}
											name="RGempty"
											checked={formik.values.RGempty}
											subtitle="Cidadão não sabe o RGE/CIN / 1ª Via / 2ª via RGE/CIN não Cadastrado"
											onChange={(e: ChangeEvent<HTMLInputElement>) => {
												const { checked } = e.target;

												formik.setFieldValue('RGempty', checked);
												formik.setFieldValue('apresentouCertidao', true);
												formik.setFieldValue('portaria606', false);

												if (notifications.errors.length)
													dispatch(clearNotifications());

												if (checked) {
													formik.setFieldValue('numeroRg', '');

													formik.setFieldValue('digitoRg', '');

													formik.setFieldValue('ufRGMae', 'SP');
												}
											}}
										/>
									</Col>
								</Row>

								<Row justify="center">
									<Col>
										<Row>
											<Col>
												<Field
													as={CheckBox}
													checked={formik.values.apresentouCertidao}
													subtitle="Apresentou certidão"
													name="apresentouCertidao"
													disabled={formik.values.portaria606}
													onChange={(e: ChangeEvent<HTMLInputElement>) => {
														const { checked } = e.target;

														formik.setFieldValue('apresentouCertidao', checked);

														if (notifications.errors.length)
															dispatch(clearNotifications());
													}}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<Field
													as={CheckBox}
													checked={formik.values.portaria606}
													subtitle="Portaria 606/2000"
													name="portaria606"
													disabled={formik.values.apresentouCertidao}
													onChange={(e: ChangeEvent<HTMLInputElement>) => {
														const { checked } = e.target;

														formik.setFieldValue('portaria606', checked);

														if (notifications.errors.length)
															dispatch(clearNotifications());
													}}
												/>
											</Col>
										</Row>
									</Col>
								</Row>

								{formik.values.RGempty && (
									<>
										<Row gutter={[0, 10]}>
											<Col span={18}>
												<Field
													as={Input}
													title="Nome"
													name="nome"
													maxLength={60}
													onChange={(v: React.FormEvent<HTMLInputElement>) =>
														formik.setFieldValue(
															'nome',
															nomeIirgdA(v.currentTarget.value),
														)
													}
													required
													error={!!formik.errors.nome}
												/>
											</Col>
										</Row>

										<Row gutter={[0, 10]}>
											<Col span={18}>
												<Field
													as={Input}
													title="Filiação 1"
													name="nomePai"
													maxLength={60}
													onChange={(v: React.FormEvent<HTMLInputElement>) =>
														formik.setFieldValue(
															'nomePai',
															nomePaisIirgdA(v.currentTarget.value),
														)
													}
													required
													error={!!formik.errors.nomePai}
												/>
											</Col>
										</Row>

										<Row gutter={[0, 10]}>
											<Col span={18}>
												<Field
													as={Input}
													title="Filiação 2"
													name="nomeMae"
													onChange={(v: React.FormEvent<HTMLInputElement>) =>
														formik.setFieldValue(
															'nomeMae',
															nomePaisIirgdA(v.currentTarget.value),
														)
													}
													maxLength={60}
													required
													error={!!formik.errors.nomeMae}
												/>
											</Col>
										</Row>

										<Row gutter={[0, 10]}>
											<Col span={18}>
												<Field
													as={ValidDataInput}
													title="Data de Nascimento"
													name="dataNascimento"
													type="text"
													mask="99/99/9999"
													subtitle="(DD/MM/AAAA)"
													size={25}
													required
													formik={formik}
													onChange={(v: string) => {
														formik.setFieldValue('dataNascimento', v);
													}}
													error={!!formik.errors.dataNascimento}
												/>
											</Col>

											<Col span={6}>
												<Field
													as={CheckBox}
													name="naturalidadeEstrangeira"
													error={!!formik.errors.naturalidadeEstrangeira}
													subtitle="Naturalidade Estrangeira"
													checked={formik.values.naturalidadeEstrangeira}
													onChange={(e: ChangeEvent<HTMLInputElement>) => {
														formik.setFieldValue(
															'naturalidadeEstrangeira',
															e.target.checked,
														);
														formik.setFieldValue('codigoNaturalidade', '');

														formik.setFieldValue('municipioNaturalidade', '');
													}}
												/>
											</Col>
										</Row>

										<Section
											title={
												formik.values.naturalidadeEstrangeira
													? 'Nacionalidade'
													: 'Naturalidade'
											}
											size="sm"
										>
											<Row gutter={[0, 10]}>
												<Col>
													{formik.values.naturalidadeEstrangeira ? (
														<p>Digite o código ou nome do país:</p>
													) : (
														<p>Digite o código ou nome do município:</p>
													)}
												</Col>
											</Row>

											<Row>
												<Col span={7}>
													<CodigoMunicipio
														title="Código"
														titleSize={55}
														type={
															formik.values.naturalidadeEstrangeira
																? 'Nacionalidade'
																: 'Naturalidade'
														}
														subtitle="(Ex: 99999-9)"
														name="codigoNaturalidade"
														reset={
															(formik.values.RGempty || resetValues) &&
															!consultaPesquisaCidadao?.form?.codigoNaturalidade
														}
														formik={formik}
														codigoValue={formik.values.codigoNaturalidade}
														required
														setFocus={() => setFocusBtnPesquisar(true)}
														onFocus={() => setFocusBtnPesquisar(false)}
														result={res => {
															formik.setFieldValue(
																'codigoNaturalidade',
																res.codigoMunicipio,
															);

															formik.setFieldValue(
																'municipioNaturalidade',
																res.municipio,
															);

															setFocusBtnPesquisar(true);
														}}
													/>
												</Col>

												<Col span={7}>
													<Field
														as={Input}
														title="Nome"
														titleSize={50}
														name="municipioNaturalidade"
														required
														disabled
														error={!!formik.errors.municipioNaturalidade}
													/>
												</Col>

												<Col span={1}>
													<BuscarMunicipioNaturalidade
														type={
															formik.values.naturalidadeEstrangeira
																? 'Naturalidade'
																: 'Município'
														}
														formik={formik}
														onClick={() => {
															setFocusBtnPesquisar(false);
														}}
														result={res => {
															formik.setFieldValue(
																'codigoNaturalidade',
																completaZeroEsquerda(
																	`${res.codigo}-${res.digito}`,
																	6,
																),
															);

															formik.setFieldValue(
																'municipioNaturalidade',
																res.descricao,
															);

															setFocusBtnPesquisar(true);
														}}
													/>
												</Col>
											</Row>
										</Section>
									</>
								)}

								<Row gutter={[0, 20]}>
									<Col span={24} />
								</Row>

								<Row justify="center">
									<Col>
										<ButtonImage
											src="limpar"
											onClick={() => {
												formik.setErrors({});

												dispatch(clearNotifications());

												dispatch(consultaPesquisaCidadaoClear());

												dispatch(consultaPesquisaCirgCidadaoClear());

												handleReset(formik);

												setResetValues(true);
											}}
											disabled={btnDisabled}
										/>
									</Col>

									<Col offset={1}>
										<ButtonImage
											type="submit"
											src="pesquisar"
											disabled={btnDisabled}
											setFocus={handleFocusBtnPesquisar}
										/>
									</Col>
								</Row>
							</Form>
						)}
					</Formik>
				</Section>
				{consultaPesquisaCirg.cidadao?.data &&
					consultaPesquisaCirg.cidadao?.data?.length > 1 && (
						<>
							{/* TODO: PROPOR MELHORIA PARA O IIRGD */}
							{/* <div style={{ marginTop: '30px' }}>
								<Alert
									type="info"
									message="Foi localizado mais de um resultado para o RG pesquisado. Selecione abaixo o desejado para continuar com atendimento."
								/>
							</div> */}

							{consultaPesquisaCirg.cidadao?.data?.map(
								(item: ConsultaPesquisaCirg) => {
									return (
										<Section key={item.numeroRg}>
											<Row gutter={[0, 3]} style={{ alignItems: 'center' }}>
												<Col span={8}>
													<TypographyCommon
														text={item.nome.toLocaleUpperCase()}
														fontWeight="bold"
													/>
												</Col>

												<Col span={8}>
													<TypographyCommon text="RG: " fontWeight="bold" />

													<TypographyCommon text={item.numeroRg} />
												</Col>

												<Col span={8}>
													<ButtonImage
														type="button"
														src="selecionar"
														onClick={() => handleRgDuplicado(item)}
													/>
												</Col>
											</Row>

											<Row gutter={[0, 3]} style={{ alignItems: 'center' }}>
												<Col span={8}>
													<TypographyCommon
														text="Filiação 1: "
														fontWeight="bold"
													/>

													<TypographyCommon text={item.nomePai} />
												</Col>

												<Col span={8}>
													<TypographyCommon
														text="Data de Nascimento: "
														fontWeight="bold"
													/>

													<TypographyCommon
														text={formatDate(item.dataNascimento)}
													/>
												</Col>

												<Col span={8} />
											</Row>

											<Row gutter={[0, 3]} style={{ alignItems: 'center' }}>
												<Col span={8}>
													<TypographyCommon
														text="Filiação 2: "
														fontWeight="bold"
													/>

													<TypographyCommon text={item.nomeMae} />
												</Col>

												<Col span={8}>
													{' '}
													<TypographyCommon
														text="Naturalidade: "
														fontWeight="bold"
													/>
													<TypographyCommon
														text={`${item.codigoNaturalidade} | ${item.municipioNaturalidade}`}
													/>
												</Col>

												<Col span={8} />
											</Row>
										</Section>
									);
								},
							)}
						</>
					)}

				<ButtonVoltar route={ROUTE_IIRGD} />
			</Col>
		</Row>
	);
};

export default PesquisaRg;
