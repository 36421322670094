import {
	formatDateISOWithTimeZone,
	limparMascara,
	unformatDateTime,
} from 'utils/genericFunctions';
// import { EnviarSolicitacoesPortalRequest } from 'store/modules/api/mp/enviarSolicitacoesPortal/types';
import { ConsultarDetalheSolicitacaoData } from 'store/modules/api/ministerioPublico/consultarDetalheSolicitacao/types';
import { SolicitarReconhecimentoPaternidadeRequest } from 'store/modules/api/ministerioPublico/solicitarReconhecimentoPaternidade/types';
import { ICadastroSolicitante } from './Forms/form';
// import { getIdData } from './utils';

export const treatInitialFormValues = (
	data: ConsultarDetalheSolicitacaoData,
	solicitante: number,
): ICadastroSolicitante => {
	return {
		...data,
		filho: {
			...data.filho,
			documentosPessoais: [],
		},
		supostoGenitor: {
			...data.supostoGenitor,
			documentosPessoais: [],
		},
		responsavelLegal: {
			...data.responsavelLegal,
			documentosPessoais: [],
		},
		tipoSolicitante: solicitante,
		documentosAnexos: [],
		documentoAnexo: '',
		cpfUsuario: '',
		podeArcarComCustosDoProcesso: '',
		comoFezOPedido: '',
		quemFezOPedido: '',
		outrasInformacoesParaLocalizacaoDoSupostoGenitor: '',
	};
};

export function treatEnviarSolicitacoesPortalRequest(
	values: ICadastroSolicitante,
	cpfAtendente: string,
	solicitante: string,
): SolicitarReconhecimentoPaternidadeRequest {
	const formattedDocumentosPessoaisFilho = values.filho.documentosPessoais
		.filter(doc => doc.tipoDoDocumento !== '')
		.map(doc => {
			if (doc.tipoDoDocumento !== 'Outros') {
				return {
					...doc,
					tipoDoDocumento: 'DocumentoPessoal',
				};
			}
			return doc; // Retorna o documento inalterado se for "Outros"
		});

	const formattedDocumentosPessoaisSupostoGenitoor =
		values.supostoGenitor.documentosPessoais
			.filter(doc => doc.tipoDoDocumento !== '')
			.map(doc => {
				if (doc.tipoDoDocumento !== 'Outros') {
					return {
						...doc,
						tipoDoDocumento: 'DocumentoPessoal',
					};
				}
				return doc; // Retorna o documento inalterado se for "Outros"
			});

	const formattedDocumentosPessoaisResponsavel =
		values.responsavelLegal.documentosPessoais
			.filter(doc => doc.tipoDoDocumento !== '')
			.map(doc => {
				if (doc.tipoDoDocumento !== 'Outros') {
					return {
						...doc,
						tipoDoDocumento: 'DocumentoPessoal',
					};
				}
				return doc; // Retorna o documento inalterado se for "Outros"
			});

	let enumSolicitante = '';

	if (solicitante === 'Filho ou Filha') {
		enumSolicitante = 'FilhoCivilmenteCapaz';
	} else if (solicitante === 'Suposto Genitor') {
		enumSolicitante = 'SupostoGenitor';
	} else if (solicitante === 'Responsável Legal') {
		enumSolicitante = 'ResponsavelLegal';
	}

	const dateRequisicao = new Date();
	const defaultData: SolicitarReconhecimentoPaternidadeRequest = {
		// FORMATACAO PADRÃO
		filho: {
			nome: values.filho.nome,
			dataDeNascimento:
				(values.filho.dataDeNascimento &&
					formatDateISOWithTimeZone(
						new Date(unformatDateTime(values.filho.dataDeNascimento)),
					)?.toString()) ||
				'',
			sexo:
				(values.filho.sexo === 'Masculino' && 'Masculino') ||
				(values.filho.sexo === 'Feminino' && 'Feminino') ||
				'',
			cpf: {
				numero: limparMascara(values.filho.cpf.numero),
			},
			rg: {
				numero: values.filho.rg.numero,
				siglaUF: values.filho.rg.siglaUF,
			},
			enderecoDeResidencia: {
				cep: values.filho.enderecoDeResidencia.cep,
				logradouro: values.filho.enderecoDeResidencia.logradouro,
				numero: values.filho.enderecoDeResidencia.numero,
				complemento: values.filho.enderecoDeResidencia.complemento,
				bairro: values.filho.enderecoDeResidencia.bairro,
				cidade: values.filho.enderecoDeResidencia.cidade,
				siglaUF: values.filho.enderecoDeResidencia.siglaUF,
			},
			email: values.filho.email,
			documentosPessoais: formattedDocumentosPessoaisFilho,
			telefones: [
				{
					ddd: values.filho.telefoneCelular
						? limparMascara(values.filho.telefoneCelular).substr(0, 2)
						: '',
					numero: values.filho.telefoneCelular
						? limparMascara(values.filho.telefoneCelular).substr(2, 9)
						: '',
					tipo: 'Celular',
				},
				{
					ddd: values.filho.telefoneResidencial
						? limparMascara(values.filho.telefoneResidencial).substr(0, 2)
						: '',
					numero: values.filho.telefoneResidencial
						? limparMascara(values.filho.telefoneResidencial).substr(2, 9)
						: '',
					tipo: 'Residencial',
				},
			],
		},
		responsavelLegal: {
			nome: values.responsavelLegal.nome,
			dataDeNascimento:
				(values.responsavelLegal.dataDeNascimento &&
					formatDateISOWithTimeZone(
						new Date(
							unformatDateTime(values.responsavelLegal.dataDeNascimento),
						),
					)?.toString()) ||
				'',
			sexo:
				(values.responsavelLegal.sexo === 'Masculino' && 'Masculino') ||
				(values.responsavelLegal.sexo === 'Feminino' && 'Feminino') ||
				'',
			cpf: {
				numero: limparMascara(values.responsavelLegal.cpf.numero),
			},
			rg: {
				numero: values.responsavelLegal.rg.numero,
				siglaUF: values.responsavelLegal.rg.siglaUF,
			},
			enderecoDeResidencia: {
				cep: values.responsavelLegal.enderecoDeResidencia.cep,
				logradouro: values.responsavelLegal.enderecoDeResidencia.logradouro,
				numero: values.responsavelLegal.enderecoDeResidencia.numero,
				complemento: values.responsavelLegal.enderecoDeResidencia.complemento,
				bairro: values.responsavelLegal.enderecoDeResidencia.bairro,
				cidade: values.responsavelLegal.enderecoDeResidencia.cidade,
				siglaUF: values.responsavelLegal.enderecoDeResidencia.siglaUF,
			},
			email: values.responsavelLegal.email,
			documentosPessoais: formattedDocumentosPessoaisResponsavel,
			telefones: [
				{
					ddd: values.responsavelLegal.telefoneCelular
						? limparMascara(values.responsavelLegal.telefoneCelular).substr(
								0,
								2,
						  )
						: '',
					numero: values.responsavelLegal.telefoneCelular
						? limparMascara(values.responsavelLegal.telefoneCelular).substr(
								2,
								9,
						  )
						: '',
					tipo: 'Celular',
				},
				{
					ddd: values.responsavelLegal.telefoneResidencial
						? limparMascara(values.responsavelLegal.telefoneResidencial).substr(
								0,
								2,
						  )
						: '',
					numero: values.responsavelLegal.telefoneResidencial
						? limparMascara(values.responsavelLegal.telefoneResidencial).substr(
								2,
								9,
						  )
						: '',
					tipo: 'Residencial',
				},
			],
		},
		supostoGenitor: {
			nome: values.supostoGenitor.nome,
			dataDeNascimento:
				(values.supostoGenitor.dataDeNascimento &&
					formatDateISOWithTimeZone(
						new Date(unformatDateTime(values.supostoGenitor.dataDeNascimento)),
					)?.toString()) ||
				'',
			sexo:
				(values.supostoGenitor.sexo === 'Masculino' && 'Masculino') ||
				(values.supostoGenitor.sexo === 'Feminino' && 'Feminino') ||
				'',
			cpf: {
				numero: limparMascara(values.supostoGenitor.cpf.numero),
			},
			rg: {
				numero: values.supostoGenitor.rg.numero,
				siglaUF: values.supostoGenitor.rg.siglaUF,
			},
			nomeDaMae: values.supostoGenitor.nomeDaMae,
			enderecoDeResidencia: {
				cep: values.supostoGenitor.enderecoDeResidencia.cep,
				logradouro: values.supostoGenitor.enderecoDeResidencia.logradouro,
				numero: values.supostoGenitor.enderecoDeResidencia.numero,
				complemento: values.supostoGenitor.enderecoDeResidencia.complemento,
				bairro: values.supostoGenitor.enderecoDeResidencia.bairro,
				cidade: values.supostoGenitor.enderecoDeResidencia.cidade,
				siglaUF: values.supostoGenitor.enderecoDeResidencia.siglaUF,
			},
			email: values.supostoGenitor.email,
			documentosPessoais: formattedDocumentosPessoaisSupostoGenitoor,
			telefones: [
				{
					ddd: values.supostoGenitor.telefoneCelular
						? limparMascara(values.supostoGenitor.telefoneCelular).substr(0, 2)
						: '',
					numero: values.supostoGenitor.telefoneCelular
						? limparMascara(values.supostoGenitor.telefoneCelular).substr(2, 9)
						: '',
					tipo: 'Celular',
				},
				{
					ddd: values.supostoGenitor.telefoneResidencial
						? limparMascara(values.supostoGenitor.telefoneResidencial).substr(
								0,
								2,
						  )
						: '',
					numero: values.supostoGenitor.telefoneResidencial
						? limparMascara(values.supostoGenitor.telefoneResidencial).substr(
								2,
								9,
						  )
						: '',
					tipo: 'Residencial',
				},
			],
		},
		documentosAnexos: values.documentosAnexos,
		podeArcarComCustosDoProcesso: !values.podeArcarComCustosDoProcesso,
		comoFezOPedido: 'ComAuxilioDoPoupatempo',
		quemFezOPedido: enumSolicitante,
		dataDaSolicitacao:
			(dateRequisicao && formatDateISOWithTimeZone(dateRequisicao)) || '',
		outrasInformacoesParaLocalizacaoDoSupostoGenitor:
			values.outrasInformacoesParaLocalizacaoDoSupostoGenitor,
		cpfUsuario: cpfAtendente,
	};

	return defaultData;
}
