import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, AtualizarSiglaRequest } from './types';

export function atualizarSiglaRequest(
	token: string,
	data: AtualizarSiglaRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_SIGLA_REQUEST,
		token,
		payload: data,
	};
}
export function atualizarSiglaSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_SIGLA_SUCCESS,
		payload,
	};
}
export function atualizarSiglaFailure(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_SIGLA_FAILURE,
		payload: null,
	};
}
export function atualizarSiglaClear(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_SIGLA_CLEAR,
		payload: null,
	};
}
