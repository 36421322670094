import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, CadastrarSiglaRequest } from './types';

export function cadastrarSiglaRequest(
	token: string,
	data: CadastrarSiglaRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_SIGLA_REQUEST,
		token,
		payload: data,
	};
}

export function cadastrarSiglaSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_SIGLA_SUCCESS,
		payload,
	};
}

export function cadastrarSiglaFailure(): ReducerActionRotaSP  {
	return {
		type: Types.CADASTRAR_SIGLA_FAILURE,
		payload: null,
	};
}
export function cadastrarSiglaClear(): ReducerActionRotaSP  {
	return {
		type: Types.CADASTRAR_SIGLA_CLEAR,
		payload: null,
	};
}
