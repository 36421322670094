import { ReducerAction, ReducerActionRotaSP } from 'store/modules/types';

import { Types } from './types';
import { IParametroData } from '../parametros/types';

export interface IConsultaServicosRequest {
	page?: number;
	size?: number;
	descricaoServico?: string;
}

export interface IConsultaParametrosServicosRequest {
	idServico: number;
}

export interface ICadastraServicosRequest {
	id?: number;
	descricao: string;
}

export interface IAlteraParametrosServicosRequest {
	body: IParametroData[];
	idServico?: number;
	situation: string;
}

export function consultaServicos(
	token: string,
	data: IConsultaServicosRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTA_SERVICOS,
		payload: data,
		token,
	};
}
export function consultaServicosSuccess(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_SERVICOS_SUCCESS,
		payload,
	};
}
export function consultaServicosFailure(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_SERVICOS_FAILURE,
		payload,
	};
}

export function consultaParametrosServicos(
	token: string,
	data: IConsultaParametrosServicosRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTA_PARAMETROS_SERVICOS,
		token,
		payload: data,
	};
}
export function consultaParametrosServicosSuccess(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_PARAMETROS_SERVICOS_SUCCESS,
		payload,
	};
}
export function consultaParametrosServicosFailure(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_PARAMETROS_SERVICOS_FAILURE,
		payload,
	};
}

export function cadastraServicos(
	token: string,
	data: ICadastraServicosRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRA_SERVICOS,
		token,
		payload: data,
	};
}
export function cadastraServicosSuccess(payload: []): ReducerAction {
	return {
		type: Types.CADASTRA_SERVICOS_SUCCESS,
		payload,
	};
}
export function cadastraServicosFailure(payload: []): ReducerAction {
	return {
		type: Types.CADASTRA_SERVICOS_FAILURE,
		payload,
	};
}

export function alteraServicos(
	token: string,
	data: ICadastraServicosRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ALTERA_SERVICOS,
		token,
		payload: data,
	};
}
export function alteraServicosSuccess(payload: []): ReducerAction {
	return {
		type: Types.ALTERA_SERVICOS_SUCCESS,
		payload,
	};
}
export function alteraServicosFailure(payload: []): ReducerAction {
	return {
		type: Types.ALTERA_SERVICOS_FAILURE,
		payload,
	};
}

export function alteraParametrosServicos(
	token: string,
	data: IAlteraParametrosServicosRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ALTERA_PARAMETROS_SERVICOS,
		token,
		payload: data,
	};
}
export function alteraParametrosServicosSuccess(
	payload: IAlteraParametrosServicosRequest,
): ReducerAction {
	return {
		type: Types.ALTERA_PARAMETROS_SERVICOS_SUCCESS,
		payload,
	};
}
export function alteraParametrosServicosFailure(payload: []): ReducerAction {
	return {
		type: Types.ALTERA_PARAMETROS_SERVICOS_FAILURE,
		payload,
	};
}

export function removeServicos(
	token: string,
	data: number,
): ReducerActionRotaSP {
	return {
		type: Types.REMOVE_SERVICOS,
		token,
		payload: data,
	};
}
export function removeServicosSuccess(payload: number): ReducerAction {
	return {
		type: Types.REMOVE_SERVICOS_SUCCESS,
		payload,
	};
}
export function removeServicosFailure(payload: []): ReducerAction {
	return {
		type: Types.REMOVE_SERVICOS_FAILURE,
		payload,
	};
}

export function clearRedirect(): ReducerAction {
	return {
		type: Types.REDIRECT_SERVICOS_CLEAR,
		payload: null,
	};
}

export function nextStepClear(): ReducerAction {
	return {
		type: Types.NEXT_STEP_SERVICOS_CLEAR,
		payload: null,
	};
}
