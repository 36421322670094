import { ICadastroRgState } from 'store/modules/api/iirgd/cadastroRg/types';
import { ContabilizacaoIirgdRequest } from 'store/modules/api/iirgd/contabilizacaoEmissaoCarteira/types';
import { SalvarAtendimento } from 'store/modules/api/sgu/atendimento/salvar/types';
import { ILogin } from 'store/modules/api/sgu/loginUnico/types';
import { IFormCadastroRg } from '../CadastroRg/form';
import { handleOrigem } from '../Dare/Pesquisa/Resultado';
import { getPagamentoTaxas } from './Buttons/ImprimirProtocolo/functions';

export function formatarContabilizacao(
	atendimento: SalvarAtendimento,
	cadastroRg: ICadastroRgState,
	login: ILogin,
	restricaoCriminal: boolean,
	tipoCili: string | null,
	resultadoDigitais: boolean,
	primeiraViaCin: boolean,
): ContabilizacaoIirgdRequest {
	const { form, tipo, perguntas } = cadastroRg;
	const taxas = getPagamentoTaxas(cadastroRg);
	return {
		idAtendimento: atendimento?.data?.idAtendimento ?? '',
		idCidadao: atendimento?.data?.idCidadao ?? '',
		primeiraViaRg: !primeiraViaCin && tipo.primeiraVia,
		primeiraViaCin,
		numeroRg: form?.principal?.numeroRg ?? '',
		isento2Via10952: !!form.observacoes.segundaVia?.isento2Via10952,
		declaracaoPobreza: !!form.observacoes.segundaVia?.declaracaoPobreza,
		isento2Via062: !!form.observacoes.segundaVia?.isento2Via062,
		tipoEntrega: form.principal?.entrega ? form.principal?.entrega : 'P',
		cpf: form.principal?.cpf ?? '',
		ip: login.user.ip,
		menorIdade: !cadastroRg.info.maior,
		reuso: {
			confrontoRealizado: resultadoDigitais,
			temTodosOsDedos: perguntas.todosDedos,
			erroReuso: !resultadoDigitais,
		},
		naturalidadeEstrangeira: !!form.principal?.pMinLei1,
		criminal: restricaoCriminal,
		inclusaoCpf: form.principal?.inscricaoCpf === 'true',
		inclusaoNomeSocial: !!form.principal?.nomeSocial,
		ciliSimplificada:
			tipoCili !== null ||
			tipoCili === 'A' ||
			tipoCili === 'B' ||
			tipoCili === 'C',
		cpfOperador: login.user.cpf,
		codigoPosto: login.user.posto,
		nomePosto: login.user.nomePosto,
		dareVinculada: !!taxas.pgtoTxCorreio || !!taxas.pgtoTx2via,
		taxaCorreio: !!taxas.pgtoTxCorreio,
		taxaEmissao: !!taxas.pgtoTx2via,
		statusDare: cadastroRg.dare?.statusDescricao ?? '',
		origemDare: cadastroRg.dare?.origem
			? handleOrigem(cadastroRg.dare?.origem)
			: '',
		dataPagamento: cadastroRg.dare?.pagamento?.dataRecebimento ?? '',
		atendimentoCin: !!(form.principal && form.principal.dni === '10977'),
	};
}

function getFieldsReusoParcial(
	original: IFormCadastroRg,
	formulario: IFormCadastroRg,
): string {
	/**
	 * Campos a serem validados:
	 * Principal: Nome
	 * Principal: Filiação 1
	 * Principal: Nome do Mãe
	 * Principal: Sexo
	 * Principal: Data de nascimento
	 * Principal: Município
	 *
	 * Documento apresentado: Tipo de documento
	 * Documento apresentado: Comarca
	 * Documento apresentado: Cartório
	 * Documento apresentado: Livro
	 * Documento apresentado: Folha
	 * Documento apresentado: Número
	 * Documento apresentado: Data do documento
	 * Documento apresentado: Port. Min-Lei
	 *
	 * Dados adicionais - Cidadão: Estado Cívil
	 * Dados adicionais - Cidadão: Cutis
	 * Dados adicionais - Cidadão: Cabelos
	 * Dados adicionais - Cidadão: Olhos
	 * Dados adicionais - Cidadão: Profissão
	 *
	 * Dados adicionais - RG: CPF
	 * Dados adicionais - RG: PIS/NIS
	 * Dados adicionais - RG: PASEP
	 */

	const campos: string[] = [];

	if (formulario.nome !== original.nome) {
		campos.push('nome');
	}
	if (formulario.nomePai !== original.nomePai) {
		campos.push('nomePai');
	}
	if (formulario.nomeMae !== original.nomeMae) {
		campos.push('nomeMae');
	}
	if (formulario.sexo !== original.sexo) {
		campos.push('sexo');
	}
	if (formulario.dataNascimento !== original.dataNascimento) {
		campos.push('dataNascimento');
	}
	if (formulario.codigoNaturalidade !== original.codigoNaturalidade) {
		campos.push('codigoNaturalidade');
	}
	if (formulario.dbas !== original.dbas) {
		campos.push('dbas');
	}
	if (formulario.comarca !== original.comarca) {
		campos.push('comarca');
	}
	if (formulario.cartorio !== original.cartorio) {
		campos.push('cartorio');
	}
	if (formulario.livro !== original.livro) {
		campos.push('livro');
	}
	if (formulario.folha !== original.folha) {
		campos.push('folha');
	}
	if (formulario.numero !== original.numero) {
		campos.push('numero');
	}
	if (formulario.dataDocumento !== original.dataDocumento) {
		campos.push('dataDocumento');
	}
	if (formulario.pMinLei1 !== original.pMinLei1) {
		campos.push('pMinLei1');
	}
	if (formulario.pMinLei2 !== original.pMinLei2) {
		campos.push('pMinLei2');
	}
	if (formulario.estadoCivil !== original.estadoCivil) {
		campos.push('estadoCivil');
	}
	if (formulario.cutis !== original.cutis) {
		campos.push('cutis');
	}
	if (formulario.cabelos !== original.cabelos) {
		campos.push('cabelos');
	}
	if (formulario.olhos !== original.olhos) {
		campos.push('olhos');
	}
	if (formulario.profissao !== original.profissao) {
		campos.push('profissao');
	}
	if (formulario.cpf !== original.cpf) {
		campos.push('cpf');
	}
	if (formulario.pis !== original.pis) {
		campos.push('pis');
	}
	if (formulario.pasep !== original.pasep) {
		campos.push('pasep');
	}
	if (campos.length) {
		let novosCampos: string = '';
		campos.map((item: string, index: number) => {
			novosCampos += item + (index !== campos.length - 1 ? ',' : '');
			return novosCampos;
		});
		return `Campos alterados: ${novosCampos} - Confronto realizado com sucesso.`;
	}

	return 'Não teve alteração dos campos de Reuso Parcial';
}

function getFieldsReusoTotal(
	original: IFormCadastroRg,
	formulario: IFormCadastroRg,
): string {
	/**
	 * Campos a serem validados:
	 *
	 * Principal: Nome social
	 *
	 * Documento apresentado: Data do documento
	 *
	 * Dados adicionais - Cidadão: Altura
	 * Dados adicionais - Cidadão: Grau de instrução
	 * Dados adicionais - Cidadão: CEP
	 * Dados adicionais - Cidadão: Logradouro
	 * Dados adicionais - Cidadão: Número
	 * Dados adicionais - Cidadão: Complemento
	 * Dados adicionais - Cidadão: Bairro
	 * Dados adicionais - Cidadão: Munícipio
	 *
	 * Dados adicionais - Trabalho: CEP
	 * Dados adicionais - Trabalho: Logradouro
	 * Dados adicionais - Trabalho: Número
	 * Dados adicionais - Trabalho: Complemento
	 * Dados adicionais - Trabalho: Bairro
	 * Dados adicionais - Trabalho: Munícipio
	 */

	const campos: string[] = [];

	if (formulario.nomeSocial !== original.nomeSocial) {
		campos.push('nomeSocial');
	}
	if (formulario.dataDocumento !== original.dataDocumento) {
		campos.push('dataDocumento');
	}
	if (formulario.altura !== original.altura) {
		campos.push('altura');
	}
	if (formulario.grauInstrucao !== original.grauInstrucao) {
		campos.push('grauInstrucao');
	}
	if (formulario.cepResidencia !== original.cepResidencia) {
		campos.push('cepResidencia');
	}
	if (formulario.enderecoResidencia !== original.enderecoResidencia) {
		campos.push('enderecoResidencia');
	}
	if (formulario.numeroResidencia !== original.numeroResidencia) {
		campos.push('numeroResidencia');
	}
	if (formulario.bairroResidencia !== original.bairroResidencia) {
		campos.push('bairroResidencia');
	}
	if (formulario.complementoResidencia !== original.complementoResidencia) {
		campos.push('complementoResidencia');
	}
	if (formulario.complementoResidencia !== original.complementoResidencia) {
		campos.push('complementoResidencia');
	}
	if (
		formulario.codigoMunicipioResidencia !== original.codigoMunicipioResidencia
	) {
		campos.push('codigoMunicipioResidencia');
	}
	if (formulario.cepTrabalho !== original.cepTrabalho) {
		campos.push('cepTrabalho');
	}

	if (formulario.enderecoTrabalho !== original.enderecoTrabalho) {
		campos.push('enderecoTrabalho');
	}
	if (formulario.numeroTrabalho !== original.numeroTrabalho) {
		campos.push('numeroTrabalho');
	}
	if (formulario.complementoTrabalho !== original.complementoTrabalho) {
		campos.push('complementoTrabalho');
	}
	if (formulario.bairroTrabalho !== original.bairroTrabalho) {
		campos.push('bairroTrabalho');
	}

	if (formulario.codigoMunicipioTrabalho !== original.codigoMunicipioTrabalho) {
		campos.push('codigoMunicipioTrabalho');
	}

	if (formulario.descMunicipioTrabalho !== original.descMunicipioTrabalho) {
		campos.push('descMunicipioTrabalho');
	}
	if (formulario.solicitacao !== original.solicitacao) {
		campos.push('solicitacao');
	}
	if (formulario.foneResidencia !== original.foneResidencia) {
		campos.push('foneResidencia');
	}
	if (formulario.foneComercial !== original.foneComercial) {
		campos.push('foneComercial');
	}
	if (formulario.celular !== original.celular) {
		campos.push('celular');
	}
	if (formulario.operadora !== original.operadora) {
		campos.push('operadora');
	}
	if (formulario.email !== original.email) {
		campos.push('email');
	}

	if (formulario.tituloEleitor !== original.tituloEleitor) {
		campos.push('tituloEleitor');
	}

	if (formulario.email !== original.email) {
		campos.push('email');
	}

	if (formulario.tituloEleitor !== original.tituloEleitor) {
		campos.push('tituloEleitor');
	}
	if (formulario.ctpsNum !== original.ctpsNum) {
		campos.push('ctpsNum');
	}
	if (formulario.ctpsSerie !== original.ctpsSerie) {
		campos.push('ctpsSerie');
	}
	if (formulario.ctpsSerieUf !== original.ctpsSerieUf) {
		campos.push('ctpsSerieUf');
	}
	if (formulario.certidaoMilitar !== original.certidaoMilitar) {
		campos.push('certidaoMilitar');
	}
	if (formulario.cnh !== original.cnh) {
		campos.push('cnh');
	}
	if (formulario.nascSaude !== original.nascSaude) {
		campos.push('nascSaude');
	}
	if (formulario.identProf1 !== original.identProf1) {
		campos.push('identProf1');
	}
	if (formulario.orgaoEmissor1 !== original.orgaoEmissor1) {
		campos.push('orgaoEmissor1');
	}
	if (formulario.identProf2 !== original.identProf2) {
		campos.push('identProf2');
	}
	if (formulario.orgaoEmissor2 !== original.orgaoEmissor2) {
		campos.push('orgaoEmissor2');
	}
	if (formulario.identProf3 !== original.identProf3) {
		campos.push('identProf3');
	}
	if (formulario.orgaoEmissor3 !== original.orgaoEmissor3) {
		campos.push('orgaoEmissor3');
	}
	if (campos.length) {
		let novosCampos: string = '';
		campos.map((item: string, index: number) => {
			novosCampos += item + (index !== campos.length - 1 ? ',' : '');
			return novosCampos;
		});
		return `Campos alterados: ${novosCampos} - Confronto realizado com sucesso.`;
	}

	return 'Não teve alteração dos campos de Reuso Total';
}

export function getMensagemReuso(cadastro: ICadastroRgState): string {
	let response: string = '';

	if (
		cadastro.tipo.reusoParcial &&
		cadastro.original &&
		cadastro.form.principal
	) {
		response = getFieldsReusoParcial(
			cadastro.original,
			cadastro.form.principal,
		);
	}
	if (
		cadastro.tipo.reusoTotal &&
		cadastro.original &&
		cadastro.form.principal
	) {
		response = getFieldsReusoTotal(cadastro.original, cadastro.form.principal);
	}
	return response;
}
