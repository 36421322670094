import React, { useEffect, useCallback, ChangeEvent, useState } from 'react';

import { FormikProps, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd';

// REDUX
import { ApplicationState } from 'store';
import { cadastroRgDataNascimento } from 'store/modules/api/iirgd/cadastroRg/actions';
import data from 'store/modules/api/iirgd/enum';
import { HABILITA_MULTIFILIACAO } from 'store/modules/api/sgu/loginUnico/constants';

// COMPONENTS
import Section from 'components/Common/Section';
import RG from 'components/Common/Form/Fields/RG';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask/index';
import Radio from 'components/Common/Form/Radio';
import CodigoMunicipio from 'components/Common/Form/Fields/Municipio/Codigo';
import BuscarMunicipioNaturalidade from 'components/Common/SearchButtons/MunicipioNaturalidade';

// UTILS
import { patternFormat, limparMascara } from 'utils/genericFunctions';
import { nomeIirgdA } from 'utils/stringFunctions';

// FORM

import CheckBox from 'components/Common/Form/Checkbox';
import { IParametrosLocalidade } from 'store/modules/api/sgu/loginUnico/types';
import TooltipInfo from 'components/Common/Tooltips/Info';
import CPF from 'components/Common/Form/Fields/CPF';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import hasError from 'utils/getFormErrors';
import { IFormCadastroRg } from '../../form';
import DNI from './DNI';

interface Props {
	formik: FormikProps<IFormCadastroRg>;
	bloqueioPortaria606: boolean | null;
	erroCIN: boolean;
}

const Principal: React.FC<Props> = ({
	formik,
	bloqueioPortaria606,
	erroCIN,
}) => {
	const { setFieldValue } = formik;

	const dispatch = useDispatch();

	const { notifications } = useSelector((state: ApplicationState) => state.app);

	const { cadastroRg, consultaPesquisaCidadao, scriptPreAtendimento } =
		useSelector((state: ApplicationState) => state.api.iirgd);

	const { loginUnico } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);
	const { consultaPesquisaCirg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	const [disabled2Via, setDisabled2Via] = useState<boolean>(false);
	const [modalDNI, setModalDNI] = useState<boolean>(false);
	// const [disabledDni, setDisabledDni] = useState<boolean>(false);

	// MOSTRAR
	// 1) exibir se não vier consultaPesquisaCirg.data?.cpf
	// 2) exibir cadastroRg?.form?.principal?.inscricaoCpf === 'true' && !cadastroRg?.form?.principal?.cpf

	useEffect(() => {
		setDisabled2Via(
			!!consultaPesquisaCirg.data?.lote &&
				consultaPesquisaCirg.data?.lote.indexOf('2') !== -1 &&
				consultaPesquisaCirg.data?.lote.indexOf('2') !== undefined &&
				consultaPesquisaCirg.data?.cpf !== '',
		);
	}, [consultaPesquisaCirg]);

	useEffect(() => {
		dispatch(cadastroRgDataNascimento(formik.values.dataNascimento));
	}, [dispatch, formik.values.dataNascimento]);

	const handleChangeCheckboxCpf = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const { checked } = e.target;
			formik.setFieldValue('cpf', '');
			formik.setFieldValue('naoPossuiCpf', checked);
		},
		[formik],
	);

	const handleChangeDNI = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			console.log(e);
			const checked = e.target.value;

			formik.setFieldValue('dni', checked);
			setModalDNI(true);
		},
		[formik],
	);

	const handleDataNascimento = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const { value } = event.target;
			const dataNascimento = patternFormat(limparMascara(value), '##/##/####');

			setFieldValue('dataNascimento', value);

			if (dataNascimento.length === 10) {
				dispatch(cadastroRgDataNascimento(dataNascimento));
			}
		},
		[setFieldValue, dispatch],
	);

	const handleMunicipioSelecionado = useCallback(
		res => {
			setFieldValue('codigoNaturalidade', `${res.codigo}-${res.digito}`);
			setFieldValue('municipioNaturalidade', res.descricao);
		},
		[setFieldValue],
	);

	const [edicaoDesabilitada, setEdicaoDesabilitada] = useState<boolean>(false);

	const verificaEdicao = useCallback(() => {
		return setEdicaoDesabilitada(!!consultaPesquisaCidadao.form?.RGempty);
	}, [consultaPesquisaCidadao]);

	useEffect(() => {
		verificaEdicao();
	}, [verificaEdicao]);

	const verifyLocationIsAllowedMultifiliacao = useCallback(() => {
		let isAllowed = false;
		loginUnico.user.parametrosSistema.forEach((item: IParametrosLocalidade) => {
			if (
				item.sigla.toLowerCase() === HABILITA_MULTIFILIACAO.toLowerCase() &&
				item.valor.toLowerCase() === 'true'
			) {
				isAllowed = true;
			}
		});
		return isAllowed;
	}, [loginUnico.user.parametrosSistema]);

	return (
		<>
			<Row gutter={[0, 10]}>
				{(cadastroRg.config.acervo ||
					cadastroRg.config.edicao ||
					cadastroRg.form.principal?.numeroRg) && (
					<Col span={14}>
						<RG
							title="RGE/CIN"
							titleSize="lg"
							numero="numeroRg"
							isAcervo={cadastroRg.config.acervo}
							defaultNumero={
								(scriptPreAtendimento?.numeroRg &&
									scriptPreAtendimento?.rgOuAcervo &&
									scriptPreAtendimento.numeroRg) ||
								(cadastroRg.form.principal?.numeroRg &&
									limparMascara(cadastroRg.form.principal?.numeroRg) !== '' &&
									cadastroRg.form.principal?.numeroRg) ||
								(formik.values?.numeroRg &&
									limparMascara(formik.values?.numeroRg) !== '' &&
									formik.values?.numeroRg) ||
								''
							}
							required
							disabled={cadastroRg.config.edicao || cadastroRg.config.novo}
							readOnly={
								cadastroRg.config.edicao ||
								cadastroRg.config.novo ||
								cadastroRg.config.acervo
							}
							formik={formik}
							result={res => {
								formik.setFieldValue('numeroRg', res.numeroRg);
							}}
						/>
					</Col>
				)}

				<Col span={6}>
					<Field
						as={Input}
						title="Posto de identificação"
						titleSize="lg"
						name="pidt"
						required
						readOnly
						error={!!formik.errors.pidt}
					/>
				</Col>
			</Row>

			<Row gutter={[0, 10]}>
				<Col span={20}>
					<Field
						autocomplete="off"
						as={Input}
						title="Nome"
						titleSize="lg"
						name="nome"
						maxLength={60}
						required
						disabled={edicaoDesabilitada || !!bloqueioPortaria606}
						value={nomeIirgdA(formik.values.nome)}
						error={!!formik.errors.nome || erroCIN}
					/>
				</Col>
			</Row>

			<Row gutter={[0, 10]}>
				<Col span={20}>
					<Field
						autocomplete="off"
						as={Input}
						title="Nome Social"
						titleSize="lg"
						name="nomeSocial"
						maxLength={100}
						value={nomeIirgdA(formik.values.nomeSocial)}
						error={!!formik.errors.nomeSocial}
					/>
				</Col>
			</Row>

			<Row gutter={[0, 10]}>
				<Col span={20}>
					<Field
						autocomplete="off"
						as={Input}
						title="Filiação 1"
						titleSize="lg"
						name="nomePai"
						maxLength={100}
						required
						value={nomeIirgdA(formik.values.nomePai)}
						disabled={edicaoDesabilitada || !!bloqueioPortaria606}
						error={!!formik.errors.nomePai}
					/>
				</Col>
			</Row>

			<Row gutter={[0, 10]}>
				<Col span={20}>
					<Field
						autocomplete="off"
						as={Input}
						title="Filiação 2"
						titleSize="lg"
						name="nomeMae"
						maxLength={100}
						required
						value={nomeIirgdA(formik.values.nomeMae)}
						disabled={edicaoDesabilitada || !!bloqueioPortaria606}
						error={!!formik.errors.nomeMae || erroCIN}
					/>
				</Col>
				{verifyLocationIsAllowedMultifiliacao() && (
					<Col offset={1} span={3}>
						<Field
							autocomplete="off"
							as={CheckBox}
							name="multifiliacao"
							checked={formik.values.multifiliacao}
							subtitle="Multifiliação"
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								const { checked } = e.target;

								formik.setFieldValue('multifiliacao', checked);
							}}
						/>
					</Col>
				)}
			</Row>

			{formik.values.multifiliacao && verifyLocationIsAllowedMultifiliacao() && (
				<>
					<Row gutter={[0, 10]}>
						<Col span={20}>
							<Field
								autocomplete="off"
								as={Input}
								title="Filiação Três"
								titleSize="lg"
								name="filiacaoTres"
								maxLength={100}
								required
								value={nomeIirgdA(formik.values.filiacaoTres)}
								error={!!formik.errors.filiacaoTres}
							/>
						</Col>
					</Row>
					<Row gutter={[0, 10]}>
						<Col span={20}>
							<Field
								autocomplete="off"
								as={Input}
								title="Filiação Quatro"
								titleSize="lg"
								name="filiacaoQuatro"
								maxLength={100}
								value={nomeIirgdA(formik.values.filiacaoQuatro)}
								error={!!formik.errors.filiacaoQuatro}
							/>
						</Col>
					</Row>
					<Row gutter={[0, 10]}>
						<Col span={20}>
							<Field
								autocomplete="off"
								as={Input}
								title="Filiação Cinco"
								titleSize="lg"
								name="filiacaoCinco"
								maxLength={100}
								value={nomeIirgdA(formik.values.filiacaoCinco)}
								error={!!formik.errors.filiacaoCinco}
							/>
						</Col>
					</Row>
					<Row gutter={[0, 10]}>
						<Col span={20}>
							<Field
								autocomplete="off"
								as={Input}
								title="Filiação Seis"
								titleSize="lg"
								name="filiacaoSeis"
								maxLength={100}
								value={nomeIirgdA(formik.values.filiacaoSeis)}
								error={!!formik.errors.filiacaoSeis}
							/>
						</Col>
					</Row>
				</>
			)}

			<Row gutter={[0, 10]}>
				<Col span={10}>
					<Field
						autocomplete="off"
						as={InputMask}
						title="Data de Nascimento"
						titleSize="lg"
						name="dataNascimento"
						mask="99/99/9999"
						onChange={handleDataNascimento}
						required
						disabled={edicaoDesabilitada || !!bloqueioPortaria606}
						error={!!formik.errors.dataNascimento || erroCIN}
					/>
				</Col>
				<Col span={7} offset={1}>
					<Field
						autocomplete="off"
						as={Radio}
						title="Sexo"
						titleSize="md"
						name="sexo"
						options={data.sexo}
						defaultValue={formik.values.sexo}
						disabled={
							(edicaoDesabilitada &&
								!cadastroRg.tipo.primeiraVia &&
								!cadastroRg.config.acervo) ||
							!!bloqueioPortaria606
						}
						required
						error={!!formik.errors.sexo}
					/>
				</Col>
			</Row>

			<Row gutter={[0, 10]}>
				<Col span={14}>
					<CPF
						title="Número CPF"
						titleSize="lg"
						subtitle={disabled2Via ? '' : '(Apenas números)'}
						numero="cpf"
						disabled={disabled2Via || formik.values.naoPossuiCpf}
						formik={formik}
						onKeyDown={() => {
							if (notifications.errors.length > 0) {
								dispatch(clearNotifications());
							}
						}}
						error={hasError(formik.errors, 'cpf')}
					/>
				</Col>
				<Col span={1}>
					{disabled2Via && (
						<div
							style={{
								alignItems: 'center',
								display: 'flex',
								height: '100%',
								marginLeft: '5px',
							}}
						>
							<TooltipInfo content="Alteração de CPF apenas em Retaguarda!" />
						</div>
					)}
				</Col>
				<Col span={9}>
					<Field
						autocomplete="off"
						as={CheckBox}
						name="naoPossuiCpf"
						checked={formik.values.naoPossuiCpf}
						subtitle="Cidadão não sabe CPF"
						onChange={handleChangeCheckboxCpf}
						error={formik.errors.naoPossuiCpf}
						disabled={disabled2Via}
					/>
				</Col>
			</Row>

			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>

			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Section title="Naturalidade" size="sm">
						<Row gutter={[0, 10]}>
							<Col>
								<p>Digite o código ou nome do município:</p>
							</Col>
						</Row>

						<Row>
							<Col span={8}>
								<CodigoMunicipio
									title="Código"
									titleSize={55}
									type={
										formik.values.dbas === 'NL' ||
										formik.values.dbas === 'NZ' ||
										formik.values.dbas === 'TI' ||
										formik.values.dbas === 'TN' ||
										(formik.values.dbas === 'CN' &&
											formik.values.livro[0] === 'K') ||
										(formik.values.dbas === 'CN' &&
											formik.values.livro[0] === 'Y') ||
										(formik.values.dbas === 'CC' &&
											formik.values.livro[0] === 'T') ||
										(formik.values.dbas === 'CC' &&
											formik.values.livro[0] === 'W')
											? 'Nacionalidade'
											: 'Naturalidade'
									}
									subtitle="(Ex: 99999-9)"
									name="codigoNaturalidade"
									formik={formik}
									codigoValue={formik.values.codigoNaturalidade}
									required
									disabled={edicaoDesabilitada || !!bloqueioPortaria606}
									result={descricao => {
										if (descricao.municipio) {
											setFieldValue(
												'municipioNaturalidade',
												descricao.municipio,
											);
										}
										if (descricao.codigoMunicipio) {
											setFieldValue(
												'codigoNaturalidade',
												`${descricao.codigoMunicipio}-${descricao.codigoMunicipio}`,
											);
										}
									}}
								/>
							</Col>

							<Col span={10}>
								<Field
									autocomplete="off"
									style={{ cursor: 'text' }}
									as={Input}
									title="Nome"
									titleSize={50}
									name="municipioNaturalidade"
									required
									disabled
									error={!!formik.errors.municipioNaturalidade}
								/>
							</Col>

							<Col span={1}>
								<BuscarMunicipioNaturalidade
									formik={formik}
									type={
										formik.values.dbas === 'NL' ||
										formik.values.dbas === 'NZ' ||
										formik.values.dbas === 'TI' ||
										formik.values.dbas === 'TN' ||
										(formik.values.dbas === 'CN' &&
											formik.values.livro[0] === 'K') ||
										(formik.values.dbas === 'CN' &&
											formik.values.livro[0] === 'Y') ||
										(formik.values.dbas === 'CC' &&
											formik.values.livro[0] === 'T') ||
										(formik.values.dbas === 'CC' &&
											formik.values.livro[0] === 'W')
											? 'Naturalidade'
											: 'Município'
									}
									disabled={edicaoDesabilitada || !!bloqueioPortaria606}
									result={handleMunicipioSelecionado}
								/>
							</Col>
						</Row>
					</Section>
				</Col>
			</Row>

			<Section
				title="Tipo de Docto. a Emitir"
				size="sm"
				style={
					cadastroRg.config.reedicao ? { opacity: '60%' } : { opacity: '100%' }
				}
			>
				<Row gutter={[0, 10]} justify="center">
					<Row gutter={[0, 10]}>
						<Col span={24} />
					</Row>
					<Col>
						<Field
							autocomplete="off"
							as={Radio}
							name="dni"
							options={[
								{
									label: 'RGE',
									value: '0',
								},
								{
									label: 'CIN',
									value: '10977',
								},
							]}
							onChange={handleChangeDNI}
							defaultValue={formik.values.dni}
							disabled={cadastroRg.config.reedicao}
							required
							error={!!formik.errors.dni}
						/>
						<Col span={24} />
					</Col>
				</Row>
			</Section>

			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>

			<DNI
				showModal={modalDNI}
				selected={formik.values.dni || '0'}
				onCancel={() => {
					setModalDNI(false);
					formik.setFieldValue('dni', '');
				}}
				onOk={() => {
					if (notifications.errors.length > 0) {
						dispatch(clearNotifications());
					}
					setModalDNI(false);
					// if (!formik.values.naoPossuiCpf || !!formik.values.cpf) {
					// 	setDisabledDni(true);
					// }
				}}
			/>
		</>
	);
};

export default Principal;
