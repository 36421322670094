import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { PATH_PREFEITURAS_API } from 'services/rotasp/path';
import { ApiResponse, getApi } from 'services/rotasp/api';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import {
	consultaPrefeiturasServicosCanaisSuccess,
	consultaPrefeiturasServicosCanaisFailure,
	IPrefeiturasServicosCanais,
} from './actions';

function* consultaPrefeiturasServicosCanais(request: ReducerAction) {
	const {
		token = '',
		payload,
	}: { token?: string; payload: IPrefeiturasServicosCanais } = request;

	const allowedStatus = [200, 204];

	const response: ApiResponse = yield call(
		getApi,
		token,
		PATH_PREFEITURAS_API,
		`prefeituras/servicos/${payload.idPrefeituraServico}/canais`,
		{},
		undefined,
		undefined,
		undefined,
		undefined,
		allowedStatus,
	);

	if (response.status === 200) {
		yield put(consultaPrefeiturasServicosCanaisSuccess(response));
	} else if (response.error) {
		yield put(consultaPrefeiturasServicosCanaisFailure(response.message));
	} else if (response.status === 204) {
		yield put(consultaPrefeiturasServicosCanaisFailure([]));
	}
}

export default all([
	takeLatest(
		Types.CONSULTA_PREFEITURA_SERVICOS_CANAIS,
		consultaPrefeiturasServicosCanais,
	),
]);
