export enum Types {
	CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES = '@servicosPrefeituras/CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES',
	CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES_SUCCESS = '@servicosPrefeituras/CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES_SUCCESS',
	CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES_FAILURE = '@servicosPrefeituras/CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES_FAILURE',

	REMOVE_PREFEITURAS_SERVICOS = '@servicosPrefeituras/REMOVE_PREFEITURAS_SERVICOS',
}

export interface ServicosPrefeiturasState {
	servicosPrefeiturasData: IServicosPrefeiturasData;
}

export interface IdentificacaoServicosValidacoes {
	chave: string;
	valor: string;
}

export interface IPrefeiturasServicosValidacoesRequest {
	identificacao: IdentificacaoServicosValidacoes[] | [];
	parcelas: string[] | null;
}

export interface IServicosPrefeiturasData {
	pdf?: string;
	endereco?: IDadosImovel;
	identificacao?: string;
	parcelas?: IParcelas[];
	proximoPrefeituraServico?: number;
	codigoBarras?: string;
}

interface IDadosImovel {
	bairro: string;
	cep: string;
	cidade: string;
	estado: string;
	logradouro: string;
	numero: string;
	tipo: string;
	complemento: string;
}

interface IParcelas {
	parcela: string;
	descricao: string;
	dataVencimento: string;
}
