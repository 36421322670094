import { ReducerActionRotaSP } from 'store/modules/types';

import { ConsultarSiglasRequest, Types } from './types';

export function consultarSiglasRequest(
	token: string,
	payload: ConsultarSiglasRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_SIGLAS_REQUEST,
		token,
		payload,
	};
}
export function consultarSiglasSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_SIGLAS_SUCCESS,
		payload,
	};
}
export function consultarSiglasFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_SIGLAS_FAILURE,
		payload: null,
	};
}
export function cadastrarSiglaClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_SIGLAS_CLEAR,
		payload: null,
	};
}
