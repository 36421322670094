export enum Types {
	CONSULTA_CANAIS = '@canais/CONSULTA_CANAIS',
	CONSULTA_CANAIS_SUCCESS = '@canais/CONSULTA_CANAIS_SUCCESS',
	CONSULTA_CANAIS_FAILURE = '@canais/CONSULTA_CANAIS_FAILURE',
}

export interface consultaCanais {
	status: number;
	data: ICanais[] | null;
}

interface ICanais {
	codigo: number;
	descricao: string;
}
