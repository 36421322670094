import produce from 'immer';
import { Types, ParametrosState } from './types';
import { ReducerAction } from '../../../types';

export const INITIAL_STATE: ParametrosState = {
	parametrosData: [],
	redirect: false,
	situation: '',
	totalRegistrosConsulta: 0,
};

export default function parametros(
	state = INITIAL_STATE,
	action: ReducerAction,
): ParametrosState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_PARAMETROS: {
				break;
			}
			case Types.CONSULTA_PARAMETROS_SUCCESS: {
				draft.parametrosData = action.payload.data;
				draft.totalRegistrosConsulta =
					action.payload.headers['total-num-registros'];
				break;
			}
			case Types.CONSULTA_PARAMETROS_FAILURE: {
				draft.parametrosData = [];
				break;
			}
			case Types.CADASTRA_PARAMETROS: {
				break;
			}
			case Types.CADASTRA_PARAMETROS_SUCCESS: {
				draft.redirect = true;
				draft.situation = 'Cadastrado';
				break;
			}
			case Types.CADASTRA_PARAMETROS_FAILURE: {
				break;
			}
			case Types.ALTERA_PARAMETROS: {
				break;
			}
			case Types.ALTERA_PARAMETROS_SUCCESS: {
				draft.redirect = true;
				draft.situation = 'Alterado';
				break;
			}
			case Types.ALTERA_PARAMETROS_FAILURE: {
				break;
			}
			case Types.REMOVE_PARAMETROS: {
				break;
			}
			case Types.REMOVE_PARAMETROS_SUCCESS: {
				const updatedParametros = draft.parametrosData.filter(
					item => item.id !== action.payload,
				);
				draft.parametrosData = updatedParametros;
				draft.redirect = true;
				draft.situation = 'Removido';
				break;
			}

			case Types.REMOVE_PARAMETROS_FAILURE: {
				break;
			}

			case Types.REDIRECT_PARAMETROS_CLEAR: {
				draft.redirect = false;
				break;
			}
			default:
		}
	});
}
