export enum Types {
	ENDERECOS_REQUEST = '@utils/ENDERECOS_REQUEST',
	ENDERECOS_SUCCESS = '@utils/ENDERECOS_SUCCESS',
	ENDERECOS_FAILURE = '@utils/ENDERECOS_FAILURE',
	ENDERECOS_CLEAR = '@utils/ENDERECOS_CLEAR',
}

export interface Enderecos {
	status: number;
	data: null | EnderecosData[];
	dataFailure: null | object;
	instanceRequest: string | null;
}

export interface EnderecosData {
	cep: string;
	bairro: string;
	tipoLogradouro: string;
	endereco: string;
	complemento: string;
	tipoLogradouroAbrev: string;
	enderecoAbrev: string;
	tipoLogradouroAbrevDNE: string;
	localidade: string;
	estado: string;
	uf: string;
	numeroIBGE: number;
	logradouro: string | null;
	cdTipoCEP: number;
	tipoCEP: string;
	municipio: string;
	tipoLocalidade: string | null;
	codigoMunicipio: number;
	codigoLocalRel: number;
	latitude: string | null;
	longitude: string | null;
	codigoDne: number;
	tipoLogradouroDne: number;
	codigoBairro: number;
}

export interface EnderecosRequest {
	cep: string;
	instanceRequest: string;
}
