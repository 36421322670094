// PATHS
import {
	ROUTE_DETRAN_ALERACAO_DADOS_CADASTRAIS_CONSULTAR,
	ROUTE_DETRAN_ALERACAO_DADOS_CADASTRAIS_EMITIR,
	ROUTE_DETRAN_ALERACAO_DADOS_CADASTRAIS_RESULTADO,
	ROUTE_DETRAN_AUTORIZACAO_PREVIA,
	// ROUTE_DETRAN_BAIXAR_VEICULO_CONSULTAR,
	// ROUTE_DETRAN_BAIXAR_VEICULO_EMITIR,
	// ROUTE_DETRAN_BAIXAR_VEICULO_RESULTADO,
	ROUTE_DETRAN_CANCELAR_AUTORIZACAO_PREVIA,
	ROUTE_DETRAN_CONSULTAR_AUTORIZACAO_PREVIA,
	ROUTE_DETRAN_CONSULTAR_AUTORIZACAO_PREVIA_RESULTADO,
	// ROUTE_DETRAN_ESCOLHER_PLACA,
	// ROUTE_DETRAN_ESCOLHER_PLACA_CONFIRMACAO,
	// ROUTE_DETRAN_ESCOLHER_PLACA_RESULTADO,
	// ROUTE_DETRAN_IMPRIMIR_CRLVE,
	ROUTE_DETRAN_LICENCIAMENTO_VEICULO,
	ROUTE_DETRAN_LICENCIAMENTO_VEICULO_IMPRESSAO,
	ROUTE_DETRAN_LICENCIAMENTO_VEICULO_RESULTADO,
	ROUTE_DETRAN_PRIMEIRO_EMPLACAMENTO,
	ROUTE_DETRAN_PRIMEIRO_EMPLACAMENTO_CONFIRMACAO,
	ROUTE_DETRAN_PRIMEIRO_EMPLACAMENTO_RESULTADO,
	ROUTE_DETRAN_PRIMEIRO_EMPLACAMENTO_IMPRESSAO,
	ROUTE_DETRAN_REGISTRAR_AUTORIZACAO_PREVIA,
	ROUTE_DETRAN_RESULTADO_AUTORIZACAO_PREVIA,
	// ROUTE_DETRAN_SEGUNDA_VIA_CRV_CONSULTAR,
	// ROUTE_DETRAN_SEGUNDA_VIA_CRV_EMITIR,
	// ROUTE_DETRAN_SEGUNDA_VIA_CRV_RESULTADO,
	// ROUTE_DETRAN_SEGUNDA_VIA_PLACA_CONSULTAR,
	// ROUTE_DETRAN_SEGUNDA_VIA_PLACA_EMITIR,
	// ROUTE_DETRAN_SEGUNDA_VIA_PLACA_RESULTADO,
	ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_CONFIRMACAO,
	ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_CONSULTAR,
	ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_ESTADO_CONFIRMACAO,
	ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_ESTADO_CONSULTAR,
	ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_ESTADO_RESULTADO,
	ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_RESULTADO,
	ROUTE_DETRAN_TRANSFERENCIA_PROPRIETARIO_CONFIRMACAO,
	ROUTE_DETRAN_TRANSFERENCIA_PROPRIETARIO_CONSULTAR,
	ROUTE_DETRAN_TRANSFERENCIA_PROPRIETARIO_RESULTADO,
	ROUTE_DETRAN_TROCA_VOLUNTARIA_PLACA_CONSULTAR,
	ROUTE_DETRAN_TROCA_VOLUNTARIA_PLACA_EMITIR,
	ROUTE_DETRAN_TROCA_VOLUNTARIA_PLACA_RESULTADO,
	ROUTE_DETRAN_REENVIAR_DOCUMENTOS_CONSULTAR,
	ROUTE_DETRAN_REENVIAR_DOCUMENTOS_RESULTADO,
	ROUTE_DETRAN_DESBLOQUEIO_VEICULOS_BLOQUEIO_DIVERSOS_BLOQUEIO_JUDICIAL_CONSULTAR,
	ROUTE_DETRAN_DESBLOQUEIO_VEICULOS_BLOQUEIO_DIVERSOS_BLOQUEIO_JUDICIAL_DESBLOQUEAR,
	ROUTE_DETRAN_LICENCIAMENTO_OCORRENCIA_OBITO_CONSULTAR,
	ROUTE_DETRAN_LICENCIAMENTO_OCORRENCIA_OBITO_DESBLOQUEAR,
	ROUTE_DETRAN_DESBLOQUEIO_VEICULOS_BLOQUEIO_DIVERSOS_CATEGORIA_ALUGUEL_CONSULTAR,
	ROUTE_DETRAN_DESBLOQUEIO_VEICULOS_BLOQUEIO_DIVERSOS_CATEGORIA_ALUGUEL_DESBLOQUEAR,
	ROUTE_DETRAN_DESBLOQUEIO_VEICULOS_BLOQUEIO_DIVERSOS_CATEGORIA_ALUGUEL_CONFIRMACAO,
	ROUTE_DETRAN_DESBLOQUEIO_VEICULOS_BLOQUEIO_DIVERSOS_BLOQUEIO_JUDICIAL_CONFIRMACAO,
	ROUTE_DETRAN_LICENCIAMENTO_OCORRENCIA_OBITO_CONFIRMACAO,
} from '../routes/paths';
// // ALTERAÇÃO DE DADOS CADASTRAIS
import AlteracaoDadosCadastrais from './AlteracaoDadosCadastrais/Consultar';
import EmitirAlteracao from './AlteracaoDadosCadastrais/Emitir';
import AlteracaoDadosCadastraisImpressao from './AlteracaoDadosCadastrais/Resultado/Alteracao';
// // // BAIXAR VEÍCULO
// import ConsultarVeiculo from './BaixarVeiculo/Consultar';
// import EmitirBaixaVeiculo from './BaixarVeiculo/Emitir';
// import ResultadoBaixa from './BaixarVeiculo/Resultado';
import CancelarAutorizacaoPrevia from './CancelarAutorizacaoPrevia';
import ConsultarAutorizacaoPrevia from './ConsultarAutorizacaoPrevia';
import ConsultarAutorizacaoPreviaResultado from './ConsultarAutorizacaoPrevia/Detalhes';
// // // ESCOLHER PLACA
// import EscolherPlaca from './EscolherPlaca';
// import EscolherPlacaConfirmacao from './EscolherPlaca/Confirmar';
// import EscolherPlacaResultado from './EscolherPlaca/ResultadoConsulta';
// // // IMPRESSÃO CRLVE
// import ImpressaoCrlve from './ImpressaoCrlv';
// // LICENCIAMENTO VEÍCULO
import LicenciamentoVeiculo from './LicenciamentoVeiculo/Consultar';
import LicenciamentoVeiculoResultadoImpressao from './LicenciamentoVeiculo/ImpressaoResultado';
import LicenciamentoVeiculoResultado from './LicenciamentoVeiculo/Resultado';
// // PRIMEIRA EMPLACAMENTO
import PrimeiroEmplacamento from './PrimeiroEmplacamento';
import PrimeiroEmplacamentoConfirmacao from './PrimeiroEmplacamento/Confirmacao';
import PrimeiroEmplacamentoImpressao from './PrimeiroEmplacamento/Impressao';
import PrimeiroEmplacamentoResultado from './PrimeiroEmplacamento/Resultado';
// // REENVIAR DOCUMENTOS
import ConsultarReenviarDocumentos from './ReenviarDocumentos/Consultar';
import ResultadoReenviarDocumentos from './ReenviarDocumentos/Resultado';

// // REGISTRAR AUTORIZACAO PREVIA
import RegistrarAutorizacaoPrevia from './RegistrarAutorizacaoPrevia';
import AutorizacaoPrevia from './RegistrarAutorizacaoPrevia/AutorizacaoPrevia';
import ResultadoAutorizacao from './RegistrarAutorizacaoPrevia/ResultadoAutorizacao';
// // // SEGUNDA VIA CRV
// import SegundaViaCrv from './SegundaViaCrv/Consultar';
// import EmitirSegundaViaCrv from './SegundaViaCrv/Emitir';
// import ResultadoSegundaViaCrv from './SegundaViaCrv/Resultado';
// // // SEGUNDA VIA PLACA
// import ConsultarSegundaViaPlaca from './SegundaViaPlaca/Consultar';
// import EmitirSegundaViaPlaca from './SegundaViaPlaca/Emitir';
// import ResultadoSegundaViaPlaca from './SegundaViaPlaca/Resultado';
// // TRANSFERÊNCIA MUNICÍPIO
import TransferenciaMunicipio from './TransferenciaMunicipio/Consultar';
import EmitirTransferenciaMunicipio from './TransferenciaMunicipio/Emitir';
import ResultadoTransferenciaMunicipio from './TransferenciaMunicipio/Resultado';
// // TRANSFERÊNCIA MUNICÍPIO ESTADO
import TransferenciaMunicipioEstado from './TransferenciaMunicipioEstado/Consultar';
import EmitirTransferenciaMunicipioEstado from './TransferenciaMunicipioEstado/Emitir';
import ResultadoTransferenciaMunicipioEstado from './TransferenciaMunicipioEstado/Resultado';
// // TRANSFERÊNCIA PROPRIETARIO
import TransferenciaPropietario from './TransferenciaPropietario/Consultar';
import EmitirTransferenciaPropietario from './TransferenciaPropietario/Emitir';
import ResultadoTransferenciaPropietario from './TransferenciaPropietario/Resultado';
// // TROCA VOLUNTÁRIA
import ConsultarTrocaVoluntariaPlaca from './TrocaVoluntariaPlaca/Consultar';
import EmitirTrocaVoluntariaPlaca from './TrocaVoluntariaPlaca/Emitir';
import ResultadoTrocaVoluntariaPlaca from './TrocaVoluntariaPlaca/Resultado';
// DESBLOQUEIO VEICULOS
import { BloqueioDiversosCategoriaAluguelConsultar } from './DesbloqueioVeiculos/BloqueioDiversosCategoriaAluguel/Consultar';
import { BloqueioDiversosCategoriaAluguelDesbloquear } from './DesbloqueioVeiculos/BloqueioDiversosCategoriaAluguel/Desbloquear';
import { BloqueioDiversosBloqueioJudicialDesbloquear } from './DesbloqueioVeiculos/BloqueioDiversosBloqueioJudicial/Desbloquear';
import { BloqueioDiversosBloqueioJudicialConsultar } from './DesbloqueioVeiculos/BloqueioDiversosBloqueioJudicial/Consultar';
import { LicenciamentoOcorrenciaObitoConsultar } from './LicenciamentoOcorrenciaObito/Consultar';
import { LicenciamentoOcorrenciaObitoDesbloquear } from './LicenciamentoOcorrenciaObito/Desbloquear';
import BloqueioDiversosCategoriaAluguelConfirmacao from './DesbloqueioVeiculos/BloqueioDiversosCategoriaAluguel/Confirmacao';
import BloqueioDiversosBloqueioJudicialConfirmacao from './DesbloqueioVeiculos/BloqueioDiversosBloqueioJudicial/Confirmacao';
import LicenciamentoOcorrenciaObitoConfirmacao from './LicenciamentoOcorrenciaObito/Confirmacao';

export const detranCrvRoute = {
	name: 'DETRAN CRV',
	route: '/detran-crv',
};

export default [
	// {
	// 	path: ROUTE_DETRAN_ESCOLHER_PLACA,
	// 	breadcrumb: [
	// 		detranCrvRoute,
	// 		{
	// 			name: 'Escolha da Placa',
	// 		},
	// 	],
	// 	Component: EscolherPlaca,
	// 	isAuthenticated: true,
	// 	exact: true,
	// },
	// {
	// 	path: ROUTE_DETRAN_ESCOLHER_PLACA_RESULTADO,
	// 	breadcrumb: [
	// 		detranCrvRoute,
	// 		{
	// 			name: 'Escolha da Placa',
	// 		},
	// 	],
	// 	Component: EscolherPlacaResultado,
	// 	isAuthenticated: true,
	// 	exact: true,
	// },
	// {
	// 	path: ROUTE_DETRAN_ESCOLHER_PLACA_CONFIRMACAO,
	// 	breadcrumb: [
	// 		detranCrvRoute,
	// 		{
	// 			name: 'Escolher da Placa',
	// 		},
	// 	],
	// 	Component: EscolherPlacaConfirmacao,
	// 	isAuthenticated: true,
	// 	exact: true,
	// },
	{
		path: ROUTE_DETRAN_PRIMEIRO_EMPLACAMENTO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Emissão do Primeiro Emplacamento',
			},
		],
		Component: PrimeiroEmplacamento,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_PRIMEIRO_EMPLACAMENTO_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Emissão do Primeiro Emplacamento',
			},
		],
		Component: PrimeiroEmplacamentoResultado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_PRIMEIRO_EMPLACAMENTO_CONFIRMACAO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Emissão do Primeiro Emplacamento',
			},
		],
		Component: PrimeiroEmplacamentoConfirmacao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_PRIMEIRO_EMPLACAMENTO_IMPRESSAO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Emissão do Primeiro Emplacamento',
			},
		],
		Component: PrimeiroEmplacamentoImpressao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_REGISTRAR_AUTORIZACAO_PREVIA,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Registrar Autorização Prévia',
			},
		],
		Component: RegistrarAutorizacaoPrevia,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_AUTORIZACAO_PREVIA,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Autorização Prévia',
			},
		],
		Component: AutorizacaoPrevia,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_RESULTADO_AUTORIZACAO_PREVIA,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Registrar Autorização Prévia',
			},
		],
		Component: ResultadoAutorizacao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_LICENCIAMENTO_VEICULO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Licenciamento de Veículo',
			},
		],
		Component: LicenciamentoVeiculo,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_LICENCIAMENTO_VEICULO_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Solicitar Licenciamento de Veículo',
			},
		],
		Component: LicenciamentoVeiculoResultado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_LICENCIAMENTO_VEICULO_IMPRESSAO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Solicitar Licenciamento de Veículo',
			},
		],
		Component: LicenciamentoVeiculoResultadoImpressao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_TRANSFERENCIA_PROPRIETARIO_CONSULTAR,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Transferência de Proprietário',
			},
		],
		Component: TransferenciaPropietario,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_TRANSFERENCIA_PROPRIETARIO_CONFIRMACAO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Transferência de Proprietário',
			},
		],
		Component: EmitirTransferenciaPropietario,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_TRANSFERENCIA_PROPRIETARIO_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Transferência de Proprietário',
			},
		],
		Component: ResultadoTransferenciaPropietario,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_ESTADO_CONSULTAR,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Transferência Município / Estado',
			},
		],
		Component: TransferenciaMunicipioEstado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_ESTADO_CONFIRMACAO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Transferência Município / Estado',
			},
		],
		Component: EmitirTransferenciaMunicipioEstado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_ESTADO_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Transferência Município / Estado',
			},
		],
		Component: ResultadoTransferenciaMunicipioEstado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_CONSULTAR,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Transferência Município',
			},
		],
		Component: TransferenciaMunicipio,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_CONFIRMACAO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Transferência Município',
			},
		],
		Component: EmitirTransferenciaMunicipio,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Transferência Município',
			},
		],
		Component: ResultadoTransferenciaMunicipio,
		isAuthenticated: true,
		exact: true,
	},
	// {
	// 	path: ROUTE_DETRAN_SEGUNDA_VIA_CRV_CONSULTAR,
	// 	breadcrumb: [
	// 		detranCrvRoute,
	// 		{
	// 			name: 'Segunda Via de CRV',
	// 		},
	// 	],
	// 	Component: SegundaViaCrv,
	// 	isAuthenticated: true,
	// 	exact: true,
	// },
	// {
	// 	path: ROUTE_DETRAN_SEGUNDA_VIA_CRV_EMITIR,
	// 	breadcrumb: [
	// 		detranCrvRoute,
	// 		{
	// 			name: 'Segunda Via de CRV',
	// 		},
	// 	],
	// 	Component: EmitirSegundaViaCrv,
	// 	isAuthenticated: true,
	// 	exact: true,
	// },
	// {
	// 	path: ROUTE_DETRAN_SEGUNDA_VIA_CRV_RESULTADO,
	// 	breadcrumb: [
	// 		detranCrvRoute,
	// 		{
	// 			name: 'Segunda Via de CRV',
	// 		},
	// 	],
	// 	Component: ResultadoSegundaViaCrv,
	// 	isAuthenticated: true,
	// 	exact: true,
	// },
	// {
	// 	path: ROUTE_DETRAN_BAIXAR_VEICULO_CONSULTAR,
	// 	breadcrumb: [
	// 		detranCrvRoute,
	// 		{
	// 			name: 'Baixar Veículo',
	// 		},
	// 	],
	// 	Component: ConsultarVeiculo,
	// 	isAuthenticated: true,
	// 	exact: true,
	// },
	// {
	// 	path: ROUTE_DETRAN_BAIXAR_VEICULO_EMITIR,
	// 	breadcrumb: [
	// 		detranCrvRoute,
	// 		{
	// 			name: 'Baixar Veículo',
	// 		},
	// 	],
	// 	Component: EmitirBaixaVeiculo,
	// 	isAuthenticated: true,
	// 	exact: true,
	// },
	// {
	// 	path: ROUTE_DETRAN_BAIXAR_VEICULO_RESULTADO,
	// 	breadcrumb: [
	// 		detranCrvRoute,
	// 		{
	// 			name: 'Baixar Veículo',
	// 		},
	// 	],
	// 	Component: ResultadoBaixa,
	// 	isAuthenticated: true,
	// 	exact: true,
	// },
	{
		path: ROUTE_DETRAN_ALERACAO_DADOS_CADASTRAIS_CONSULTAR,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Alteração de Dados Cadastrais',
			},
		],
		Component: AlteracaoDadosCadastrais,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_ALERACAO_DADOS_CADASTRAIS_EMITIR,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Alteração de Dados Cadastrais',
			},
		],
		Component: EmitirAlteracao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_ALERACAO_DADOS_CADASTRAIS_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Alteração de Dados Cadastrais',
			},
		],
		Component: AlteracaoDadosCadastraisImpressao,
		isAuthenticated: true,
		exact: true,
	},
	// {
	// 	path: ROUTE_DETRAN_SEGUNDA_VIA_PLACA_CONSULTAR,
	// 	breadcrumb: [
	// 		detranCrvRoute,
	// 		{
	// 			name: 'Segunda Via de Placa',
	// 		},
	// 	],
	// 	Component: ConsultarSegundaViaPlaca,
	// 	isAuthenticated: true,
	// 	exact: true,
	// },
	// {
	// 	path: ROUTE_DETRAN_SEGUNDA_VIA_PLACA_EMITIR,
	// 	breadcrumb: [
	// 		detranCrvRoute,
	// 		{
	// 			name: 'Segunda Via de Placa',
	// 		},
	// 	],
	// 	Component: EmitirSegundaViaPlaca,
	// 	isAuthenticated: true,
	// 	exact: true,
	// },
	// {
	// 	path: ROUTE_DETRAN_SEGUNDA_VIA_PLACA_RESULTADO,
	// 	breadcrumb: [
	// 		detranCrvRoute,
	// 		{
	// 			name: 'Segunda Via de Placa',
	// 		},
	// 	],
	// 	Component: ResultadoSegundaViaPlaca,
	// 	isAuthenticated: true,
	// 	exact: true,
	// },
	{
		path: ROUTE_DETRAN_CANCELAR_AUTORIZACAO_PREVIA,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Cancelar Autorização Prévia',
			},
		],
		Component: CancelarAutorizacaoPrevia,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CONSULTAR_AUTORIZACAO_PREVIA,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consultar Autorização Prévia',
			},
		],
		Component: ConsultarAutorizacaoPrevia,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_CONSULTAR_AUTORIZACAO_PREVIA_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Consultar Autorização Prévia',
			},
		],
		Component: ConsultarAutorizacaoPreviaResultado,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_TROCA_VOLUNTARIA_PLACA_CONSULTAR,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Troca Voluntária de Placa',
			},
		],
		Component: ConsultarTrocaVoluntariaPlaca,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_TROCA_VOLUNTARIA_PLACA_EMITIR,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Troca Voluntária de Placa',
			},
			{
				name: 'Emitir',
			},
		],
		Component: EmitirTrocaVoluntariaPlaca,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_TROCA_VOLUNTARIA_PLACA_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Troca Voluntária de Placa',
			},
			{
				name: 'Resultado',
			},
		],
		Component: ResultadoTrocaVoluntariaPlaca,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_REENVIAR_DOCUMENTOS_CONSULTAR,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Reenvio de Documentos',
			},
		],
		Component: ConsultarReenviarDocumentos,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_REENVIAR_DOCUMENTOS_RESULTADO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Reenvio de Documentos',
			},
			{
				name: 'Resultado',
			},
		],
		Component: ResultadoReenviarDocumentos,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_DESBLOQUEIO_VEICULOS_BLOQUEIO_DIVERSOS_CATEGORIA_ALUGUEL_CONSULTAR,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Desbloqueio de veículo - bloqueio diversos - categoria aluguel',
			},
		],
		Component: BloqueioDiversosCategoriaAluguelConsultar,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_DESBLOQUEIO_VEICULOS_BLOQUEIO_DIVERSOS_CATEGORIA_ALUGUEL_DESBLOQUEAR,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Desbloqueio de veículo - bloqueio diversos - categoria aluguel',
			},
		],
		Component: BloqueioDiversosCategoriaAluguelDesbloquear,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_DESBLOQUEIO_VEICULOS_BLOQUEIO_DIVERSOS_CATEGORIA_ALUGUEL_CONFIRMACAO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Desbloqueio de veículo - bloqueio diversos - categoria aluguel',
			},
		],
		Component: BloqueioDiversosCategoriaAluguelConfirmacao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_DESBLOQUEIO_VEICULOS_BLOQUEIO_DIVERSOS_BLOQUEIO_JUDICIAL_CONSULTAR,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Desbloqueio de veículo - bloqueio judicial',
			},
		],
		Component: BloqueioDiversosBloqueioJudicialConsultar,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_DESBLOQUEIO_VEICULOS_BLOQUEIO_DIVERSOS_BLOQUEIO_JUDICIAL_DESBLOQUEAR,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Desbloqueio de veículo - bloqueio judicial',
			},
		],
		Component: BloqueioDiversosBloqueioJudicialDesbloquear,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_DESBLOQUEIO_VEICULOS_BLOQUEIO_DIVERSOS_BLOQUEIO_JUDICIAL_CONFIRMACAO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Desbloqueio de veículo - bloqueio judicial',
			},
		],
		Component: BloqueioDiversosBloqueioJudicialConfirmacao,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_LICENCIAMENTO_OCORRENCIA_OBITO_CONSULTAR,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Licenciamento de veículo - ocorrência óbito',
			},
		],
		Component: LicenciamentoOcorrenciaObitoConsultar,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_LICENCIAMENTO_OCORRENCIA_OBITO_DESBLOQUEAR,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Licenciamento de veículo - ocorrência óbito',
			},
		],
		Component: LicenciamentoOcorrenciaObitoDesbloquear,
		isAuthenticated: true,
		exact: true,
	},
	{
		path: ROUTE_DETRAN_LICENCIAMENTO_OCORRENCIA_OBITO_CONFIRMACAO,
		breadcrumb: [
			detranCrvRoute,
			{
				name: 'Licenciamento de veículo - ocorrência óbito',
			},
		],
		Component: LicenciamentoOcorrenciaObitoConfirmacao,
		isAuthenticated: true,
		exact: true,
	},
	// {
	// 	path: ROUTE_DETRAN_IMPRIMIR_CRLVE,
	// 	breadcrumb: [
	// 		detranCrvRoute,
	// 		{
	// 			name: 'Imprimir CRLV-e de Licenciamento de Veículos',
	// 		},
	// 	],
	// 	Component: ImpressaoCrlve,
	// 	isAuthenticated: true,
	// 	exact: true,
	// },
];
