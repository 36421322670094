import { ParametrosState } from './parametros/types';
import { ServicosState } from './servicos/types';
import { PrefeiturasServicosMunicipaisState } from './prefeituras/types';
import { ServicosPrefeiturasState } from './servicosPrefeituras/types';
import { Canais } from './canais/types';
import { PrefeiturasServicosCanais } from './prefeiturasServicosCanais/types';

export enum Types {
	SERVICOS_MUNICIPAIS_CLEAR = '@servicosMunicipais/SERVICOS_MUNICIPAIS_CLEAR ',
}

export interface ServicosMunicipaisState {
	parametros: ParametrosState;
	servicos: ServicosState;
	prefeituras: PrefeiturasServicosMunicipaisState;
	servicosPrefeituras: ServicosPrefeiturasState;
	canais: Canais;
	prefeiturasServicosCanais: PrefeiturasServicosCanais;
}
