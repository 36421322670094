import { ReducerActionRotaSP } from 'store/modules/types';

import { AtualizarNaturalidadeRequest, Types } from './types';

export function atualizarNaturalidadeRequest(
	token: string,
	payload: AtualizarNaturalidadeRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_NATURALIDADE,
		token,
		payload: {
			id: Number(payload.id),
			digito: Number(payload.digito),
			descricao: payload.descricao,
			sigla: payload.sigla,
		},
	};
}
export function atualizarNaturalidadeSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_NATURALIDADE_SUCCESS,
		payload,
	};
}
export function atualizarNaturalidadeFailure(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_NATURALIDADE_FAILURE,
		payload: null,
	};
}
export function atualizarNaturalidadeClear(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_NATURALIDADE_CLEAR,
		payload: null,
	};
}
