import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, IRequestAlteraPrefeiturasServicosCanais } from './types';

export function alteraPrefeiturasServicosCanaisRequest(
	token: string,
	data: IRequestAlteraPrefeiturasServicosCanais,
): ReducerActionRotaSP {
	return {
		type: Types.ALTERA_PREFEITURA_SERVICOS_CANAIS,
		token,
		payload: data,
	};
}
export function alteraPrefeiturasServicosCanaisSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.ALTERA_PREFEITURA_SERVICOS_CANAIS_SUCCESS,
		payload,
	};
}
export function alteraPrefeiturasServicosCanaisFailure(
	payload: [],
): ReducerActionRotaSP {
	return {
		type: Types.ALTERA_PREFEITURA_SERVICOS_CANAIS_FAILURE,
		payload,
	};
}

export function alteraPrefeiturasServicosCanaisClear(): ReducerActionRotaSP {
	return {
		type: Types.ALTERA_PREFEITURA_SERVICOS_CANAIS_CLEAR,
		payload: null,
	};
}
