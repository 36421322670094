import { ReducerAction, ReducerActionRotaSP } from 'store/modules/types';

import { IPrefeiturasServicosValidacoesRequest, Types } from './types';

export interface IPrefeiturasServicosValidacoes {
	idPrefeituraServico: number;
	body?: IPrefeiturasServicosValidacoesRequest;
}

export function cadastraPrefeiturasServicosValidacoes(
	token: string,
	data: IPrefeiturasServicosValidacoes,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES,
		token,
		payload: data,
	};
}
export function cadastraPrefeiturasServicosValidacoesSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES_SUCCESS,
		payload,
	};
}
export function cadastraPrefeiturasServicosValidacoesFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CADASTRA_PREFEITURAS_SERVICOS_VALIDACOES_FAILURE,
		payload,
	};
}

export function removePrefeiturasServicos(): ReducerAction {
	return {
		type: Types.REMOVE_PREFEITURAS_SERVICOS,
		payload: null,
	};
}
