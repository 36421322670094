import { ReducerActionRotaSP } from 'store/modules/types';

import { CadastrarNaturalidadeRequest, Types } from './types';

export function cadastrarNaturalidadeRequest(
	token: string,
	data: CadastrarNaturalidadeRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_NATURALIDADE,
		token,
		payload: {
			id: Number(data.id),
			digito: Number(data.digito),
			descricao: data.descricao,
			sigla: data.sigla,
		},
	};
}
export function cadastrarNaturalidadeSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_NATURALIDADE_SUCCESS,
		payload,
	};
}
export function cadastrarNaturalidadeFailure(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_NATURALIDADE_FAILURE,
		payload: null,
	};
}
export function cadastrarNaturalidadeClear(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_NATURALIDADE_CLEAR,
		payload: null,
	};
}
