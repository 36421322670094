export enum Types {
	CADASTRAR_NATURALIDADE = '@SGU/CADASTRAR_NATURALIDADE',
	CADASTRAR_NATURALIDADE_SUCCESS = '@SGU/CADASTRAR_NATURALIDADE_SUCCESS',
	CADASTRAR_NATURALIDADE_FAILURE = '@SGU/CADASTRAR_NATURALIDADE_FAILURE',
	CADASTRAR_NATURALIDADE_CLEAR = '@SGU/CADASTRAR_NATURALIDADE_CLEAR',
}

export interface CadastrarNaturalidade {
	status: number;
	data: NaturalidadeData | null;
}

interface NaturalidadeData {
	status: string;
	statusMensagem: string;
}

export interface CadastrarNaturalidadeRequest {
	id?: string | number;
	digito: string | number;
	descricao: string;
	sigla?: string;
}
export interface CadastrarNaturalidadeRequestNew {
	id?: string | number;
	digito: string | number;
	descricao: string;
	sigla: string;
}
