import * as Yup from 'yup';

// STORE
import { ILogin } from 'store/modules/api/sgu/loginUnico/types';
import { ConsultarCpfRequest } from 'store/modules/api/rfb/consultarCpf/types';

// UTILS
import { onlyLetters, isCpfValid } from 'utils/yupValidations';
import { limparMascara, unformatDate } from 'utils/genericFunctions';
import { SalvarAtendimento } from 'store/modules/api/sgu/atendimento/salvar/types';

export interface IFormConsultarCpf {
	cpf: string;
	cpfEmpty: boolean;
	nome: string;
	nomeMae: string;
	dataNascimento: string;
}

export const initialValues: IFormConsultarCpf = {
	cpf: '',
	cpfEmpty: false,
	nome: '',
	nomeMae: '',
	dataNascimento: '',
};

export const schema = Yup.object<IFormConsultarCpf>().shape({
	cpf: Yup.string()
		.when('cpfEmpty', {
			is: cpfEmpty => cpfEmpty === false,
			then: Yup.string().required('Campo Obrigatório: CPF.'),
		})
		.test({
			name: 'isValidCPF',
			exclusive: true,
			message: 'CPF informado é inválido.',
			test: value => (value !== '' ? isCpfValid(value) : true),
		}),
	nome: Yup.string().when('cpfEmpty', {
		is: cpfEmpty => cpfEmpty === true,
		then: Yup.string()
			.required('Campo Obrigatório: Nome.')
			.max(60, 'Campo Nome: Tamanho máximo de 60 caracteres.')
			.test({
				name: 'onlyLetters',
				exclusive: true,
				message: 'Campo Nome: Apenas letras são permitidas.',
				test: value => onlyLetters(value),
			}),
	}),
	nomeMae: Yup.string().when('cpfEmpty', {
		is: cpfEmpty => cpfEmpty === true,
		then: Yup.string()
			.required('Campo Obrigatório: Filiação.')
			.max(60, 'Campo Nome: Tamanho máximo de 60 caracteres.')
			.test({
				name: 'onlyLetters',
				exclusive: true,
				message: 'Campo Filiação: Apenas letras são permitidas.',
				test: value => onlyLetters(value),
			}),
	}),
	dataNascimento: Yup.string().when('cpfEmpty', {
		is: cpfEmpty => cpfEmpty === true,
		then: Yup.string().required('Campo Obrigatório: Data de Nascimento.'),
	}),
});

export function treatValues(
	values: IFormConsultarCpf,
	login: ILogin,
	salvarAtendimento: SalvarAtendimento,
): ConsultarCpfRequest {
	const { cpf, cpfEmpty, nome, nomeMae, dataNascimento } = values;

	return {
		cpf: !cpfEmpty ? limparMascara(cpf) : '',
		cpfUsuario: login.user.cpf,
		dataNascimento: '',
		dataNascimentoInicial: cpfEmpty ? '' : unformatDate(dataNascimento),
		dataNascimentoFinal: cpfEmpty ? '' : unformatDate(dataNascimento),
		nome: cpfEmpty ? nome.toUpperCase() : '',
		nomeMae: cpfEmpty ? nomeMae.toUpperCase() : '',
		evento: {
			id_atendimento:
				salvarAtendimento.data?.idAtendimento ??
				'00000000-0000-0000-0000-000000000000',
			id_cidadao:
				salvarAtendimento.data?.idCidadao ??
				'00000000-0000-0000-0000-000000000000',
			cpf: login.user.cpf,
			identificacao: cpf ? limparMascara(cpf) : '',
			ip: login.user.ip,
			canal: {
				id: login.user.idCanal,
				desc: login.user.descCanal,
				localidade: {
					desc: login.user.nomePosto,
					id: Number(login.user.posto),
				},
			},
		},
	};
}
