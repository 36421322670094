import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_CDHU_MUTUARIOS_API } from 'services/rotasp/path';

// UTILS
import { treatObjNullValues } from 'utils/genericFunctions';

// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	Types,
	GerarBoletoAgrupadoData,
	GerarBoletoAgrupadoRequest,
} from './types';

// ACTIONS
import {
	gerarBoletoAgrupadoSuccess,
	gerarBoletoAgrupadoFailure,
} from './actions';

function* gerarBoletoAgrupadoRequest(request: ReducerAction) {
	const {
		token,
		payload,
	}: { token?: string; payload: GerarBoletoAgrupadoRequest } = request;

	const formattedPayload = {
		servicoEnum: payload.servicoEnum,
		ocorrenciaId: payload.ocorrenciaId,
		numeroContrato: payload.numeroContrato,
		dataInicio: payload.dataInicio,
		dataFim: payload.dataFim,
		dataLimite: payload.dataLimite,
	};

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_CDHU_MUTUARIOS_API,
		`boletos`,
		formattedPayload,
	);

	if (response.status === 201 || response.status === 200) {
		const data = treatObjNullValues(response.data);

		yield put(gerarBoletoAgrupadoSuccess(data as GerarBoletoAgrupadoData));
	} else {
		yield put(gerarBoletoAgrupadoFailure());
	}
}

export default all([
	takeLatest(Types.GERAR_BOLETO_AGRUPADO_REQUEST, gerarBoletoAgrupadoRequest),
]);
