export enum Types {
	CONSULTAR_RELACAO_USUARIO_REQUEST = '@relatorios/CONSULTAR_RELACAO_USUARIO_REQUEST',
	CONSULTAR_RELACAO_USUARIO_FAILURE = '@relatorios/CONSULTAR_RELACAO_USUARIO_FAILURE',
	CONSULTAR_RELACAO_USUARIO_SUCCESS = '@relatorios/CONSULTAR_RELACAO_USUARIO_SUCCESS',
	CONSULTAR_RELACAO_USUARIO_CLEAR = '@relatorios/CONSULTAR_RELACAO_USUARIO_CLEAR',
}

export interface IConsultarRelacaoUsuarioState {
	status: number;
	data: IConsultarRelacaoUsuario[] | null;
}

export interface IConsultarRelacaoUsuario {
	id: number;
	nome: string;
	descricaoPerfil: string;
	rg: string;
	digitoRg: string;
	cpf: string;
	usuariosMainframe: string;
	localidadeAssociada: {
		id: number;
		nome: string;
		orgao: {
			id: number;
			descricao: string;
			flHabilitado: boolean;
		};
	};
}

export interface IConsultarRelacaoUsuarioRequest {
	page?: number;
	size?: number;
	idOrgao: number | string | null;
	idLocalidade: number | null;
	codigoMainFrame?: string;
}
