import { combineReducers } from 'redux';
import { ReducerAction } from 'store/modules/types';

import parametros from './parametros/reducer';
import servicos from './servicos/reducer';
import prefeituras from './prefeituras/reducer';
import servicosPrefeituras from './servicosPrefeituras/reducer';

// CANAIS
import canais from './canais/combineReducers';

// PREFEITURAS SERVIÇOS CANAIS
import prefeiturasServicosCanais from './prefeiturasServicosCanais/combineReducers';
import { ServicosMunicipaisState, Types } from './types';

const allReducers = combineReducers({
	parametros,
	servicos,
	prefeituras,
	servicosPrefeituras,
	canais,
	prefeiturasServicosCanais,
});

const combineReducer = (
	state: ServicosMunicipaisState,
	action: ReducerAction,
) => {
	if (action.type === Types.SERVICOS_MUNICIPAIS_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
