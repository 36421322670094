import { ReducerActionRotaSP } from 'store/modules/types';

import { Types } from './types';

export interface IPrefeiturasServicosCanais {
	idPrefeituraServico: number;
	body?: any;
	situation?: string;
}

export function consultaPrefeiturasServicosCanaisRequest(
	token: string,
	data: IPrefeiturasServicosCanais,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTA_PREFEITURA_SERVICOS_CANAIS,
		token,
		payload: data,
	};
}
export function consultaPrefeiturasServicosCanaisSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTA_PREFEITURA_SERVICOS_CANAIS_SUCCESS,
		payload,
	};
}
export function consultaPrefeiturasServicosCanaisFailure(
	payload: [],
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTA_PREFEITURA_SERVICOS_CANAIS_FAILURE,
		payload,
	};
}
