import { ReducerActionRotaSP } from 'store/modules/types';

import { ExcluirNaturalidadeRequest, Types } from './types';

export function excluirNaturalidadeRequest(
	token: string,
	payload: ExcluirNaturalidadeRequest,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_NATURALIDADE,
		token,
		payload: {
			id: Number(payload.id),
			digito: Number(payload.digito),
			descricao: payload.descricao,
		},
	};
}
export function excluirNaturalidadeSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_NATURALIDADE_SUCCESS,
		payload,
	};
}
export function excluirNaturalidadeFailure(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_NATURALIDADE_FAILURE,
		payload: null,
	};
}
export function excluirNaturalidadeClear(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_NATURALIDADE_CLEAR,
		payload: null,
	};
}
