import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { cdhuClear } from 'store/modules/api/cdhu/actions';
import {
	obterFichaFinanceiraRequest,
	obterFichaFinanceiraClear,
} from 'store/modules/api/cdhu/obterFichaFinanceira/actions';
import {
	obterTokenCdhuRequest,
	obterTokenCdhuClear,
} from 'store/modules/api/cdhu/gerarTokenCdhu/actions';

import { ROUTE_CDHU_SOLICITANTE_PESQUISAR } from 'pages/cdhu/routes/paths';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import { Button, ColCenter } from './style';

const DadosOcorrencia: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	// eslint-disable-next-line
	const [showInfoButton, setShowInfoButton] = useState<boolean>(false);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const {
		consultarMutuario,
		consultarSolicitante,
		gerarOcorrencia,
		obterFichaFinanceira,
		gerarTokenCdhu,
	} = useSelector((state: ApplicationState) => state.api.cdhu);

	useEffect(() => {
		if (gerarOcorrencia.form) {
			const { servicoEnum } = gerarOcorrencia.form;

			if (servicoEnum === 'INFO_INSCRICOES_PROGRAMAS_HABITACIONAIS') {
				setShowInfoButton(true);
			}

			if (servicoEnum === 'INFO_SITUACAO_FINANCEIRA') {
				setShowInfoButton(true);
			}

			if (servicoEnum === 'INFO_SALDO_DEVEDOR') {
				setShowInfoButton(true);
			}
		}
	}, [gerarOcorrencia.form]);

	const openbase64 = (base64: string) => {
		const pdfWindow = window.open('');
		pdfWindow!.document!.write(
			`<iframe width='100%' height='100%' src='data:text/html;base64,${base64}'></iframe>`,
		);
	};

	const handleServicoEnum = useCallback((servico: string): string => {
		switch (servico) {
			case 'ACORDO':
				return 'Solicitação de Acordo - Presencial';

			case 'BOLETO_AGRUPADO':
				return 'Solicitação de Boleto Agrupado - Presencial';

			case 'INFO_TRANSFERENCIA_TITULARIDADE':
				return 'Transferência de Titularidade - Informação';

			case 'INFO_SEGURO_HABITACIONAL':
				return 'Seguro Habitacional - Informação';

			case 'INFO_INSCRICOES_PROGRAMAS_HABITACIONAIS':
				return 'Inscrições e Programas Habitacionais - Informação';

			case 'INFO_SITUACAO_FINANCEIRA':
				return 'Situação Financeira - Informação';

			case 'INFO_SALDO_DEVEDOR':
				return 'Saldo Devedor - Informação';

			default:
				return '';
		}
	}, []);

	const handleClickProcedimento = useCallback(() => {
		if (gerarOcorrencia.data) {
			const { urlProcedimento } = gerarOcorrencia.data;

			window.open(urlProcedimento, '_blank');
		}
	}, [gerarOcorrencia]);

	const handleClickFichaFinanceira = useCallback(() => {
		dispatch(obterFichaFinanceiraClear());
		dispatch(obterTokenCdhuClear());
		dispatch(obterTokenCdhuRequest());
	}, [dispatch]);

	useEffect(() => {
		if (gerarTokenCdhu.status === 200) {
			if (gerarOcorrencia.form?.numeroContrato) {
				const payloadObterFichaFinanceira = gerarOcorrencia.form.numeroContrato;
				dispatch(
					obterFichaFinanceiraRequest({
						numeroContrato: payloadObterFichaFinanceira,
						baseUrl: gerarTokenCdhu.data?.baseUrl || '',
						token: gerarTokenCdhu.data?.token || '',
					}),
				);
			}
		}
	}, [gerarTokenCdhu, gerarOcorrencia, dispatch]);

	useEffect(() => {
		if (obterFichaFinanceira.status === 200 && obterFichaFinanceira.data) {
			const { base64Api } = obterFichaFinanceira.data;
			openbase64(base64Api);
			dispatch(obterFichaFinanceiraClear());
			dispatch(obterTokenCdhuClear());
		}
	}, [gerarTokenCdhu, obterFichaFinanceira, dispatch]);

	// const handleClickSituacaoGeral = useCallback(async () => {
	// 	if (gerarOcorrencia.data) {
	// 		const { situacaoGeraldaPrestacao } = gerarOcorrencia.data;

	// 		openbase64(situacaoGeraldaPrestacao);
	// 	}
	// }, [gerarOcorrencia]);

	// const handleClickDebitos = useCallback(() => {
	// 	if (gerarOcorrencia.data) {
	// 		const { debitos } = gerarOcorrencia.data;

	// 		openbase64(debitos);
	// 	}
	// }, [gerarOcorrencia]);

	// const handleClickComponentePrestacao = useCallback(() => {
	// 	if (gerarOcorrencia.data) {
	// 		const { componentePrestacao } = gerarOcorrencia.data;

	// 		openbase64(componentePrestacao);
	// 	}
	// }, [gerarOcorrencia]);

	const handleClickImprimir = useCallback(() => {
		window.print();
	}, []);

	const handleButtonVoltar = useCallback(() => {
		dispatch(cdhuClear());
		history.push(ROUTE_CDHU_SOLICITANTE_PESQUISAR);
	}, [dispatch, history]);

	const handleClickEncerrar = useCallback(() => {
		dispatch(cdhuClear());
		history.push(ROUTE_CDHU_SOLICITANTE_PESQUISAR);
	}, [dispatch, history]);

	return (
		<>
			{consultarMutuario.form &&
				consultarMutuario.data &&
				gerarOcorrencia.form &&
				gerarOcorrencia.data && (
					<>
						<Row>
							<Col span={24}>
								<Section size="lg" title="Dados da ocorrência" titleSize="sm">
									<Row align="bottom">
										<Col span={24}>
											{consultarSolicitante.data && (
												<Row gutter={[0, 5]}>
													<Col span={6}>Solicitante:</Col>

													<Col>
														<b>
															{consultarSolicitante.data.nome.toUpperCase()}
														</b>
													</Col>
												</Row>
											)}

											<Row gutter={[0, 5]}>
												<Col span={6}>Mutuário:</Col>

												<Col>
													<b>{consultarMutuario.data.nome.toUpperCase()}</b>
												</Col>
											</Row>

											<Row gutter={[0, 5]}>
												<Col span={6}>Número da conta:</Col>

												<Col>
													<b>{consultarMutuario.form.numContrato}</b>
												</Col>
											</Row>

											<Row gutter={[0, 5]}>
												<Col span={6}>Protocolo:</Col>

												<Col>
													<b>{gerarOcorrencia.data.titulo}</b>
												</Col>
											</Row>

											<Row gutter={[0, 5]}>
												<Col span={6}>Serviço:</Col>

												<Col>
													<b>
														{handleServicoEnum(
															gerarOcorrencia.form.servicoEnum,
														).toUpperCase()}
													</b>
												</Col>
											</Row>

											<Row>
												<Col span={6}>Localidade:</Col>

												<Col>
													<b>{login.user.nomePosto.toUpperCase()}</b>
												</Col>
											</Row>
										</Col>
									</Row>

									<Row gutter={[0, 20]} className="no-print">
										<Col span={24} />
									</Row>

									<Row
										wrap
										justify="center"
										gutter={[0, 10]}
										className="no-print"
									>
										{showInfoButton && (
											<>
												{/* <ColCenter xs={18} sm={8} md={8}>
													<Button onClick={handleClickComponentePrestacao}>
														Componente da Prestação
													</Button>
												</ColCenter> */}
												{/* <ColCenter xs={18} sm={8} md={8}>
													<Button onClick={handleClickSituacaoGeral}>
													Situação Geral da Prestação
													</Button>
												</ColCenter> */}
											</>
										)}
									</Row>
									<Row justify="center" gutter={[0, 10]} className="no-print">
										{gerarOcorrencia.form &&
											gerarOcorrencia.form.servicoEnum !==
												'BOLETO_AGRUPADO' && (
												<ColCenter xs={18} sm={8} md={8}>
													<Button onClick={handleClickFichaFinanceira}>
														Ficha Financeira
													</Button>
												</ColCenter>
											)}
										{/* {showInfoButton && (
											<ColCenter xs={18} sm={8} md={8}>
												<Button onClick={handleClickDebitos}>Débitos</Button>
											</ColCenter>
										)} */}

										<ColCenter xs={18} sm={8} md={8}>
											<Button onClick={handleClickProcedimento}>
												Procedimento
											</Button>
										</ColCenter>

										<ColCenter xs={18} sm={8} md={8}>
											<Button onClick={handleClickImprimir}>
												Imprimir Ocorrência
											</Button>
										</ColCenter>
									</Row>
								</Section>
							</Col>
						</Row>

						<Row justify="space-between" className="no-print">
							<Col>
								<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
							</Col>

							<Col style={{ marginTop: '13px' }}>
								<ButtonImage src="encerrar" onClick={handleClickEncerrar} />
							</Col>
						</Row>
					</>
				)}
		</>
	);
};

export default DadosOcorrencia;
