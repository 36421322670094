export enum Types {
	CADASTRAR_SIGLA_REQUEST = '@SGU/CADASTRAR_SIGLA_REQUEST',
	CADASTRAR_SIGLA_SUCCESS = '@SGU/CADASTRAR_SIGLA_SUCCESS',
	CADASTRAR_SIGLA_FAILURE = '@SGU/CADASTRAR_SIGLA_FAILURE',
	CADASTRAR_SIGLA_CLEAR = '@SGU/CADASTRAR_SIGLA_CLEAR',
}

export interface CadastrarSigla {
	status: number;
	data: null | SiglasData;
}

interface SiglasData {
	status: string;
	statusMensagem: string;
}

export interface CadastrarSiglaRequest {
	id?: number | string;
	descricao?: string; 
    idOrgao?: number | string;
}
