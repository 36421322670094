import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_PREFEITURAS_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import { consultaCanaisSuccess, consultaCanaisFailure } from './actions';

function* consultaCanais(request: ReducerActionRotaSP) {
	const { token }: { token?: string } = request;
	const allowedStatus = [200, 204];

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_PREFEITURAS_API,
		'canais',
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		allowedStatus,
	);

	if (response.status === 200) {
		yield put(consultaCanaisSuccess(response));
	} else if (response.status === 204) {
		yield put(consultaCanaisFailure([]));
	} else if (response.error) {
		yield put(consultaCanaisFailure(response.message));
	}
}

export default all([takeLatest(Types.CONSULTA_CANAIS, consultaCanais)]);
