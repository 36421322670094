import React, { useCallback } from 'react';

import { Col, Row } from 'antd';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';

// TYPES
import {
	GerarBoletoAgrupadoData,
	GerarBoletoAgrupadoRequest,
} from 'store/modules/api/cdhu/gerarBoletoAgrupado/types';

interface PropsGerarBoletoAGrupado {
	gerarBoletoAgrupadoData: GerarBoletoAgrupadoData | null;
	gerarBoletoAgrupadoForm: GerarBoletoAgrupadoRequest | null;
}

const GerarBoletoAgrupado: React.FC<PropsGerarBoletoAGrupado> = ({
	gerarBoletoAgrupadoData,
	gerarBoletoAgrupadoForm,
}) => {
	const handleClick = useCallback(() => {
		if (gerarBoletoAgrupadoData) {
			const { urlBoletoGerado } = gerarBoletoAgrupadoData;

			window.open(urlBoletoGerado, '_blank')?.focus();
		}
	}, [gerarBoletoAgrupadoData]);

	return (
		<>
			{gerarBoletoAgrupadoData && gerarBoletoAgrupadoForm && (
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Section
							size="lg"
							title="Serviços / Boleto agrupado"
							titleSize="sm"
						>
							<Row align="middle">
								<Col span={7} offset={1}>
									<Row>
										<Col span={11}>
											<b>Total de prestações:</b>
										</Col>

										<Col>{gerarBoletoAgrupadoForm.qtdPrestacoes || ''}</Col>
									</Row>
								</Col>

								<Col span={7} offset={1}>
									<Row>
										<Col span={17}>
											<b>Data de vencimento:</b>
										</Col>

										<Col>{gerarBoletoAgrupadoData.dataVencimento}</Col>
									</Row>
								</Col>

								<Col span={7} offset={1}>
									<Row justify="center">
										<Col>
											<ButtonImage src="gerar-boleto" onClick={handleClick} />
										</Col>
									</Row>
								</Col>
							</Row>
						</Section>
					</Col>
				</Row>
			)}
		</>
	);
};

export default GerarBoletoAgrupado;
