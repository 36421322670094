import { ReducerActionRotaSP } from 'store/modules/types';

import { ConsultarNaturalidadesRequest, Types } from './types';

export function consultarNaturalidadesRequest(
	token: string,
	payload: ConsultarNaturalidadesRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_NATURALIDADES_REQUEST,
		token,
		payload: {
			id: Number(payload.id) || null,
			digito: Number(payload.digito) || null,
			descricao: payload.descricao,
			sigla: payload.sigla,
		},
	};
}
export function consultarNaturalidadesSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_NATURALIDADES_SUCCESS,
		payload,
	};
}
export function consultarNaturalidadesFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_NATURALIDADES_FAILURE,
		payload: null,
	};
}
export function consultarNaturalidadesClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_NATURALIDADES_CLEAR,
		payload: null,
	};
}
