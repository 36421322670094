import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import {
	getApi as getApiRotaSP,
	postApi as postApiRotaSP,
	putApi as putApiRotaSP,
	deleteApi as deleteApiRotaSP,
} from 'services/rotasp/api';

// TYPES
import { ReducerAction, ReducerActionRotaSP } from 'store/modules/types';
import { PATH_PREFEITURAS_API } from 'services/rotasp/path';
import { Types } from './types';

// ACTIONS
import {
	consultaPrefeiturasSuccess,
	consultaPrefeiturasFailure,
	cadastraPrefeiturasSuccess,
	cadastraPrefeiturasFailure,
	alteraPrefeiturasSuccess,
	alteraPrefeiturasFailure,
	removePrefeiturasSuccess,
	removePrefeiturasFailure,
	consultaPrefeiturasServicosSuccess,
	consultaPrefeiturasServicosFailure,
	cadastraPrefeiturasServicosSuccess,
	cadastraPrefeiturasServicosFailure,
	alteraPrefeiturasServicos as actionAlteraPrefeituraServicos,
	alteraPrefeiturasServicosSuccess,
	alteraPrefeiturasServicosFailure,
	removePrefeiturasServicos as actionRemovePrefeiturasServicos,
	removePrefeiturasServicosSuccess,
	removePrefeiturasServicosFailure,
	consultaPrefeiturasServicosParametrosSuccess,
	consultaPrefeiturasServicosParametrosFailure,
	cadastraPrefeiturasServicosParametrosSuccess,
	cadastraPrefeiturasServicosParametrosFailure,
	alteraPrefeiturasServicosParametrosSuccess,
	alteraPrefeiturasServicosParametrosFailure,
	removePrefeiturasServicosParametrosSuccess,
	removePrefeiturasServicosParametrosFailure,
	IAlteraPrefeiturasRequest,
	ICadastraPrefeiturasRequest,
	ICadastraPrefeiturasServicosRequest,
	IConsultaPrefeiturasServicosRequest,
	IAlteraPrefeiturasServicosRequest,
	IConsultaPrefeiturasServicosParametrosRequest,
	ICadastraPrefeiturasServicosParametrosRequest,
	IAlteraPrefeiturasServicosParametrosRequest,
	IRemovePrefeiturasServicosParametrosRequest,
	IConsultaPrefeiturasRequest,
} from './actions';

function* consultaPrefeituras(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: IConsultaPrefeiturasRequest } = request;

	const allowedStatus = [200, 204];

	const response = yield call(
		getApiRotaSP,
		token || '',
		PATH_PREFEITURAS_API,
		'prefeituras',
		payload,
		undefined,
		undefined,
		undefined,
		undefined,
		allowedStatus,
	);

	if (response.status === 200) {
		yield put(consultaPrefeiturasSuccess(response));
	} else if (response.status === 204) {
		yield put(consultaPrefeiturasFailure([]));
	} else if (response.error) {
		yield put(consultaPrefeiturasFailure(response.message));
	}
}

function* cadastraPrefeituras(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: ICadastraPrefeiturasRequest } = request;

	const response = yield call(
		postApiRotaSP,
		token || '',
		PATH_PREFEITURAS_API,
		'prefeituras',
		payload,
	);

	if (response.status === 201) {
		yield put(cadastraPrefeiturasSuccess(payload));
	} else if (response.error) {
		yield put(cadastraPrefeiturasFailure(response.message));
	}
}

function* alteraPrefeituras(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: IAlteraPrefeiturasRequest } = request;

	payload.codigo = Number(payload.codigo);

	const response = yield call(
		putApiRotaSP,
		token || '',
		PATH_PREFEITURAS_API,
		`prefeituras/${payload.codigo}`,
		payload.body,
	);

	if (response.status === 200) {
		yield put(alteraPrefeiturasSuccess(payload));
	} else if (response.error) {
		yield put(alteraPrefeiturasFailure(response.message));
	}
}

function* removePrefeituras(request: ReducerActionRotaSP) {
	const { token, payload }: { token?: string; payload: number } = request;

	const response = yield call(
		deleteApiRotaSP,
		token || '',
		PATH_PREFEITURAS_API,
		`prefeituras/${payload}`,
	);

	if (response.status === 200) {
		yield put(removePrefeiturasSuccess(payload));
	} else if (response.error) {
		yield put(removePrefeiturasFailure(response.message));
	}
}

function* consultaPrefeiturasServicos(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: IConsultaPrefeiturasServicosRequest } = request;
	const allowedStatus = [200, 204];

	const response = yield call(
		getApiRotaSP,
		token || '',
		PATH_PREFEITURAS_API,
		'prefeituras/servicos',
		payload,
		undefined,
		undefined,
		undefined,
		undefined,
		allowedStatus,
	);

	if (response.status === 200) {
		yield put(consultaPrefeiturasServicosSuccess(response));
	} else if (response.error) {
		yield put(consultaPrefeiturasServicosFailure(response.message));
	} else if (response.status === 204) {
		yield put(consultaPrefeiturasServicosFailure([]));
	}
}

function* cadastraPrefeiturasServicos(request: ReducerActionRotaSP) {
	const {
		token = '',
		payload,
	}: { token?: string; payload: ICadastraPrefeiturasServicosRequest } = request;

	// Cadastro de 1 ou mais serviços vinculados
	let proximoServico = { idProximoServico: null };
	// eslint-disable-next-line no-restricted-syntax
	for (const item of payload.body.reverse()) {
		const response = yield call(
			postApiRotaSP,
			token,
			PATH_PREFEITURAS_API,
			`prefeituras/${payload.codigoPrefeitura}/servicos/${payload.idServico}`,
			{ ...item, ...proximoServico },
		);

		if (response.status === 201) {
			proximoServico = {
				idProximoServico: response.data.id,
			};
			yield put(
				cadastraPrefeiturasServicosSuccess({
					...response.data,
					codigo_servico: payload.idServico,
					codigo_prefeitura: payload.codigoPrefeitura,
					contabiliza: item.contabiliza,
					endpoint: item.endpoint,
					primeiro_servico: item.primeiro_servico,
				}),
			);
		} else if (response.error) {
			yield put(cadastraPrefeiturasServicosFailure(response.message));
		}
	}

	// Vinculação de cadastros antigos com o novo serviço
	if (payload.valuesToUpdate && payload.valuesToUpdate.length > 0) {
		// eslint-disable-next-line no-restricted-syntax
		for (const item of payload.valuesToUpdate) {
			if (!item.proximo_servico) {
				yield put(
					actionAlteraPrefeituraServicos(token, {
						body: { ...item.body, ...proximoServico },
						idPrefeituraServico: item.codigo,
					}),
				);
			} else {
				yield put(
					actionAlteraPrefeituraServicos(token, {
						body: { ...item.body },
						idPrefeituraServico: item.codigo,
					}),
				);
			}
		}
	}
}

function* alteraPrefeiturasServicos(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: IAlteraPrefeiturasServicosRequest } = request;
	const treatedToken = token || '';

	const response = yield call(
		putApiRotaSP,
		treatedToken,
		PATH_PREFEITURAS_API,
		`prefeituras/servicos/${payload.idPrefeituraServico}`,
		payload.body,
	);

	if (response.status === 200) {
		if (payload.delete) {
			yield put(
				actionRemovePrefeiturasServicos(treatedToken, [payload.delete]),
			);
		}
		yield put(alteraPrefeiturasServicosSuccess(response));
	} else if (response.error) {
		yield put(alteraPrefeiturasServicosFailure(response.message));
	}
}

function* removePrefeiturasServicos(request: ReducerActionRotaSP) {
	const { token, payload }: { token?: string; payload: any } = request;

	// eslint-disable-next-line no-restricted-syntax
	for (const item of payload) {
		const response = yield call(
			deleteApiRotaSP,
			token || '',
			PATH_PREFEITURAS_API,
			`prefeituras/servicos/${item}`,
		);

		if (response.status === 200) {
			yield put(removePrefeiturasServicosSuccess(item));
		} else if (response.error) {
			yield put(removePrefeiturasServicosFailure(response.message));
		}
	}
}

function* consultaPrefeiturasServicosParametros(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: {
		token?: string;
		payload: IConsultaPrefeiturasServicosParametrosRequest;
	} = request;

	const allowedStatus = [200, 204];

	const response = yield call(
		getApiRotaSP,
		token || '',
		PATH_PREFEITURAS_API,
		`prefeituras/servicos/${payload.idPrefeituraServico}/parametros`,
		{},
		undefined,
		undefined,
		undefined,
		undefined,
		allowedStatus,
	);

	if (response.status === 200) {
		yield put(consultaPrefeiturasServicosParametrosSuccess(response));
	} else if (response.status === 204) {
		yield put(consultaPrefeiturasServicosParametrosFailure([]));
	} else if (response.error) {
		yield put(consultaPrefeiturasServicosParametrosFailure(response.message));
	}
}

function* cadastraPrefeiturasServicosParametros(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: {
		token?: string;
		payload: ICadastraPrefeiturasServicosParametrosRequest;
	} = request;

	const response = yield call(
		postApiRotaSP,
		token || '',
		PATH_PREFEITURAS_API,
		`prefeituras/servicos/${payload.idPrefeituraServico}/parametros/${payload.idParametro}`,
		{ grupoObrigatorio: payload.body.grupoObrigatorio },
	);

	if (response.status === 201) {
		yield put(cadastraPrefeiturasServicosParametrosSuccess(payload.body));
	} else if (response.error) {
		yield put(cadastraPrefeiturasServicosParametrosFailure(response.message));
	}
}

function* alteraPrefeiturasServicosParametros(request: ReducerAction) {
	const {
		token,
		payload,
	}: { token?: string; payload: IAlteraPrefeiturasServicosParametrosRequest } =
		request;

	const response = yield call(
		putApiRotaSP,
		token || '',
		PATH_PREFEITURAS_API,
		`prefeituras/servicos/${payload.idPrefeituraServico}/parametros/${payload.idParametro}`,
		{ grupoObrigatorio: payload.grupoObrigatorio },
	);

	if (response.status === 200) {
		yield put(alteraPrefeiturasServicosParametrosSuccess(payload));
	} else if (response.error) {
		yield put(alteraPrefeiturasServicosParametrosFailure(response.message));
	}
}

function* removePrefeiturasServicosParametros(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: IRemovePrefeiturasServicosParametrosRequest } =
		request;

	const response = yield call(
		deleteApiRotaSP,
		token || '',
		PATH_PREFEITURAS_API,
		`prefeituras/servicos/${payload.idPrefeituraServico}/parametros/${payload.idParametro}`,
	);

	if (response.status === 200) {
		yield put(removePrefeiturasServicosParametrosSuccess(payload.idParametro));
	} else if (response.error) {
		yield put(removePrefeiturasServicosParametrosFailure(response.message));
	}
}

export default all([
	takeLatest(Types.CONSULTA_PREFEITURAS, consultaPrefeituras),
	takeLatest(Types.CADASTRA_PREFEITURAS, cadastraPrefeituras),
	takeLatest(Types.ALTERA_PREFEITURAS, alteraPrefeituras),
	takeLatest(Types.REMOVE_PREFEITURAS, removePrefeituras),
	takeLatest(Types.CONSULTA_PREFEITURAS_SERVICOS, consultaPrefeiturasServicos),
	takeLatest(Types.CADASTRA_PREFEITURAS_SERVICOS, cadastraPrefeiturasServicos),
	takeLatest(Types.ALTERA_PREFEITURAS_SERVICOS, alteraPrefeiturasServicos),
	takeLatest(Types.REMOVE_PREFEITURAS_SERVICOS, removePrefeiturasServicos),
	takeLatest(
		Types.CONSULTA_PREFEITURAS_SERVICOS_PARAMETROS,
		consultaPrefeiturasServicosParametros,
	),
	takeLatest(
		Types.CADASTRA_PREFEITURAS_SERVICOS_PARAMETROS,
		cadastraPrefeiturasServicosParametros,
	),
	takeLatest(
		Types.ALTERA_PREFEITURAS_SERVICOS_PARAMETROS,
		alteraPrefeiturasServicosParametros,
	),
	takeLatest(
		Types.REMOVE_PREFEITURAS_SERVICOS_PARAMETROS,
		removePrefeiturasServicosParametros,
	),
]);
