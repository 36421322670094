import { ReducerAction, ReducerActionRotaSP } from 'store/modules/types';

import { Types } from './types';

export interface IRequestConsultaParametros {
	page: number;
	size: number;
	descricaoParametro?: string;
}

export interface IRequestCadastraParametro {
	codigo?: number;
	descricao: string;
	nome: string;
	rotulo: string;
	tamanho: number | string;
	tipo: string;
}

export function consultaParametros(
	token: string,
	data: IRequestConsultaParametros,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTA_PARAMETROS,
		token,
		payload: data,
	};
}
export function consultaParametrosSuccess(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_PARAMETROS_SUCCESS,
		payload,
	};
}
export function consultaParametrosFailure(payload: []): ReducerAction {
	return {
		type: Types.CONSULTA_PARAMETROS_FAILURE,
		payload,
	};
}

export function cadastraParametros(
	token: string,
	data: IRequestCadastraParametro,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRA_PARAMETROS,
		token,
		payload: data,
	};
}
export function cadastraParametrosSuccess(payload: []): ReducerAction {
	return {
		type: Types.CADASTRA_PARAMETROS_SUCCESS,
		payload,
	};
}
export function cadastraParametrosFailure(payload: []): ReducerAction {
	return {
		type: Types.CADASTRA_PARAMETROS_FAILURE,
		payload,
	};
}

export function alteraParametros(
	token: string,
	data: IRequestCadastraParametro,
): ReducerActionRotaSP {
	return {
		type: Types.ALTERA_PARAMETROS,
		token,
		payload: data,
	};
}
export function alteraParametrosSuccess(payload: []): ReducerAction {
	return {
		type: Types.ALTERA_PARAMETROS_SUCCESS,
		payload,
	};
}
export function alteraParametrosFailure(payload: []): ReducerAction {
	return {
		type: Types.ALTERA_PARAMETROS_FAILURE,
		payload,
	};
}

export function removeParametros(
	token: string,
	codigo: number,
): ReducerActionRotaSP {
	return {
		type: Types.REMOVE_PARAMETROS,
		token,
		payload: codigo,
	};
}
export function removeParametrosSuccess(codigo: number): ReducerAction {
	return {
		type: Types.REMOVE_PARAMETROS_SUCCESS,
		payload: codigo,
	};
}
export function removeParametrosFailure(payload: []): ReducerAction {
	return {
		type: Types.REMOVE_PARAMETROS_FAILURE,
		payload,
	};
}

export function clearRedirect(): ReducerAction {
	return {
		type: Types.REDIRECT_PARAMETROS_CLEAR,
		payload: null,
	};
}
