import React, { useEffect, useState, useCallback } from 'react';

import { Row, Col, List, Popover } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';
import {
	ROUTE_IIRGD_PESQUISAR_RG,
	ROUTE_IIRGD_CADASTRO,
	ROUTE_IIRGD_RESULTADO_RESTRICOES,
	ROUTE_IIRGD_EDICAO,
} from 'pages/iirgd/RgFicDigital/routes/paths';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store';
import {
	clearNotifications,
	showInside,
} from 'store/modules/app/notifications/actions';
import { consultarCpfClear } from 'store/modules/api/rfb/consultarCpf/actions';
import {
	cadastroRgConfigNovo,
	cadastroRgConfigAcervo,
	cadastroRgPrimeiraVia,
	cadastroRgSegundaVia,
	cadastroRgConfigEdicao,
} from 'store/modules/api/iirgd/cadastroRg/actions';
import { consultaPesquisaCriminalRequest } from 'store/modules/api/iirgd/consultaPesquisaCriminal/actions';
import {
	consultaPesquisaCidadaoClear,
	consultaPesquisaCidadaoSelecionado,
} from 'store/modules/api/iirgd/consultaPesquisaCidadao/actions';
import {
	consultaPesquisaCirgCidadaoClear,
	consultaPesquisaCirgClear,
	consultaPesquisaCirgRequest,
	consultaPesquisaCirgSuccess,
} from 'store/modules/api/iirgd/consultaPesquisaCirg/actions';
import { ConsultaPesquisaCidadao } from 'store/modules/api/iirgd/consultaPesquisaCidadao/types';
import { recuperarAtendimentoClear } from 'store/modules/api/sgu/atendimento/recuperar/actions';
import { consultaPesquisaCivilClear } from 'store/modules/api/iirgd/consultaPesquisaCivil/actions';
import { ConsultaPesquisaCirg } from 'store/modules/api/iirgd/consultaPesquisaCirg/types';

// COMPONENTS
import Alert from 'components/Common/Notifications/Alert';
import Section from 'components/Common/Section';
import Badge from 'components/Common/Badge';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// TYPES
import { SCRIPT_POSSUI_RG_EM_SAO_PAULO } from 'store/modules/api/iirgd/scriptPreAtendimento/types';

// UTILS
import {
	formatDate,
	verificarMaioridade,
	unformatDate,
	patternFormat,
	limparMascara,
	completaZeroEsquerda,
} from 'utils/genericFunctions';
import { isUnderAge } from '../../utils/rgFicDigitalFunctions';
import ScriptPreAtendimentoModal from './ScriptPreAtendimentoModal';

const AlertContent = (
	<p>
		Para criar um RG novo ou RG acervo é necessário o preenchimento da pesquisa
		com os dados do cidadão.
	</p>
);

interface ScriptPreAtendimento {
	showModal: boolean;
	disabled: boolean;
}

const ResultadoRg: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [infoMessage, setInfoMessage] = useState<boolean>(false);
	const [isMinor, setIsMinor] = useState<boolean>(false);
	const [selecionadoParaPesquisaCivil, setSelecionadoParaPesquisaCivil] =
		useState<null | ConsultaPesquisaCidadao>(null);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const {
		consultaPesquisaCidadao,
		consultaPesquisaCirg,
		consultaPesquisaCriminal,
		cadastroRg,
		scriptPreAtendimento,
	} = useSelector((state: ApplicationState) => state.api.iirgd);

	const locale = {
		emptyText: 'Nenhum registro encontrado',
	};

	useEffect(() => {
		dispatch(consultaPesquisaCivilClear());
		dispatch(consultarCpfClear());
	}, [dispatch]);

	useEffect(() => {
		if (!consultaPesquisaCidadao.data) {
			history.push(ROUTE_IIRGD_PESQUISAR_RG);
		}
	}, [consultaPesquisaCidadao, history]);

	useEffect(() => {
		if (
			isMinor &&
			cadastroRg.config.edicao &&
			consultaPesquisaCirg.status === 200 &&
			consultaPesquisaCirg?.data
		) {
			history.push(ROUTE_IIRGD_EDICAO);
		} else if (consultaPesquisaCriminal.status === 200) {
			if (!cadastroRg.config.acervo) {
				dispatch(cadastroRgConfigEdicao());
			}
			setSelecionadoParaPesquisaCivil(null);
			history.push(ROUTE_IIRGD_RESULTADO_RESTRICOES);
		}
	}, [
		consultaPesquisaCriminal,
		dispatch,
		history,
		isMinor,
		cadastroRg,
		consultaPesquisaCirg,
	]);

	const handlePesquisarRestricoesRequest = useCallback(
		(item: ConsultaPesquisaCidadao) => {
			const cidadaoSelecionando = {
				numeroRg: item.numeroRg,
				digitoRg: item.digitoRg,
				nome: item.nome,
				nomePai: item.nomePai,
				nomeMae: item.nomeMae,
				dataNascimento: item.dataNascimento,
				codigoNaturalidade: item.numeroNaturalidade,
				municipioNaturalidade: item.descricaoNaturalidade,
				afis: item.afis,
				statusEmissao: item.statusEmissao,
			};
			if (item.lote.indexOf('2') !== -1) {
				dispatch(cadastroRgSegundaVia());
			} else {
				dispatch(cadastroRgPrimeiraVia());
			}

			dispatch(consultaPesquisaCidadaoSelecionado(cidadaoSelecionando));

			if (isUnderAge(item.dataNascimento)) {
				setIsMinor(true);
				dispatch(cadastroRgConfigEdicao());
				history.push(ROUTE_IIRGD_EDICAO);
			} else {
				setIsMinor(false);

				dispatch(
					consultaPesquisaCriminalRequest({
						numeroRg: item.numeroRg,
						nomeCompleto: item.nome,
						nomePai: item.nomePai,
						nomeMae: item.nomeMae,
						dataNascimento: item.dataNascimento,
						usuario: login.mainframe.idUsuario,
						senha: login.mainframe.senhaMainFrame,
					}),
				);
			}
		},
		[
			dispatch,
			history,
			login.mainframe.idUsuario,
			login.mainframe.senhaMainFrame,
		],
	);

	useEffect(() => {
		if (
			consultaPesquisaCirg.status === 200 &&
			consultaPesquisaCirg.data &&
			selecionadoParaPesquisaCivil?.numeroRg ===
				consultaPesquisaCirg.data?.numeroRg
		) {
			dispatch(consultaPesquisaCirgSuccess(consultaPesquisaCirg.data));
			handlePesquisarRestricoesRequest(selecionadoParaPesquisaCivil);
		}
	}, [
		consultaPesquisaCirg,
		dispatch,
		handlePesquisarRestricoesRequest,
		selecionadoParaPesquisaCivil,
	]);

	const handlePesquisarRestricoes = useCallback(
		(item: ConsultaPesquisaCidadao) => {
			if (consultaPesquisaCirg?.cidadao?.data) {
				const newCirg = consultaPesquisaCirg?.cidadao?.data.filter(
					(cirgItem: ConsultaPesquisaCirg) =>
						cirgItem.numeroRg === item.numeroRg,
				);
				if (newCirg.length) {
					dispatch(consultaPesquisaCirgSuccess(newCirg[0]));
					handlePesquisarRestricoesRequest(item);
					setSelecionadoParaPesquisaCivil(null);
				} else {
					setSelecionadoParaPesquisaCivil(item);
					const cidadaoSelecionando = {
						numeroRg: item.numeroRg,
						digitoRg: item.digitoRg,
						nome: item.nome,
						nomePai: item.nomePai,
						nomeMae: item.nomeMae,
						dataNascimento: item.dataNascimento,
						codigoNaturalidade: item.numeroNaturalidade,
						municipioNaturalidade: item.descricaoNaturalidade,
						afis: item.afis,
					};
					const usuario: string = login.mainframe.idUsuario;
					const senha: string = login.mainframe.senhaMainFrame;
					dispatch(
						consultaPesquisaCirgRequest(cidadaoSelecionando, usuario, senha),
					);
				}
			} else {
				setSelecionadoParaPesquisaCivil(item);
				const cidadaoSelecionando = {
					numeroRg: item.numeroRg,
					digitoRg: item.digitoRg,
					nome: item.nome,
					nomePai: item.nomePai,
					nomeMae: item.nomeMae,
					dataNascimento: item.dataNascimento,
					codigoNaturalidade: item.numeroNaturalidade,
					municipioNaturalidade: item.descricaoNaturalidade,
					afis: item.afis,
				};
				const usuario: string = login.mainframe.idUsuario;
				const senha: string = login.mainframe.senhaMainFrame;
				dispatch(
					consultaPesquisaCirgRequest(cidadaoSelecionando, usuario, senha),
				);
			}
		},
		[consultaPesquisaCirg, dispatch, handlePesquisarRestricoesRequest, login],
	);

	const handleRgNovo = useCallback(() => {
		if (
			consultaPesquisaCidadao.form &&
			consultaPesquisaCidadao.form.numeroRg &&
			consultaPesquisaCidadao.form.digitoRg &&
			!consultaPesquisaCidadao.form.RGempty
		) {
			setInfoMessage(true);
			window.scrollTo(0, 0);
			return;
		}

		if (
			consultaPesquisaCidadao.form &&
			verificarMaioridade(consultaPesquisaCidadao.form.dataNascimento)
		) {
			const {
				nome,
				nomePai,
				nomeMae,
				dataNascimento,
				codigoNaturalidade,
				municipioNaturalidade,
			} = consultaPesquisaCidadao.form;

			dispatch(
				consultaPesquisaCidadaoSelecionado({
					numeroRg: '',
					digitoRg: '',
					nome,
					nomePai,
					nomeMae,
					dataNascimento: unformatDate(dataNascimento),
					codigoNaturalidade,
					municipioNaturalidade,
				}),
			);

			dispatch(
				consultaPesquisaCriminalRequest({
					numeroRg: '',
					nomeCompleto: nome,
					nomePai,
					nomeMae,
					dataNascimento: unformatDate(
						consultaPesquisaCidadao.form.dataNascimento,
					),
					usuario: login.mainframe.idUsuario,
					senha: login.mainframe.senhaMainFrame,
				}),
			);

			return;
		}

		dispatch(cadastroRgConfigNovo());
		dispatch(cadastroRgPrimeiraVia());
		dispatch(consultarCpfClear());
		history.push(ROUTE_IIRGD_CADASTRO);
		setSelecionadoParaPesquisaCivil(null);
	}, [
		consultaPesquisaCidadao,
		setInfoMessage,
		dispatch,
		history,
		login.mainframe,
	]);

	const handleRgAcervo = useCallback(() => {
		if (
			consultaPesquisaCidadao.form &&
			consultaPesquisaCidadao.form.numeroRg &&
			consultaPesquisaCidadao.form.digitoRg &&
			!consultaPesquisaCidadao.form.RGempty
		) {
			setInfoMessage(true);
			window.scrollTo(0, 0);
			return;
		}
		dispatch(cadastroRgConfigAcervo());
		dispatch(cadastroRgSegundaVia());
		dispatch(consultarCpfClear());
		if (
			consultaPesquisaCidadao.form &&
			verificarMaioridade(consultaPesquisaCidadao.form.dataNascimento)
		) {
			const {
				numeroRg,
				nome,
				nomePai,
				nomeMae,
				dataNascimento,
				codigoNaturalidade,
				municipioNaturalidade,
			} = consultaPesquisaCidadao.form;

			dispatch(
				consultaPesquisaCidadaoSelecionado({
					numeroRg:
						`${consultaPesquisaCidadao.form.numeroRg}` ||
						`${scriptPreAtendimento.numeroRg}`,
					digitoRg: `${consultaPesquisaCidadao.form.digitoRg}`,
					nome,
					nomePai,
					nomeMae,
					dataNascimento: unformatDate(dataNascimento),
					codigoNaturalidade,
					municipioNaturalidade,
				}),
			);

			dispatch(
				consultaPesquisaCriminalRequest({
					numeroRg: `${scriptPreAtendimento.numeroRg}` || numeroRg,
					nomeCompleto: nome,
					nomePai,
					nomeMae,
					dataNascimento: unformatDate(
						consultaPesquisaCidadao.form.dataNascimento,
					),
					usuario: login.mainframe.idUsuario,
					senha: login.mainframe.senhaMainFrame,
				}),
			);

			return;
		}

		if (consultaPesquisaCirg.status === 200) {
			dispatch(consultaPesquisaCirgClear());
		}
		// dispatch(cadastroRgConfigAcervo());
		// dispatch(cadastroRgSegundaVia());
		// dispatch(consultarCpfClear());
		history.push(ROUTE_IIRGD_CADASTRO);
		setSelecionadoParaPesquisaCivil(null);
	}, [
		consultaPesquisaCidadao.form,
		setInfoMessage,
		dispatch,
		history,
		consultaPesquisaCirg,
		login,
		scriptPreAtendimento,
	]);

	const [preScriptRequired, setPreScriptRequired] =
		useState<ScriptPreAtendimento>({ disabled: true, showModal: false });

	const [isSegundaVia, setIsSegundaVia] = useState<boolean>(false);
	useEffect(() => {
		if (
			consultaPesquisaCirg.data &&
			consultaPesquisaCirg.cidadao &&
			consultaPesquisaCirg.cidadao.data &&
			consultaPesquisaCirg.cidadao.data?.filter(
				(item: ConsultaPesquisaCirg) =>
					item.numeroRg === consultaPesquisaCirg.data?.numeroRg,
			)
		) {
			const selecionado: ConsultaPesquisaCirg[] =
				consultaPesquisaCirg.cidadao.data.filter(
					(item: ConsultaPesquisaCirg) =>
						item.numeroRg === consultaPesquisaCirg.data?.numeroRg,
				);
			if (selecionado.length) {
				setIsSegundaVia(selecionado[0].lote.indexOf('2') !== -1);
			}
		}
	}, [consultaPesquisaCirg.cidadao, consultaPesquisaCirg.data]);

	useEffect(() => {
		if (consultaPesquisaCidadao.form?.numeroRg || isSegundaVia) {
			setPreScriptRequired(prev => {
				return { ...prev, disabled: false };
			});
		}
	}, [consultaPesquisaCidadao, isSegundaVia]);

	useEffect(() => {
		if (scriptPreAtendimento.preAtendimentoValido) {
			setPreScriptRequired(prev => {
				return { ...prev, disabled: false };
			});
		}
	}, [scriptPreAtendimento.preAtendimentoValido]);

	useEffect(() => {
		showInside(preScriptRequired.showModal);
	}, [dispatch, preScriptRequired.showModal]);

	const getMensagem = (item: ConsultaPesquisaCidadao) => {
		if (item.mensagem) {
			return item.mensagem;
		}
		if (item.status === 'E') {
			return 'RG ESCOLAR';
		}
		if (item.status) {
			return item.status;
		}
		return '';
	};

	const btnRGNovo = (
		<ButtonImage
			src="btn-rge-cin"
			onClick={handleRgNovo}
			disabled={
				consultaPesquisaCidadao.form?.apresentouCertidao === false ||
				preScriptRequired.disabled ||
				(limparMascara(scriptPreAtendimento.numeroRg) !== '' &&
					scriptPreAtendimento.ondeMorava === 'São Paulo')
			}
		/>
	);

	const btnRGAcervo = (
		<ButtonImage
			src="btn-rgecin-acervo"
			onClick={handleRgAcervo}
			disabled={
				// consultaPesquisaCidadao.form?.numeroRg !== '' ||
				consultaPesquisaCidadao.form?.apresentouCertidao === false ||
				preScriptRequired.disabled ||
				scriptPreAtendimento.teveRg === false ||
				scriptPreAtendimento.ondeMorava === 'Outro Estado'
				// consultaPesquisaCidadao.status !== 400
			}
		/>
	);

	const btnPreScript = (
		<ButtonImage
			src="btn-script-preatendimento"
			disabled={
				consultaPesquisaCidadao.form?.apresentouCertidao === false ||
				scriptPreAtendimento.preAtendimentoValido
			}
			onClick={() =>
				setPreScriptRequired(prev => {
					return { ...prev, showModal: true };
				})
			}
		/>
	);

	const getRg = () => {
		if (
			consultaPesquisaCidadao.form &&
			consultaPesquisaCidadao.form.numeroRg &&
			consultaPesquisaCidadao.form.digitoRg
		) {
			return `${consultaPesquisaCidadao.form.numeroRg}-${consultaPesquisaCidadao.form.digitoRg}`;
		}
		if (
			scriptPreAtendimento?.numeroRg &&
			scriptPreAtendimento?.rgOuAcervo &&
			scriptPreAtendimento?.ondeMorava === SCRIPT_POSSUI_RG_EM_SAO_PAULO
		) {
			return scriptPreAtendimento.numeroRg;
		}
		if (consultaPesquisaCirg.data) {
			if (!consultaPesquisaCirg.data?.numeroRg) {
				return '__.___.___-_';
			}
			if (
				consultaPesquisaCirg.data?.numeroRg &&
				limparMascara(consultaPesquisaCirg.data?.numeroRg).length === 10
			) {
				return patternFormat(
					consultaPesquisaCirg.data.numeroRg,
					'##.###.###-##',
				);
			}
			return patternFormat(consultaPesquisaCirg.data.numeroRg, '##.###.###-#');
		}
		return '__.___.___-_';
	};

	return (
		<>
			<Row>
				<Col span={24}>
					{infoMessage && (
						<Row gutter={[0, 20]}>
							<Col span={24}>
								<Alert
									type="info"
									message={AlertContent}
									onClose={() => setInfoMessage(false)}
								/>
							</Col>
						</Row>
					)}

					<Section title="Resultado da Pesquisa">
						<Section bgColor="cinza-claro">
							<Row gutter={[0, 10]}>
								<Col>
									<h5>Termos pesquisados:</h5>
								</Col>
							</Row>

							<Row gutter={[0, 3]}>
								<Col span={3}>
									<p>
										<strong>RGE/CIN:</strong>
									</p>
								</Col>

								<Col span={13}>
									<p>{getRg()}</p>
								</Col>
							</Row>

							<Row gutter={[0, 3]}>
								<Col span={3}>
									<p>
										<strong>Nome:</strong>
									</p>
								</Col>

								<Col span={13}>
									<>
										{consultaPesquisaCidadao.form &&
										consultaPesquisaCidadao.form.nome ? (
											<p>{consultaPesquisaCidadao.form.nome.toUpperCase()}</p>
										) : (
											consultaPesquisaCirg.cidadao?.data &&
											consultaPesquisaCirg.cidadao?.data.length &&
											consultaPesquisaCirg.cidadao.data[0].nome && (
												<p>{consultaPesquisaCirg.cidadao.data[0].nome}</p>
											)
										)}
									</>
								</Col>
							</Row>

							<Row gutter={[0, 3]}>
								<Col span={3}>
									<p>
										<strong>Filiação 1:</strong>
									</p>
								</Col>

								<Col span={13}>
									{consultaPesquisaCidadao.form &&
									consultaPesquisaCidadao.form.nomePai ? (
										<p>{consultaPesquisaCidadao.form.nomePai.toUpperCase()}</p>
									) : (
										consultaPesquisaCirg.cidadao?.data?.length &&
										consultaPesquisaCirg.cidadao.data[0].nomePai && (
											<p>{consultaPesquisaCirg.cidadao.data[0].nomePai}</p>
										)
									)}
								</Col>

								<Col span={4}>
									<p>
										<strong>Data de Nascimento:</strong>
									</p>
								</Col>

								<Col span={3}>
									<p>
										{/* eslint-disable-next-line no-nested-ternary */}
										{consultaPesquisaCidadao.form &&
										consultaPesquisaCidadao.form.dataNascimento
											? consultaPesquisaCidadao.form.dataNascimento
											: consultaPesquisaCirg.cidadao?.data?.length &&
											  consultaPesquisaCirg.cidadao.data[0].dataNascimento
											? formatDate(
													consultaPesquisaCirg.cidadao.data[0].dataNascimento,
											  )
											: '__/__/____'}
									</p>
								</Col>
							</Row>

							<Row gutter={[0, 3]}>
								<Col span={3}>
									<p>
										<strong>Filiação 2:</strong>
									</p>
								</Col>

								<Col span={13}>
									{consultaPesquisaCidadao.form &&
									consultaPesquisaCidadao.form.nomeMae ? (
										<p>{consultaPesquisaCidadao.form.nomeMae.toUpperCase()}</p>
									) : (
										consultaPesquisaCirg.cidadao?.data?.length &&
										consultaPesquisaCirg.cidadao.data[0].nomeMae && (
											<p>{consultaPesquisaCirg.cidadao.data[0].nomeMae}</p>
										)
									)}
								</Col>
							</Row>

							<Row gutter={[0, 3]}>
								<Col span={3}>
									<p>
										<strong>Naturalidade:</strong>
									</p>
								</Col>

								<Col span={13}>
									<p>
										{/* eslint-disable-next-line no-nested-ternary */}
										{consultaPesquisaCidadao.form &&
										consultaPesquisaCidadao.form.codigoNaturalidade
											? `${consultaPesquisaCidadao.form.codigoNaturalidade} | ${consultaPesquisaCidadao.form.municipioNaturalidade}`
											: consultaPesquisaCirg.cidadao?.data?.length &&
											  consultaPesquisaCirg.cidadao.data[0].codigoNaturalidade
											? `${consultaPesquisaCirg.cidadao.data[0].codigoNaturalidade} | ${consultaPesquisaCirg.cidadao.data[0].municipioNaturalidade}`
											: '_____-_'}
									</p>
								</Col>
							</Row>
						</Section>

						<Row>
							<Col span={24}>
								<Row gutter={[0, 20]}>
									<Col span={24} />
								</Row>

								<Row gutter={[0, 10]}>
									<Col>
										<h5>Resultado da Pesquisa Civil:</h5>
									</Col>
								</Row>

								<Row>
									<Col>
										<b>Foram encontradas: </b>
										<b>{`${consultaPesquisaCidadao.ocorrencias.cirg} `}</b>
										ocorrência(s) <b>CIRG, </b>
										<b>{`${consultaPesquisaCidadao.ocorrencias.cino} `}</b>
										ocorrência(s) <b>CINO, </b>
										<b>{`${consultaPesquisaCidadao.ocorrencias.cinn} `}</b>
										ocorrência(s) <b>CINN.</b>
									</Col>
								</Row>
							</Col>
						</Row>

						<List
							itemLayout="horizontal"
							size="large"
							locale={locale}
							dataSource={consultaPesquisaCidadao.data}
							split={false}
							pagination={{
								showSizeChanger: true,
								pageSize: 10,
								pageSizeOptions: ['10', '20'],
							}}
							renderItem={item => (
								<>
									<Section key={item.numeroRg} style={{ marginTop: '10px' }}>
										<Row gutter={[0, 10]}>
											<Col span={9}>
												<h5>{item.nome}</h5>
											</Col>
											<Col span={9} offset={1}>
												{item.cirg && (
													<Badge
														bgColor="#faad14"
														color="#ffffff"
														text="CIRG"
													/>
												)}

												{item.cino && (
													<Badge
														bgColor="#003366"
														color="#ffffff"
														text="CINO"
													/>
												)}

												{item.cinn && (
													<Badge
														bgColor="#1890ff"
														color="#ffffff"
														text="CINN"
													/>
												)}
											</Col>
											<Col>
												{item.status !== 'D' && item.status !== 'F' && (
													<ButtonImage
														src="pesquisar-restricoes"
														onClick={() => handlePesquisarRestricoes(item)}
													/>
												)}
											</Col>
										</Row>

										<Row gutter={[0, 3]}>
											<Col span={9}>
												<Row>
													<Col>
														<strong>RGE/CIN: </strong>
														{limparMascara(item.numeroRg).length < 9
															? patternFormat(
																	completaZeroEsquerda(
																		limparMascara(item.numeroRg),
																		9,
																	),
																	'##.###.###-##',
															  )
															: patternFormat(item.numeroRg, '##.###.###-##')}
													</Col>
												</Row>

												<Row>
													<Col>
														<strong>Status Emissão: </strong>
														{item.statusEmissao}
													</Col>
												</Row>

												<Row>
													<Col>
														<strong>Filiação 1: </strong> {item.nomePai}
													</Col>
												</Row>

												<Row>
													<Col>
														<strong>Filiação 2: </strong> {item.nomeMae}
													</Col>
												</Row>
											</Col>

											<Col span={9} offset={1}>
												<Row>
													<Col>
														<strong>Sexo: </strong>
														{item.sexo === 'M' ? 'MASCULINO' : 'FEMININO'}
													</Col>
												</Row>

												<Row>
													<Col>
														<strong>Obs: </strong>{' '}
														{item.status === 'D' || item.status === 'F' ? (
															<span>
																<strong style={{ color: 'red' }}>
																	Comunicar Supervisor
																</strong>
															</span>
														) : (
															<strong
																style={{
																	color: 'red',
																}}
															>
																{getMensagem(item)}
															</strong>
														)}
													</Col>
												</Row>

												<Row>
													<Col>
														<strong>Data de Nascimento: </strong>
														{formatDate(item.dataNascimento)}
													</Col>
												</Row>

												<Row>
													<Col>
														<strong>Naturalidade: </strong>
														{item.descricaoNaturalidade}
													</Col>
												</Row>
											</Col>
										</Row>
									</Section>
								</>
							)}
						/>
					</Section>

					<Row gutter={[0, 20]}>
						<Col span={24} />
					</Row>

					{!consultaPesquisaCidadao.form?.numeroRg && !isSegundaVia && (
						<Row justify="center" style={{ textAlign: 'center' }}>
							<Col>
								{consultaPesquisaCidadao.form?.apresentouCertidao === false ? (
									<Popover content="Primeira via de RG e RG acervo deverá apresentar a certidão para seguir o atendimento!">
										<div>{btnPreScript}</div>
									</Popover>
								) : (
									<div>{btnPreScript}</div>
								)}
							</Col>
						</Row>
					)}

					<Row justify="center" style={{ textAlign: 'center' }}>
						<Col span={24}>
							<h1 style={{ color: 'red', marginBottom: 0 }}>
								Não encontrou o registro?
							</h1>
						</Col>
					</Row>

					<Row justify="center" style={{ textAlign: 'center' }}>
						<Col>
							{consultaPesquisaCirg.data ? (
								<>
									{consultaPesquisaCirg.data.dataNascimento &&
										verificarMaioridade(
											formatDate(consultaPesquisaCirg.data.dataNascimento),
										) && <h3>Pesquise aqui as restrições:</h3>}
								</>
							) : (
								<>
									{consultaPesquisaCidadao.form &&
										consultaPesquisaCidadao.form.dataNascimento &&
										verificarMaioridade(
											consultaPesquisaCidadao.form.dataNascimento,
										) && <h3>Pesquise aqui as restrições:</h3>}
								</>
							)}
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={24} />
					</Row>

					<Row justify="center">
						<Col>
							{consultaPesquisaCidadao.form?.apresentouCertidao === false ? (
								<Popover content="Primeira via de RG e RG acervo deverá apresentar a certidão para seguir o atendimento!">
									<div>{btnRGNovo}</div>
								</Popover>
							) : (
								btnRGNovo
							)}
						</Col>

						<Col offset={1}>
							{consultaPesquisaCidadao.form?.apresentouCertidao === false ? (
								<Popover content="Primeira via de RG e RG acervo deverá apresentar a certidão para seguir o atendimento!">
									<div>{btnRGAcervo}</div>
								</Popover>
							) : (
								btnRGAcervo
							)}
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col>
							<ButtonVoltar
								navigate={false}
								onClick={() => {
									dispatch(clearNotifications());
									dispatch(consultaPesquisaCidadaoClear());
									dispatch(consultaPesquisaCirgClear());
									dispatch(consultarCpfClear());
									dispatch(consultaPesquisaCirgCidadaoClear());
									dispatch(recuperarAtendimentoClear());
									history.push(ROUTE_IIRGD_PESQUISAR_RG);
									setSelecionadoParaPesquisaCivil(null);
								}}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
			{preScriptRequired.showModal && (
				<ScriptPreAtendimentoModal
					isVisible={preScriptRequired.showModal}
					onClose={() =>
						setPreScriptRequired(prev => {
							return { ...prev, showModal: false };
						})
					}
				/>
			)}
		</>
	);
};

export default ResultadoRg;
