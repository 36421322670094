import React, { useCallback, useState, useEffect } from 'react';
import { Select as SelectAntDesign } from 'antd';

// STYLED
// import { RefSelectProps } from 'antd/lib/select';
import { v4 } from 'uuid';
import CreateGlobalStyle from './reset';
import { Container, Title, Content, SubTitle } from './styled';

const { Option } = SelectAntDesign;

interface SelectProps {
	title?: string;
	titleSize?: 'sm' | 'md' | 'lg' | 'xl' | number | 'auto';
	titleQuestion?: boolean;
	titleAlign?: 'start' | 'end';
	subtitle?: string;
	subtitleColor?: 'red' | 'black' | string;
	name: string;
	defaultFirstOption?: boolean;
	defaultFirstOptionText?: string;
	defaultValue?: string | number;
	value?: string | number;
	options: OptionProps[];
	size?: number;
	required?: boolean;
	disabled?: boolean;
	error?: boolean;
	onChange: (v: string | number) => void;
	overflow?: boolean;
	asteriskDistance?: number;
	maxWidth?: string;
}

export interface OptionProps {
	label: string;
	value: string | number;
	flHabilitado?: string | number | boolean;
}

const Select: React.FC<SelectProps> = ({
	title,
	titleSize = 'md',
	titleQuestion = false,
	titleAlign = 'end',
	subtitle,
	subtitleColor = 'black',
	name,
	defaultValue = '',
	defaultFirstOption = true,
	defaultFirstOptionText = '- SELECIONE -',
	value,
	options,
	required = false,
	disabled = false,
	size = 100,
	error = false,
	onChange,
	overflow = false,
	asteriskDistance = -4,
	maxWidth = '240px',
	...rest
}) => {
	const [isFocused, setIsFocused] = useState(false);
	const [errors, setErrors] = useState<boolean>(!!error);

	useEffect(() => {
		if (error) {
			setErrors(true);
		} else {
			setErrors(false);
		}
	}, [error]);

	const handleInputFocus = useCallback(() => {
		setIsFocused(true);
	}, []);

	const handleInputBlur = useCallback(() => {
		setIsFocused(false);
	}, []);

	const handleChange = useCallback(
		(v: string | number) => {
			onChange(v);

			if (v) {
				setErrors(false);
			}
		},
		[onChange],
	);

	return (
		<Container>
			{title && (
				<Title
					align={titleAlign}
					size={titleSize}
					required={required}
					asteriskDistance={asteriskDistance}
				>
					<label htmlFor={name}>{`${title}${titleQuestion ? '?' : ':'}`}</label>
				</Title>
			)}
			<Content
				size={size}
				isErrored={errors}
				isFocused={isFocused}
				isDisabled={disabled}
				isOverflow={overflow}
			>
				<SelectAntDesign
					style={{ maxWidth }}
					dropdownAlign={{ offset: [0, 0] }}
					showSearch
					id={`${name}-${v4()}`}
					defaultValue={defaultValue}
					value={value}
					onFocus={handleInputFocus}
					onBlur={handleInputBlur}
					disabled={disabled}
					onChange={handleChange}
					filterOption={(input, option: any) =>
						option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
					{...rest}
				>
					{defaultFirstOption && !disabled && (
						<Option value="">{defaultFirstOptionText}</Option>
					)}
					{options &&
						options.map(option => (
							<Option key={option.value} value={option.value}>
								{option.label}
							</Option>
						))}
				</SelectAntDesign>

				{subtitle && (
					<SubTitle subtitleColor={subtitleColor}>
						<span>{subtitle}</span>
					</SubTitle>
				)}
			</Content>

			<CreateGlobalStyle />
		</Container>
	);
};

export default Select;
