export enum Types {
	EXCLUIR_SIGLA_REQUEST = '@SGU/EXCLUIR_SIGLA_REQUEST',
	EXCLUIR_SIGLA_SUCCESS = '@SGU/EXCLUIR_SIGLA_SUCCESS',
	EXCLUIR_SIGLA_FAILURE = '@SGU/EXCLUIR_SIGLA_FAILURE',
	EXCLUIR_SIGLA_CLEAR = '@SGU/EXCLUIR_SIGLA_CLEAR',
}

export interface ExcluirSigla {
	status: number;
	data: null | SiglasData;
}

interface SiglasData {
	status: string;
	statusMensagem: string;
}

export interface ExcluirSiglaRequest {
	id?: number | string;
	descricao?: string; 
    idOrgao?: number | string; 
}
