import React, { useEffect, useState } from 'react';

import { Row, Col, Alert } from 'antd';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// SECTIONS
import SituacaoContrato from '../components/SituacaoContrato';
import GerarBoletoAcordo from '../components/GerarBoletoAcordo';
import GerarBoletoAgrupado from '../components/GerarBoletoAgrupado';
import DadosOcorrencia from '../components/DadosOcorrencia';

const Sucesso: React.FC = () => {
	const [showAlert, setShowAlert] = useState<boolean>(false);

	const { gerarOcorrencia, gerarBoletoAgrupado } = useSelector(
		(state: ApplicationState) => state.api.cdhu,
	);

	useEffect(() => {
		if (gerarOcorrencia.status === 200) {
			setShowAlert(true);
		}
	}, [gerarOcorrencia]);

	return (
		<>
			{showAlert && (
				<div className="no-print">
					<Row gutter={[0, 10]}>
						<Col span={24} />
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Alert
								type="info"
								showIcon
								message="Ocorrencia criada com sucesso!"
								closable
								onClose={() => setShowAlert(false)}
							/>
						</Col>
					</Row>
				</div>
			)}

			<SituacaoContrato />

			{gerarOcorrencia.form && (
				<>
					{gerarOcorrencia.form.servicoEnum === 'ACORDO' && (
						<GerarBoletoAcordo />
					)}

					{gerarOcorrencia.form.servicoEnum === 'BOLETO_AGRUPADO' &&
						gerarBoletoAgrupado.data && (
							<GerarBoletoAgrupado
								gerarBoletoAgrupadoData={gerarBoletoAgrupado.data}
								gerarBoletoAgrupadoForm={gerarBoletoAgrupado.form}
							/>
						)}
				</>
			)}

			<DadosOcorrencia />
		</>
	);
};

export default Sucesso;
