import React, {
	ChangeEvent,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { Col, Row } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';

import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Section from 'components/Common/Section';
import SimpleTable from 'components/Common/Table';
import InputMask from 'components/Common/Form/Input/InputMask';
import TooltipInfo from 'components/Common/Tooltips/Info';
import { Title } from 'components/Common/Form/Input/styled';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ApplicationState } from 'store';
import {
	consultarAndamentoSolicitacoesRequest,
	consultarAndamentoSolicitacoesClear,
} from 'store/modules/api/ministerioPublico/consultarAndamentoSolicitacoes/actions';
import {
	consultarDetalheSolicitacaoRequest,
	consultarDetalheSolicitacaoClear,
} from 'store/modules/api/ministerioPublico/consultarDetalheSolicitacao/actions';
import { preCadastroCidadaoClear } from 'store/modules/api/sguService/preCadastroCidadao/actions';
import {
	limpaPreCadastroRequest,
	preCadastroRequest,
} from 'store/modules/api/mp/preCadastro/actions';
import { LimparSolicitacaoMP } from 'store/modules/api/mp/enviarSolicitacaoMP/actions';
import { limparAtualizaSolicitacaoPortal } from 'store/modules/api/mp/atualizarSolicitacaoPortal/actions';
import { impressaoTermoPaternidadeClear } from 'store/modules/api/mp/impressaoTermoPaternidade/actions';
import { impressaoSenhaClear } from 'store/modules/api/mp/impressaoGeraSenha/actions';
import { enviarSolicitacoesPortalClear } from 'store/modules/api/mp/enviarSolicitacoesPortal/actions';
import { eventosPPTContabilizacaoClear } from 'store/modules/api/utils/eventosPPT/contabilizacao/actions';
import { uploadArquivoClear } from 'store/modules/api/utils/uploadArquivo/actions';
import { solicitarReconhecimentoPaternidadeClear } from 'store/modules/api/ministerioPublico/solicitarReconhecimentoPaternidade/actions';
import { enderecosClear } from 'store/modules/api/utils/enderecos/actions';

// TYPES
import { ConsultarAndamentoSolicitacoesData } from 'store/modules/api/ministerioPublico/consultarAndamentoSolicitacoes/types';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';

// CONTEXT
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// ROUTES
import { ROUTE_MINISTERIO_PUBLICO } from 'routes/paths';

// FORM
import { ROUTE_MINISTERIO_PUBLICO_RECONHECIMENTO_DE_PATERNIDADE_CADASTRO } from 'pages/ministerioPublico/routes/paths';
import { initialValues, schema, treatPreCadastroRequest } from './form';
import { Content, TooltipBox } from './styled';

const BuscarSolicitacao: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { token } = useContext<IAuthContext>(AuthContext);

	const [cpfCidadao, setCpfCidadao] = useState('');

	const {
		consultarAndamentoSolicitacoes,
		consultarDetalheSolicitacao,
		solicitarReconhecimentoPaternidade,
	} = useSelector((state: ApplicationState) => state.api.ministerioPublico);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { notifications } = useSelector((state: ApplicationState) => state.app);

	const handleReset = useCallback(() => {
		dispatch(enviarSolicitacoesPortalClear());
		dispatch(LimparSolicitacaoMP());
		dispatch(limparAtualizaSolicitacaoPortal());
		dispatch(limpaPreCadastroRequest());
		dispatch(impressaoTermoPaternidadeClear());
		dispatch(impressaoSenhaClear());
		dispatch(consultarAndamentoSolicitacoesClear());
		dispatch(preCadastroCidadaoClear());
		dispatch(eventosPPTContabilizacaoClear());
		dispatch(consultarDetalheSolicitacaoClear());
		dispatch(uploadArquivoClear());
		dispatch(solicitarReconhecimentoPaternidadeClear());
		dispatch(enderecosClear());
	}, [dispatch]);

	useEffect(() => {
		handleReset();
	}, [handleReset]);

	const handleSelecionar = useCallback(
		(values: ConsultarAndamentoSolicitacoesData) => {
			const requestConsultarDetalheSolicitacao = {
				cpfUsuario: user.cpf,
				cpfCidadao,
				idSolicitacao: values.idSolicitacao,
			};
			dispatch(
				consultarDetalheSolicitacaoRequest(
					token,
					requestConsultarDetalheSolicitacao,
				),
			);
		},
		[cpfCidadao, dispatch, token, user.cpf],
	);

	useEffect(() => {
		if (
			consultarAndamentoSolicitacoes.status === 200 &&
			consultarAndamentoSolicitacoes.data?.length === 0
		) {
			addNotifications({
				errors: 'CPF não cadastrado',
			});
		}
	}, [
		consultarAndamentoSolicitacoes.data,
		consultarAndamentoSolicitacoes.status,
	]);

	useEffect(() => {
		if (
			solicitarReconhecimentoPaternidade.status === 0 &&
			(consultarDetalheSolicitacao.status === 200 ||
				consultarDetalheSolicitacao.status === 204) &&
			consultarDetalheSolicitacao.data &&
			cpfCidadao !== ''
		) {
			const requestPreCadastro = treatPreCadastroRequest(
				consultarDetalheSolicitacao.data,
			);
			dispatch(preCadastroRequest(requestPreCadastro));
			history.push(
				ROUTE_MINISTERIO_PUBLICO_RECONHECIMENTO_DE_PATERNIDADE_CADASTRO,
			);
		}
	}, [
		history,
		consultarDetalheSolicitacao.data,
		dispatch,
		cpfCidadao,
		consultarDetalheSolicitacao.status,
		solicitarReconhecimentoPaternidade.status,
	]);

	const handleSubmit = useCallback(
		(cpf: string) => {
			dispatch(clearNotifications());
			const treatedCpf = cpf.replace(/[.-]+/g, '');
			setCpfCidadao(treatedCpf);
			const treatValues = {
				cpfUsuario: user.cpf,
				cpfCidadao: treatedCpf,
			};
			dispatch(consultarAndamentoSolicitacoesRequest(token, treatValues));
		},
		[dispatch, token, user.cpf],
	);

	const TooltipContent = (
		<>
			<p>Responsável legal, filho (a) acima de 18 anos, próprio pai.</p>
		</>
	);

	const headers = [
		{
			title: 'Protocolo MPSP',
			dataIndex: 'numeroMP',
			align: 'center',
			render: (numeroMP: string) => {
				return <b>{numeroMP}</b>;
			},
		},
		{
			title: 'Situação Atual',
			dataIndex: 'situacaoAtual',
			align: 'center',
			render: (situacaoAtual: string) => {
				return <b>{situacaoAtual}</b>;
			},
		},
		{
			title: 'Localização Atual',
			dataIndex: 'localizacaoAtual',
			align: 'center',
			render: (localizacaoAtual: string) => {
				return <b>{localizacaoAtual}</b>;
			},
		},
		{
			title: '',
			dataIndex: 'numeroMP',
			align: 'center',
			render: (numeroMP: any, values: ConsultarAndamentoSolicitacoesData) => {
				if (numeroMP) {
					return (
						<ButtonImage
							style={{ display: 'block !important', margin: '0 auto' }}
							type="submit"
							src="selecionar"
							onClick={() => {
								handleSelecionar(values);
							}}
						/>
					);
				}
				return '';
			},
		},
	];

	return (
		<>
			<Section title="Reconhecimento de Paternidade">
				<Row justify="end">
					<Col span={4}>
						<ButtonImage
							type="button"
							src="novo-cadastro"
							onClick={() => {
								handleReset();
								history.push(
									ROUTE_MINISTERIO_PUBLICO_RECONHECIMENTO_DE_PATERNIDADE_CADASTRO,
								);
							}}
						/>
					</Col>
				</Row>
				<Section title="Buscar Solicitação" size="sm">
					<Row justify="center" gutter={[0, 16]}>
						<Col>
							<Formik
								validateOnChange={false}
								validateOnBlur={notifications.errors.length > 0}
								initialValues={initialValues}
								validate={values => getValidationsErrors(values, schema)}
								onSubmit={values => {
									handleSubmit(values.cpf);
								}}
							>
								{(formik: FormikProps<any>) => {
									const { errors } = formik;
									return (
										<Content>
											<Form>
												<TooltipBox>
													<Title size={30} required={false}>
														<label>CPF:</label>
													</Title>
													<TooltipInfo content={TooltipContent} />
												</TooltipBox>
												<Field
													name="cpf"
													size={200}
													as={InputMask}
													mask="999.999.999-99"
													error={hasError(errors, 'cpf')}
													onChange={(e: ChangeEvent<HTMLInputElement>) => {
														formik.setFieldValue('cpf', e.target.value);
														if (notifications.errors) {
															dispatch(clearNotifications());
															formik.setErrors({});
														}
													}}
												/>
												<ButtonImage
													type="submit"
													src="pesquisar"
													disabled={
														consultarAndamentoSolicitacoes.status === 100
													}
												/>
											</Form>
										</Content>
									);
								}}
							</Formik>
						</Col>
						<Col />
					</Row>
				</Section>
				{consultarAndamentoSolicitacoes.data &&
					consultarAndamentoSolicitacoes.data?.length > 0 && (
						<>
							<span>Resultados Encontrados:</span>
							<SimpleTable
								size="sm"
								headers={headers}
								body={consultarAndamentoSolicitacoes.data}
								pageSize={5}
								messageNoResults="Nenhum registro encontrado"
								striped
							/>
						</>
					)}
			</Section>

			<ButtonVoltar route={ROUTE_MINISTERIO_PUBLICO} />
		</>
	);
};

export default BuscarSolicitacao;
