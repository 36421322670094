import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_SGU_API } from '../../../../../../../services/rotasp/path';

// TYPES
import { ReducerActionRotaSP  } from 'store/modules/types';
import { Types, CadastrarSiglaRequest } from './types';

// ACTIONS
import { cadastrarSiglaSuccess, cadastrarSiglaFailure } from './actions';

function* cadastrarSigla(request: ReducerActionRotaSP ) {
	const {
		token,
		payload,
	}: { token?: string; payload: CadastrarSiglaRequest } = request;

	let body = { ...payload };

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_SGU_API,
		`siglas`,
		body,
		{ idOrgao: payload.idOrgao },
	);


	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarSiglaSuccess(response));
	} else {
		yield put(cadastrarSiglaFailure());
	}
}

export default all([takeLatest(Types.CADASTRAR_SIGLA_REQUEST, cadastrarSigla)]);
