/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';

import { Row, Col } from 'antd';

// STORES
import { useDispatch } from 'react-redux';
import data from 'store/modules/api/mp/enum';
import { clearNotifications } from 'store/modules/app/notifications/actions';

// UTILS
import hasError from 'utils/getFormErrors';
import {
	onlyLetters,
	onlyLettersAndSpace,
	onlyNumbers,
	onlyNumbersAndLetters,
} from 'utils/genericFunctions';

// COMMONS
import RG from 'components/Common/Form/Fields/RG';
import Select from 'components/Common/Form/Select';
import InputMask from 'components/Common/Form/Input/InputMask';
import CEP, { IBuscarCepResultado } from 'components/Common/Form/Fields/CEP';
import Input from 'components/Common/Form/Input/Input';

// FORM
import { FormikProps, Field } from 'formik';
import { ICadastroSolicitante } from '../form';

interface Props {
	formik: FormikProps<ICadastroSolicitante>;
	step: number;
	solicitante: string;
	formDisabled: boolean;
}

const DadosSolicitante: React.FC<Props> = ({
	formik,
	step,
	solicitante,
	formDisabled,
}) => {
	const dispatch = useDispatch();
	const tipoSolicitante =
		(formik.values.tipoSolicitante === 1 && 'filho') ||
		(formik.values.tipoSolicitante === 2 && 'responsavelLegal') ||
		(formik.values.tipoSolicitante === 3 && 'supostoGenitor');

	const { setFieldValue } = formik;
	const handleCepSelecionado = useCallback(
		(res: IBuscarCepResultado) => {
			dispatch(clearNotifications());
			setFieldValue(`${solicitante}.enderecoDeResidencia.cep`, res.cep);
			setFieldValue(`${solicitante}.enderecoDeResidencia.uf`, res.uf);
			setFieldValue(
				`${solicitante}.enderecoDeResidencia.logradouro`,
				res.endereco,
			);
			setFieldValue(`${solicitante}.enderecoDeResidencia.bairro`, res.bairro);
			setFieldValue(
				`${solicitante}.enderecoDeResidencia.cidade`,
				res.municipio,
			);
			setFieldValue(`${solicitante}.enderecoDeResidencia.siglaUF`, res.uf);
		},
		[dispatch, setFieldValue, solicitante],
	);

	return (
		<>
			<Row gutter={[10, 10]}>
				<Col span={8}>
					<RG
						title="RG"
						numero={`${solicitante}.rg.numero`}
						defaultNumero={
							solicitante === 'filho'
								? formik.values.filho?.rg?.numero
								: solicitante === 'responsavelLegal'
								? formik.values.responsavelLegal?.rg?.numero
								: formik.values.supostoGenitor?.rg?.numero
						}
						formik={formik}
						disabled={formDisabled}
						result={rg => {
							formik.setFieldValue(`${solicitante}.rg.numero`, rg.numeroRg);
						}}
						readOnly={step === 2}
						error={hasError(
							formik.errors,
							`${solicitante}.rg.numero` || `${solicitante}.rg.siglaUF`,
						)}
					/>
				</Col>
				<Col span={16}>
					<Field
						as={Select}
						size={35}
						title="UF RG"
						name={`${solicitante}.rg.siglaUF`}
						options={data.uf}
						onChange={(v: string) =>
							formik.setFieldValue(`${solicitante}.rg.siglaUF`, v)
						}
						disabled={formDisabled}
						readOnly={step === 2}
						error={hasError(
							formik.errors,
							`${solicitante}.rg.siglaUF` || `${solicitante}.rg.numero`,
						)}
					/>
				</Col>
			</Row>
			{solicitante === 'supostoGenitor' && (
				<Row>
					<Col span={18}>
						<Field
							as={Input}
							title="Nome da Mãe"
							name="supostoGenitor.nomeMae"
							maxLength={60}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								formik.setFieldValue(
									'supostoGenitor.nomeMae',
									onlyLettersAndSpace(e.target.value),
								);
							}}
							error={hasError(formik.errors, 'supostoGenitor.nomeMae')}
							readOnly={step === 2}
							disabled={formDisabled}
						/>
					</Col>
				</Row>
			)}
			<Row gutter={[10, 10]}>
				<Col span={8}>
					<CEP
						disabled={formDisabled}
						retornoErro
						readOnly={step === 2}
						name={`${solicitante}.enderecoDeResidencia.cep`}
						defaultValue={
							solicitante === 'filho'
								? formik.values.filho?.enderecoDeResidencia?.cep
								: solicitante === 'responsavelLegal'
								? formik.values.responsavelLegal?.enderecoDeResidencia?.cep
								: formik.values.supostoGenitor?.enderecoDeResidencia?.cep
						}
						formik={formik}
						instanceRequest={`${solicitante}.enderecoDeResidencia.cep`}
						result={handleCepSelecionado}
						required={solicitante === tipoSolicitante}
					/>
				</Col>
				<Col span={16}>
					<Field
						maxLength={22}
						title="Logradouro"
						name={`${solicitante}.enderecoDeResidencia.logradouro`}
						type="text"
						size={70}
						disabled={formDisabled}
						as={Input}
						onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
							formik.setFieldValue(
								`${solicitante}.enderecoDeResidencia.logradouro`,
								onlyLetters(v.target.value),
							);
						}}
						error={hasError(
							formik.errors,
							`${solicitante}.enderecoDeResidencia.logradouro`,
						)}
						required={solicitante === tipoSolicitante}
						readOnly={step === 2}
					/>
				</Col>
			</Row>
			<Row gutter={[10, 10]}>
				<Col span={8}>
					<Field
						maxLength={10}
						required={solicitante === tipoSolicitante}
						title="Número"
						name={`${solicitante}.enderecoDeResidencia.numero`}
						type="text"
						as={Input}
						onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
							formik.setFieldValue(
								`${solicitante}.enderecoDeResidencia.numero`,
								onlyNumbers(v.target.value),
							);
						}}
						error={hasError(
							formik.errors,
							`${solicitante}.enderecoDeResidencia.numero`,
						)}
						readOnly={step === 2}
						disabled={formDisabled}
					/>
				</Col>
				<Col span={8}>
					<Field
						maxLength={6}
						title="Complemento"
						name={`${solicitante}.enderecoDeResidencia.complemento`}
						type="text"
						as={Input}
						onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
							formik.setFieldValue(
								`${solicitante}.enderecoDeResidencia.complemento`,
								onlyNumbersAndLetters(v.target.value),
							);
						}}
						error={hasError(
							formik.errors,
							`${solicitante}.enderecoDeResidencia.complemento`,
						)}
						readOnly={step === 2}
						disabled={formDisabled}
					/>
				</Col>
			</Row>

			<Row gutter={[10, 10]}>
				<Col span={8}>
					<Field
						maxLength={14}
						required={solicitante === tipoSolicitante}
						title="Bairro"
						name={`${solicitante}.enderecoDeResidencia.bairro`}
						type="text"
						as={Input}
						onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
							formik.setFieldValue(
								`${solicitante}.enderecoDeResidencia.bairro`,
								onlyLetters(v.target.value),
							);
						}}
						error={hasError(
							formik.errors,
							`${solicitante}.enderecoDeResidencia.bairro`,
						)}
						readOnly={step === 2}
						disabled={formDisabled}
					/>
				</Col>
				<Col span={8}>
					<Field
						maxLength={50}
						title="Município"
						name={`${solicitante}.enderecoDeResidencia.cidade`}
						type="text"
						as={Input}
						onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
							formik.setFieldValue(
								`${solicitante}.enderecoDeResidencia.cidade`,
								onlyLetters(v.target.value),
							);
						}}
						error={hasError(
							formik.errors,
							`${solicitante}.enderecoDeResidencia.cidade`,
						)}
						readOnly={step === 2}
						disabled={formDisabled}
						required={solicitante === tipoSolicitante}
					/>
				</Col>
				<Col span={8}>
					{step === 1 && (
						<Field
							as={Select}
							title="UF"
							name={`${solicitante}.enderecoDeResidencia.siglaUF`}
							options={data.uf}
							onChange={(v: string) =>
								formik.setFieldValue(
									`${solicitante}.enderecoDeResidencia.siglaUF`,
									v,
								)
							}
							error={hasError(
								formik.errors,
								`${solicitante}.enderecoDeResidencia.siglaUF`,
							)}
							disabled={formDisabled}
							required={solicitante === tipoSolicitante}
						/>
					)}

					{step === 2 && (
						<Field
							readOnly
							as={Input}
							title="UF"
							name={`${solicitante}.enderecoDeResidencia.siglaUF`}
							error={hasError(formik.errors, `${solicitante}.rg.siglaUF`)}
							disabled={formDisabled}
						/>
					)}
				</Col>
			</Row>
			<Row gutter={[10, 10]}>
				<Col span={16}>
					<Field
						maxLength={150}
						title="E-mail"
						name={`${solicitante}.email`}
						type="text"
						as={Input}
						error={hasError(formik.errors, `${solicitante}.email`)}
						readOnly={step === 2}
						disabled={formDisabled}
						required={solicitante === tipoSolicitante}
					/>
				</Col>
			</Row>

			<Row gutter={[10, 10]}>
				<Col span={8}>
					<Field
						title="Telefone"
						name={`${solicitante}.telefoneResidencial`}
						type="text"
						mask="(99) 9999-9999"
						as={InputMask}
						error={hasError(
							formik.errors,
							`${solicitante}.telefoneResidencial`,
						)}
						readOnly={step === 2}
						disabled={formDisabled}
					/>
				</Col>
				<Col span={8}>
					<Field
						title="Celular"
						name={`${solicitante}.telefoneCelular`}
						type="text"
						mask="(99) 99999-9999"
						as={InputMask}
						error={hasError(formik.errors, `${solicitante}.telefoneCelular`)}
						readOnly={step === 2}
						disabled={formDisabled}
						required={solicitante === tipoSolicitante}
					/>
				</Col>
				{/* <Col span={8}>
					<Field
						title="Tel Comercial"
						name={`${solicitante}.telefones.comercial`}
						type="text"
						mask="(99) 9999-9999"
						as={InputMask}
						error={hasError(
							formik.errors,
							`${solicitante}.telefones.comercial`,
						)}
						readOnly={step === 2}
						disabled={formDisabled}
					/>
				</Col> */}
			</Row>
			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>
		</>
	);
};

export default DadosSolicitante;
