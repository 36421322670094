import produce from 'immer';
import { Types, PrefeiturasServicosMunicipaisState } from './types';
import { ReducerAction } from '../../../types';

export const INITIAL_STATE: PrefeiturasServicosMunicipaisState = {
	prefeiturasData: [],
	prefeiturasServicosData: [],
	prefeiturasServicosParametrosData: [],
	totalRegistrosConsulta: 0,
	nextStep: false,
	codigoRedirect: '',
	redirect: false,
	situation: '',
};

export default function prefeituras(
	state = INITIAL_STATE,
	action: ReducerAction,
): PrefeiturasServicosMunicipaisState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTA_PREFEITURAS: {
				draft.totalRegistrosConsulta = 0;
				break;
			}
			case Types.CONSULTA_PREFEITURAS_SUCCESS: {
				draft.prefeiturasData = action.payload.data;
				draft.totalRegistrosConsulta =
					action.payload.headers['total-num-registros'];
				break;
			}
			case Types.CONSULTA_PREFEITURAS_FAILURE: {
				draft.prefeiturasData = [];
				break;
			}
			case Types.CADASTRA_PREFEITURAS: {
				break;
			}
			case Types.CADASTRA_PREFEITURAS_SUCCESS: {
				draft.nextStep = true;
				draft.redirect = true;
				draft.situation = 'Cadastrado';
				draft.codigoRedirect = action.payload.codigo;
				break;
			}
			case Types.CADASTRA_PREFEITURAS_FAILURE: {
				break;
			}
			case Types.ALTERA_PREFEITURAS: {
				break;
			}
			case Types.ALTERA_PREFEITURAS_SUCCESS: {
				const updatedData = [...draft.prefeiturasData];

				const itemIndex = updatedData?.findIndex(newItem => {
					return newItem.codigo === action.payload.codigo;
				});

				updatedData[itemIndex] = {
					...action.payload.body,
					codigo: action.payload.codigo,
				};
				draft.prefeiturasData = updatedData;
				draft.nextStep = true;
				draft.redirect = true;
				draft.situation = 'Alterado';
				break;
			}
			case Types.ALTERA_PREFEITURAS_FAILURE: {
				break;
			}
			case Types.REMOVE_PREFEITURAS: {
				break;
			}
			case Types.REMOVE_PREFEITURAS_SUCCESS: {
				const updatedParametros = draft.prefeiturasData.filter(
					item => item.codigo !== action.payload,
				);
				draft.prefeiturasData = updatedParametros;
				draft.redirect = true;
				draft.situation = 'Removido';
				break;
			}
			case Types.REMOVE_PREFEITURAS_FAILURE: {
				break;
			}

			case Types.CONSULTA_PREFEITURAS_SERVICOS: {
				break;
			}
			case Types.CONSULTA_PREFEITURAS_SERVICOS_SUCCESS: {
				draft.prefeiturasServicosData = action.payload.data;
				break;
			}
			case Types.CONSULTA_PREFEITURAS_SERVICOS_FAILURE: {
				draft.prefeiturasServicosData = [];
				break;
			}
			case Types.CADASTRA_PREFEITURAS_SERVICOS: {
				break;
			}
			case Types.CADASTRA_PREFEITURAS_SERVICOS_SUCCESS: {
				draft.prefeiturasServicosData.push(action.payload);
				draft.redirect = true;
				draft.situation = 'Cadastrado';
				break;
			}
			case Types.CADASTRA_PREFEITURAS_SERVICOS_FAILURE: {
				draft.prefeiturasServicosData = [];
				break;
			}
			case Types.ALTERA_PREFEITURAS_SERVICOS: {
				break;
			}
			case Types.ALTERA_PREFEITURAS_SERVICOS_SUCCESS: {
				draft.redirect = true;
				draft.situation = 'Alterado';
				break;
			}
			case Types.ALTERA_PREFEITURAS_SERVICOS_FAILURE: {
				break;
			}
			case Types.REMOVE_PREFEITURAS_SERVICOS: {
				break;
			}
			case Types.REMOVE_PREFEITURAS_SERVICOS_SUCCESS: {
				const updatedParametros = draft.prefeiturasServicosData.filter(
					item => item.id !== action.payload,
				);
				draft.prefeiturasServicosData = updatedParametros;
				draft.redirect = true;
				draft.situation = 'Removido';
				break;
			}
			case Types.REMOVE_PREFEITURAS_SERVICOS_FAILURE: {
				break;
			}

			case Types.CONSULTA_PREFEITURAS_SERVICOS_PARAMETROS: {
				break;
			}
			case Types.CONSULTA_PREFEITURAS_SERVICOS_PARAMETROS_SUCCESS: {
				draft.prefeiturasServicosParametrosData = action.payload.data;
				break;
			}
			case Types.CONSULTA_PREFEITURAS_SERVICOS_PARAMETROS_FAILURE: {
				draft.prefeiturasServicosParametrosData = [];
				break;
			}
			case Types.CADASTRA_PREFEITURAS_SERVICOS_PARAMETROS: {
				break;
			}
			case Types.CADASTRA_PREFEITURAS_SERVICOS_PARAMETROS_SUCCESS: {
				const formattedData = {
					grupoObrigatorio: action.payload.grupoObrigatorio,
					parametro: {
						...action.payload,
					},
				};

				delete formattedData.parametro.grupoObrigatorio;

				draft.prefeiturasServicosParametrosData.push(formattedData);
				draft.redirect = true;
				draft.situation = 'Adicionado';
				break;
			}
			case Types.CADASTRA_PREFEITURAS_SERVICOS_PARAMETROS_FAILURE: {
				break;
			}
			case Types.ALTERA_PREFEITURAS_SERVICOS_PARAMETROS: {
				break;
			}
			case Types.ALTERA_PREFEITURAS_SERVICOS_PARAMETROS_SUCCESS: {
				const updatedData = [...draft.prefeiturasServicosParametrosData];

				const itemIndex = updatedData?.findIndex(newItem => {
					return newItem.parametro.id === action.payload.idParametro;
				});

				updatedData[itemIndex].grupoObrigatorio =
					action.payload.grupo_obrigatorio;
				draft.prefeiturasServicosParametrosData = updatedData;
				draft.redirect = true;
				draft.situation = 'Alterado';
				break;
			}
			case Types.ALTERA_PREFEITURAS_SERVICOS_PARAMETROS_FAILURE: {
				break;
			}
			case Types.REMOVE_PREFEITURAS_SERVICOS_PARAMETROS: {
				break;
			}
			case Types.REMOVE_PREFEITURAS_SERVICOS_PARAMETROS_SUCCESS: {
				const updatedData = draft.prefeiturasServicosParametrosData.filter(
					item => item.parametro.id !== action.payload,
				);
				draft.prefeiturasServicosParametrosData = updatedData;
				draft.redirect = true;
				draft.situation = 'Removido';
				break;
			}
			case Types.REMOVE_PREFEITURAS_SERVICOS_PARAMETROS_FAILURE: {
				break;
			}

			case Types.REDIRECT_PREFEITURAS_CLEAR: {
				draft.redirect = false;
				draft.codigoRedirect = '';
				break;
			}

			case Types.NEXT_STEP_PREFEITURAS_CLEAR: {
				draft.nextStep = false;
				break;
			}
			default:
		}
	});
}
