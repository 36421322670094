import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, postApi } from 'services/rotasp/api';
import { ReducerActionRotaSP } from 'store/modules/types';
import { PATH_SGU_DOMINIOS_API } from '../../../../../../../services/rotasp/path';
import { CadastrarNaturalidadeRequestNew, Types } from './types';
import {
	cadastrarNaturalidadeSuccess,
	cadastrarNaturalidadeFailure,
} from './actions';

function* cadastrarNaturalidadeRequest(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: CadastrarNaturalidadeRequestNew } = request;

	const body = { ...payload };

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_SGU_DOMINIOS_API,
		`naturalidades`,
		body,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarNaturalidadeSuccess(response));
	} else {
		yield put(cadastrarNaturalidadeFailure());
	}
}

export default all([
	takeLatest(Types.CADASTRAR_NATURALIDADE, cadastrarNaturalidadeRequest),
]);
