import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ExcluirSiglaRequest } from './types';

export function excluirSiglaRequest(
	token: string,
	data: ExcluirSiglaRequest): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_SIGLA_REQUEST,
		token,
		payload: data,
	};
}
export function excluirSiglaSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_SIGLA_SUCCESS,
		payload,
	};
}
export function excluirSiglaFailure(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_SIGLA_FAILURE,
		payload: null,
	};
}
export function excluirSiglaClear(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_SIGLA_CLEAR,
		payload: null,
	};
}
