import { ReducerActionRotaSP } from 'store/modules/types';

import { Types } from './types';

export function consultaCanaisRequest(token: string): ReducerActionRotaSP {
	return {
		type: Types.CONSULTA_CANAIS,
		token,
		payload: null,
	};
}
export function consultaCanaisSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.CONSULTA_CANAIS_SUCCESS,
		payload,
	};
}
export function consultaCanaisFailure(payload: []): ReducerActionRotaSP {
	return {
		type: Types.CONSULTA_CANAIS_FAILURE,
		payload,
	};
}
