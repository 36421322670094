import {
	addNotifications,
	clearNotificationsValidate,
} from '../store/modules/app/notifications/actions';

export default async function getValidationsErrors(
	values: any,
	schema: any,
	nameObjectKeyToIgnore?: string | string[],
) {
	let newValues = {};

	Object.values(values).forEach((item: any, index: number) => {
		const key = Object.keys(values)[index];

		if (
			typeof item === 'string' &&
			(nameObjectKeyToIgnore && Array.isArray(nameObjectKeyToIgnore)
				? !nameObjectKeyToIgnore.includes(key)
				: key !== nameObjectKeyToIgnore || true)
		) {
			newValues = { ...newValues, [key]: item.replace(/[_.-]+/g, '') };
		} else {
			newValues = { ...newValues, [key]: item };
		}
	});

	clearNotificationsValidate();

	try {
		await schema.validate(newValues, {
			abortEarly: false,
		});

		return {};
	} catch (err) {
		let errors: any = {};
		const notifications: string[] = [];

		err.inner.forEach((error: any) => {
			errors = { ...errors, [error.path]: error.message };
			notifications.push(error.message);
		});

		addNotifications({ errors: notifications });

		return errors;
	}
}

export async function getFormValidationsErrors(
	values: any,
	schema: any,
	nameObjectKeyToIgnore?: string,
	hasErrors?: boolean | undefined,
) {
	let newValues = {};

	Object.values(values).forEach((item: any, index: number) => {
		const key = Object.keys(values)[index];

		if (
			typeof item === 'string' &&
			(nameObjectKeyToIgnore ? key !== nameObjectKeyToIgnore : true)
		) {
			newValues = { ...newValues, [key]: item.replace(/[_.-]+/g, '') };
		} else {
			newValues = { ...newValues, [key]: item };
		}
	});

	clearNotificationsValidate();

	try {
		await schema.validate(newValues, {
			abortEarly: false,
		});

		return true;
	} catch (err) {
		let errors: any = {};
		const notifications: string[] = [];

		err.inner.forEach((error: any) => {
			errors = { ...errors, [error.path]: error.message };
			notifications.push(error.message);
		});

		if (hasErrors) {
			addNotifications({
				errors: notifications,
				moveToTop: false,
				notificationOrigin: 'FORM_VALIDATE',
			});
		} else {
			addNotifications({
				errors: notifications,
				moveToTop: true,
				notificationOrigin: 'FORM_VALIDATE',
			});
		}

		return errors;
	}
}

export async function getValidationsErrorsMenu(values: any, schema: any) {
	try {
		await schema.validate(values, {
			abortEarly: false,
		});

		return true;
	} catch (err) {
		let errors: any = {};
		const notifications: string[] = [];

		err.inner.forEach((error: any) => {
			errors = { ...errors, [error.path]: error.message };
			notifications.push(error.message);
		});

		addNotifications({ errors: notifications });

		return errors;
	}
}
