import * as Yup from 'yup';

// STORE
import { ILogin } from 'store/modules/api/sgu/loginUnico/types';

// UTILS
import { limparMascara, unformatDate } from 'utils/genericFunctions';
import { namesIirgd } from 'utils/yupValidations';

export interface IFormPesquisaRg {
	numeroRg: string;
	digitoRg: string;
	resetValues: boolean;
	RGempty: boolean;
	nome: string;
	nomePai: string;
	nomeMae: string;
	rgMae: string;
	rgMaeUf: string;
	dataNascimento: string;
	naturalidadeEstrangeira: boolean;
	codigoNaturalidade: string;
	municipioNaturalidade: string;
	apresentouCertidao: null | boolean;
	portaria606: null | boolean;
	filiacaoTres?: string;
	filiacaoQuatro?: string;
	filiacaoCinco?: string;
	filiacaoSeis?: string;
}

export const initialValues: IFormPesquisaRg = {
	numeroRg: '',
	digitoRg: '',
	resetValues: false,
	RGempty: false,
	nome: '',
	nomePai: '',
	nomeMae: '',
	rgMae: '',
	rgMaeUf: 'SP',
	dataNascimento: '',
	naturalidadeEstrangeira: false,
	codigoNaturalidade: '',
	municipioNaturalidade: '',
	apresentouCertidao: false,
	portaria606: false,
	filiacaoTres: '',
	filiacaoQuatro: '',
	filiacaoCinco: '',
	filiacaoSeis: '',
};

export const schema = Yup.object<IFormPesquisaRg>().shape({
	numeroRg: Yup.string().when('RGempty', {
		is: RGempty => RGempty === false,
		then: Yup.string().required('Campo Obrigatório: RG.'),
	}),
	nome: Yup.string().when('RGempty', {
		is: RGempty => RGempty === true,
		then: Yup.string()
			.required('Campo Obrigatório: Nome.')
			.max(60, 'Campo Nome: Tamanho máximo de 60 caracteres.'),
	}),
	nomePai: Yup.string().when('RGempty', {
		is: RGempty => RGempty === true,
		then: Yup.string()
			.required('Campo Obrigatório: Filiação 1.')
			.max(60, 'Campo Filiação 1: Tamanho máximo de 60 caracteres.')
			.test({
				name: 'onlyLetters',
				exclusive: true,
				message: 'Campo Filiação 1: Apenas letras são permitidas.',
				test: value => namesIirgd(value),
			}),
	}),
	nomeMae: Yup.string().when('RGempty', {
		is: RGempty => RGempty === true,
		then: Yup.string()
			.required('Campo Obrigatório: Filiação 2.')
			.max(60, 'Campo Filiação 2: Tamanho máximo de 60 caracteres.')
			.test({
				name: 'onlyLetters',
				exclusive: true,
				message: 'Campo Filiação 2: Apenas letras são permitidas.',
				test: value => namesIirgd(value),
			}),
	}),
	dataNascimento: Yup.string().when('RGempty', {
		is: RGempty => RGempty === true,
		then: Yup.string().required('Campo Obrigatório: Data de Nascimento.'),
	}),
	codigoNaturalidade: Yup.string().when('RGempty', {
		is: RGempty => RGempty === true,
		then: Yup.string().required('Campo Obrigatório: Código do Munícipio.'),
	}),
});

export interface RequestPesquisarRg {
	numeroRg: string;
	usuario: string;
	senha: string;
	pesquisaCIRG: boolean;
	pesquisaCivil: boolean;
	nomeCompleto?: string;
	nomePai?: string;
	nomeMae?: string;
	dataNascimento?: string;
	naturalidade?: number | string;
	apresentouCertidao?: boolean;
}

export function treatValues(
	values: IFormPesquisaRg,
	login: ILogin,
): RequestPesquisarRg {
	if (values.RGempty) {
		const valorNaturalidade =
			Number(values.codigoNaturalidade.split('-')[0]) ||
			Number(limparMascara(values.codigoNaturalidade));

		return {
			numeroRg: `${values.numeroRg}${values.digitoRg ? '-' : ''}${
				values.digitoRg
			}`,
			usuario: login.mainframe.idUsuario,
			senha: login.mainframe.senhaMainFrame,
			pesquisaCIRG: false,
			pesquisaCivil: true,
			nomeCompleto: values.nome.toUpperCase().trim(),
			nomePai: values.nomePai.toUpperCase().trim(),
			nomeMae: values.nomeMae.toUpperCase().trim(),
			dataNascimento: unformatDate(values.dataNascimento),
			naturalidade: valorNaturalidade,
		};
	}

	return {
		numeroRg: `${values.numeroRg}${values.digitoRg ? '-' : ''}${
			values.digitoRg
		}`,
		usuario: login.mainframe.idUsuario,
		senha: login.mainframe.senhaMainFrame,
		pesquisaCIRG: true,
		pesquisaCivil: true,
	};
}
