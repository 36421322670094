import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApi } from 'services/rotasp/api';
import { ReducerActionRotaSP } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { PATH_SGU_DOMINIOS_API } from '../../../../../../../services/rotasp/path';
import { ConsultarNaturalidadesRequest, Types } from './types';
import {
	consultarNaturalidadesSuccess,
	consultarNaturalidadesFailure,
} from './actions';

// UTILS

function* consultarNaturalidades(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: ConsultarNaturalidadesRequest } = request;

	let body = { ...payload };

	delete body.current;

	body = cleanEmpty(body) as ConsultarNaturalidadesRequest;

	if (body.digito === 0) {
		delete body.digito;
	}

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_SGU_DOMINIOS_API,
		`naturalidades`,
		body,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarNaturalidadesSuccess(response));
	} else {
		yield put(consultarNaturalidadesFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_NATURALIDADES_REQUEST, consultarNaturalidades),
]);
