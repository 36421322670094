export enum Types {
	ATUALIZAR_SIGLA_REQUEST = '@SGU/ATUALIZAR_SIGLA_REQUEST',
	ATUALIZAR_SIGLA_SUCCESS = '@SGU/ATUALIZAR_SIGLA_SUCCESS',
	ATUALIZAR_SIGLA_FAILURE = '@SGU/ATUALIZAR_SIGLA_FAILURE',
	ATUALIZAR_SIGLA_CLEAR = '@SGU/ATUALIZAR_SIGLA_CLEAR',
}

export interface AtualizarSigla {
	status: number;
	data: null | SiglaData;
}

interface SiglaData {
	status: string;
	statusMensagem: string;
}

export interface AtualizarSiglaRequest {
	id?: string | number;
	idOrgao?: string | number;
	descricao?: string;
}
