import { OptionProps } from 'components/Common/Form/Select';

export enum Types {
	CONSULTAR_NATURALIDADES_REQUEST = '@SGU/CONSULTAR_NATURALIDADES_REQUEST',
	CONSULTAR_NATURALIDADES_SUCCESS = '@SGU/CONSULTAR_NATURALIDADES_SUCCESS',
	CONSULTAR_NATURALIDADES_FAILURE = '@SGU/CONSULTAR_NATURALIDADES_FAILURE',
	CONSULTAR_NATURALIDADES_CLEAR = '@SGU/CONSULTAR_NATURALIDADES_CLEAR',
}

export interface ConsultarNaturalidades {
	status: number;
	data: Naturalidade[] | null;
	enum: OptionProps[];
	totalRegistrosConsulta: number;
}

export interface Naturalidade {
	id: string | number;
	digito: string | number;
	descricao: string;
	sigla?: string;
}

export interface ConsultarNaturalidadesRequest {
	registro_inicial?: number;
	limite?: number;
	current?: number;
	id?: string | number;
	digito?: string | number;
	sigla?: string;
	descricao?: string;
}
